export default {
  businessProcess: {
    definitionsFormTitle: 'Редагувати діаграму процесу',
    definitionsListTitle: 'Бізнес-процеси',
    fillExecutorOrExecutorRole: 'Будь ласка, вкажіть роль виконавця або виконавиці',
    noExecutor: 'Ніякого підрядника.',
  },
  commands: {
    input: "Введення",
    selection: "Вибір",
    shiftTask: "Завдання на зміну",
    labelPrint: "Друк етикетки",
    cencelLastLabel: "Видалення останньої етикетки",
    issueEmpty: "Видати порожню",
    many: "Багато",
    reprintLabel: "Знову надрукувати етикетку",
    applicator: "Аплікатор",
    defect: "Дефект",
    accept: 'Я приймаю',
    activate: 'Активувати',
    add: 'Додати',
    addAction: 'Додати акції',
    addAttachment: 'Додати додаток',
    addComment: 'Додати коментар',
    addCondition: 'Додати умову',
    addCustomer: 'Додати клієнта',
    addEvent: 'Додати подію',
    addExtraProduct: 'Додати сипучі товари',
    addField: 'Додати поле',
    addFields: 'Додати поля',
    addFile: 'Додати файл',
    addFilters: 'Додати фільтри',
    addFolder: 'Додати папку',
    addFromList: 'Додати зі списку',
    addFunction: 'Додати функцію',
    addGroup: 'Додати групу',
    addItem: 'Додати елемент',
    addNew: 'Додати новий',
    addNotificationContent: 'Додати вміст сповіщення',
    addPhase: 'Додати етап',
    addProduct: 'Додати продукт',
    addRefreshResult: 'Додати / Оновити результат',
    addReminder: 'Додати нагадування',
    addSetting: 'Додати налаштування',
    addTable: 'Додати таблицю',
    addTitle: 'Додати заголовок',
    addToCalendar: 'Додати до календаря',
    addViewSetting: 'Додати налаштування перегляду',
    advancedSearch: 'Розширений пошук',
    allowManual: 'Редагувати',
    attachTo: 'Приєднати до',
    autofill: 'Автозаповнення',
    batchesPickup: 'Вибір партії',
    bulkInteractionForSelected: 'Масова взаємодія для вибраних',
    calculate: 'Розрахувати',
    calculateRoute: 'Обчислити маршрут',
    cancel: 'Скасувати',
    change: 'Змінити',
    changeDecision: 'Змінити рішення',
    changeNumber: 'Змінити номер',
    changeStatus: 'Змінити статус',
    changeView: 'Змінити перегляд',
    checkVat: 'Перевірити ПДВ',
    checkVies: 'Перевірити VIES',
    clear: 'Очистити',
    close: 'Закрити',
    compare: 'Порівняти',
    completeAllConsts: 'Заповніть всі витрати',
    configProduct: 'Налаштуйте продукт',
    confirm: 'Підтвердити',
    confirmPrices: 'Підтвердити ціни',
    confirmSelected: 'Підтвердити вибір',
    copy: 'Копіювати',
    copySettingsFromUser: 'Копіювати налаштування з іншого користувача',
    createFrom: 'Створити на основі',
    createOrder: 'Створити замовлення',
    createProcess: 'Створити процес',
    delete: 'Видалити',
    deleteRestore: 'Видалити / Відновити',
    download: 'Завантажити',
    downloadFile: 'Завантажити файл',
    edit: 'Редагувати',
    editAccess: 'Змінити права доступу',
    editAction: 'Редагувати дії',
    editFields: 'Редагувати поля',
    editFilters: 'Редагувати фільтри',
    editFolder: 'Редагувати папку',
    editNumber: 'Редагувати номер',
    end: 'Закінчити',
    enterCode: 'Введіть код',
    enterComment: 'Введіть коментар',
    enterDescription: 'Введіть опис',
    enterNumber: 'Введіть номер',
    enterSth: 'Введіть дані',
    eventsList: 'Список подій',
    execute: 'Виконати',
    fill: 'Заповнити',
    fillStandardFields: 'Заповнити стандартні поля',
    filterByValue: 'Фільтрувати за значенням поля',
    findOptimalRoute: 'Знайти оптимальний маршрут',
    forward: 'Переслати',
    generate: 'Генерувати',
    getComputerID: "Отримати ID комп'ютера",
    getFileForTranslation: 'Завантажити файл для перекладу',
    getInfoEquipment: 'Отримати дані про обладнання',
    goToEditing: 'Перейти до редагування',
    goToParentDocument: 'Перейти до основного документа',
    goToRegister: 'Перейти до реєстрації',
    groupByField: 'Групувати за полем',
    hideField: 'Сховати поле',
    inputTranslations: 'Введіть переклади',
    insertNumber: 'Введіть номер',
    loadItemsFromSpreadSheet: 'Завантажити з таблиці',
    loadTranslationFromFile: 'Завантажити переклад з файлу',
    loadWagonsDataFromFile: 'Завантажити дані вагонів з файлу',
    mannualEntry: 'Ручне введення',
    markAll: 'Відзначити всі',
    more: 'Більше',
    moveDown: 'Вниз',
    moveFieldLeft: 'Перемістити поле вліво',
    moveFieldRight: 'Перемістити поле вправо',
    moveUp: 'Вгору',
    ok: 'ОК',
    open: 'Відкрити',
    openFile: 'Відкрити файл',
    openReport: 'Відкрити звіт',
    planRoute: 'Запланувати маршрут',
    print: 'Друкувати',
    processes: 'Процеси',
    redirect: 'Перенаправити',
    redirectTaskExecution: 'Виконати і перенаправити на наступний етап',
    refresh: 'Оновити',
    resend: 'Відправити знову',
    reset: 'Скинути',
    resetPassword: 'Скинути пароль',
    rowDown: 'Вниз',
    rowUp: 'Вгору',
    run: 'Запустити',
    runAndClose: 'Запустити і закрити',
    runTimeTracking: 'Запустити відстеження часу',
    runningProcesses: 'Запущені процеси',
    salesReport: 'Звіт про продажі',
    search: 'Пошук',
    select: 'Вибрати',
    selectAll: 'Вибрати все',
    selectContactFromList: 'Вибрати контакт зі списку',
    send: 'Відправити',
    sendListToAuthor: 'Відправити список автору',
    sendNow: 'Відправити зараз',
    sendResult: 'Відправити результат',
    sendStartSms: 'Відправити стартове SMS',
    setDefault: 'Встановити за замовчуванням',
    setPeriod: 'Встановити період',
    setSchedule: 'Встановити розклад',
    setStatus: 'Встановити статус',
    showAttachedFiles: "Показати пов'язані файли",
    showCanceled: 'Показати неактивні',
    showDetail: 'Показати деталі',
    showHistory: 'Показати історію змін',
    showRegistersRecords: 'Показати записи в реєстрах',
    showRegistrationInfo: 'Вказати реєстраційні дані',
    showVatInfo: 'Інформація про ПДВ',
    sortByDate: 'Сортувати за датою',
    sortByFieldAsc: 'Сортувати за зростанням',
    sortByFieldDesc: 'Сортувати за спаданням',
    sortByName: 'Сортувати за назвою',
    takeFromAnotherUser: 'Перенести від іншого користувача',
    undoConfirm: 'Скасувати підтвердження',
    unloadWagonsToFile: 'Вивантажити вагони у файл',
    unmarkAll: 'Зняти всі позначки',
    update: 'Оновити',
    uploadFiles: 'Завантажити файли',
    write: 'Записати',
    writeAndClose: 'Записати і закрити',
    confirmAndClose: 'Підтвердити і закрити',
    writeChanges: 'Записати зміни',
    writeEmail: 'Написати електронного листа',
  },
  common: {
    searchSpecification: 'Пошук специфікацій...',
    searchProductType: 'Пошук типу продукту',
    searchServiceCategory: 'Пошук категорії послуги',
    batchOfFinishedProducts: 'Знайти партію готової продукції',
    shiftAssigments: 'Знайти завдання на зміну',
    searchLabel: 'Знайти етикетку',
    finishedProductionRegistration: "Реєстрація готової продукції",
    absence: 'Відсутність',
    acceptDispositionParking: 'Прийняття розпорядження',
    access: 'Доступ',
    actions: 'Дії',
    active: 'Активний',
    actual: 'Фактичний',
    addBtn: 'Кнопка "Додати"',
    addCalendar: 'Додати календар',
    addDisposition: 'Додати розпорядження',
    addNipParking: 'Введіть ІПН',
    addUpdateCostsOfComplaintOrder: 'Додавання / Редагування витрат на замовлення за скаргою',
    addinDimensions: 'Додаткові розміри',
    addinItems: 'Додаткові елементи',
    additional: 'Додаткові',
    additionalProducts: 'Додаткові продукти',
    additionalPunishmantParking: 'Додаткова плата за послуги паркінгу',
    addresses: 'Адреси',
    administration: 'Адміністрація',
    advanced: 'Розширені',
    againScanParking: 'Документ не був відсканований! Скануйте ще раз!',
    algorithm: 'Алгоритм обчислення',
    all: 'Усі',
    allDays: 'Цілий день',
    allFiles: 'Усі файли',
    analizeByCustomers: 'Аналіз за клієнтами',
    analyzeProccess: 'Аналіз процесу',
    anyQuestion: 'Маєте питання?',
    attachedFIles: 'Пов’язані файли',
    attachments: 'Вкладення',
    attendees: 'Учасники зустрічі',
    availableFields: 'Доступні поля',
    bankAccount: 'Банківський рахунок',
    bankName: 'Назва банку',
    basic: 'Основні',
    beginWeighting: 'Триває зважування',
    beginningStartParking: '<< Поверніться на початкову сторінку',
    bottom: 'Низ',
    bruttoPrice: 'Ціна брутто',
    bruttoWorth: 'Вартість брутто',
    buyer: 'Покупець',
    calcDisabled: 'Обчислення вимкнено',
    calculated: 'Обчислюваний',
    callForChangeDateDisp: 'Зателефонуйте за номером ........... для виправлення даних.',
    cancelParking: 'Скасувати',
    carrierData: 'Дані перевізника',
    chainOfCommand: 'Структура підпорядкування',
    checkAllDataParking: 'ПЕРЕВІРТЕ ВСІ ДАНІ',
    chooseCarrier: 'Виберіть перевізника',
    classes: 'Класи',
    codeConfirmedParking: 'Підтвердити код',
    codeFromSMSParking: 'Введіть код з SMS',
    collapsible: 'Згортати',
    commandName: 'Назва команди',
    commands: 'Команди',
    companyRegistrationInfo: 'Дані компанії (з реєстраційної форми)',
    complaintVerificationProtocol: 'Протокол перевірки скарги',
    complaintsInfo: 'Дані скарги',
    components: 'Компоненти',
    confirmDriverParking: 'Підтвердження водія',
    confirmExitParking: 'Підтвердити виїзд',
    confirmNipParking: 'Підтвердити',
    constructor: 'Конструктор',
    containsWords: 'Містить слова',
    contentCols: 'Ширина контенту',
    correctTheDataParking: 'Виправити дані',
    currency: 'Валюта',
    currentWeek: 'Поточний тиждень',
    customerData: 'Дані клієнта',
    customerFiles: 'Файли клієнта',
    customerQuantity: 'Додано нових клієнтів',
    customerRequestNumber: 'Номер запиту на пропозицію',
    customerRequestNumberEdit: 'Редагування номера запиту на пропозицію',
    customerRequestsByCustomer: 'Запити на пропозиції за клієнтами',
    customerRequestsQuantity: 'Кількість нових запитів на пропозиції',
    customerRequestsSalesOrdersStatistics: 'Статистика запитів на пропозиції - замовлень клієнта',
    customerRequestsSalesOrdersValue: 'Вартість запитів на пропозиції - замовлень клієнта',
    customerRequestsStatistics: 'Статистика запитів на пропозиції',
    customerRequestsValue: 'Вартість запитів на пропозиції',
    customerRequestsValueActual: 'Вартість нових запитів на пропозиції',
    customersValue: 'Нові клієнти',
    databaseTranslation: 'Переклад інтерфейсу',
    dateAdded: 'Дата додавання',
    dateOfIssue: 'Дата видачі',
    days: 'Дні',
    deactive: 'Неактивний',
    decisionDate: 'Дата рішення',
    default: 'Основні',
    defaultPrinterSettings: 'Стандартні налаштування принтера',
    defaultSettings: 'Стандартні налаштування',
    deleted: 'Видалено',
    deliveryNoteData: 'Дані транспортної накладної',
    deliveryOrderOfNumber: 'Замовлення №',
    deliveryPoints: 'Місця доставки',
    demand: 'Попит',
    describeAlgorithm: 'Опишіть алгоритм',
    describeProduct: 'Опишіть продукт...',
    details: 'Деталі',
    detailsPunishmentParking: 'Деталі нарахованих зборів',
    dispositionData: 'Дані розпорядження',
    dispositionOfNumber: 'Розпорядження №',
    documentIssue: 'Документ виписав',
    documentReaderParking: 'Сканер документів знаходиться праворуч від Селфбоксу',
    documentReceived: 'Документ отримав',
    documentToPrint: {
      printDeliveryNote: 'Друк вагового квитка',
      printDeliveryNoteRoll: 'Друк вагового квитка (ролка)',
      printDeliveryNoteTurn: 'Друк вагового квитка зі скруткою',
    },
    double: 'Подвійний',
    emptyDispositionList: 'Не знайдено розпоряджень...',
    emptyExecutorList: 'Список виконавців порожній...',
    emptyField: '<не заповнено>',
    emptyFileVersions: 'Не знайдено версій файлу.',
    emptyHistoryList: "Не знайдено записів щодо змін об'єкта...",
    emptyTicketParking: 'Введіть номер квитка',
    emptyUserList: 'Список користувачів порожній...',
    endWeighting: 'Зважування завершено',
    enterComment: 'Введіть коментарі',
    enterIdManuallyParking: 'Введіть вручну посвідчення особи',
    enterName: 'Введіть назву',
    enterPictureName: 'Введіть назву зображення',
    enterQuantity: 'Введіть кількість',
    enterTextHere: 'Введіть текст тут...',
    enterWeekNumber: 'Введіть номер тижня',
    enterYorTicket: 'Введіть свій номер квитка',
    erp: 'ERP',
    eventEnd: 'Кінець події',
    eventStart: 'Початок події',
    eventType: 'Тип події',
    executionCondition: 'Умова виконання',
    executionToday: 'Виконання сьогодні',
    executorData: 'Дані Виконавця завдань',
    executorRoleData: 'Дані Ролі виконавців',
    exitNoDispositionParking: 'Виїзд з паркінгу без розпорядження',
    exitWithoutDispositionParking: 'Ви впевнені, що хочете виїхати без розпорядження?',
    externalCodes: 'Зовнішні коди',
    fieldCols: 'Ширина поля',
    fields: 'Поля',
    fieldsManager: 'Визначення полів',
    fileDescription: 'Опис файлу',
    filePath: 'Шлях до файлу',
    fileVersions: 'Версії файлу',
    files: 'Файли',
    fillByTemplate: '-- Заповнити з шаблону --',
    fillNew: 'Заповнити для нових',
    fillTranslationsFromDictionary: 'Заповнити переклади зі словника',
    fillingOnBasis: 'Заповнення на основі',
    filter: 'Фільтр',
    filterDisabled: 'Фільтрування вимкнено',
    filterable: 'Фільтровані',
    filters: 'Фільтри',
    filtersManager: 'Визначення фільтрів',
    financialPeriod: 'Фінансові терміни',
    firm: 'Компанія',
    fontSize: 'Розмір шрифту',
    format: 'Формат',
    formatter: 'Форматування',
    forwarders: 'Експедитори',
    from: 'від',
    functionalDisabled: 'Обчислення вимкнено',
    general: 'Загальні',
    goToBegin: 'На початок',
    goToEnd: 'На кінець',
    gridView: 'Вид сітки',
    groupDisabled: 'Групування вимкнено',
    groupable: 'Можна групувати',
    height: 'Висота',
    help: 'Допомога',
    history: 'Історія',
    hours: 'Години',
    inWords: 'Словами',
    incorrectData: 'Введені некоректні дані!',
    instructionHasBeenRegisteredParking: 'Розпорядження зареєстровано в черзі. Чекайте на SMS з інформацією про виїзд!',
    instructionOrTicket: 'Номер розпорядження / квитка',
    interactionQuantity: 'Додано взаємодії',
    invoiceProFormEn: 'Рахунок-фактура проформа (en)',
    invoiceProFormPl: 'Рахунок-фактура проформа (pl)',
    invoiceServiceAgreements: 'Рахунок-фактура ПДВ №',
    isFirm: 'Я є фірмою',
    issueVATPerson: 'Особа, уповноважена на видачу рахунку-фактури ПДВ',
    issuedIn: ' від ',
    itemCols: 'Ширина елемента',
    itemOffset: 'Відстань',
    items: 'Елементи',
    itemsTree: 'Дерево елементів',
    justification: 'Обґрунтування',
    labelCols: 'Ширина заголовку',
    left: 'Ліво',
    leftToPay: 'Залишилося до сплати',
    length: 'Довжина',
    lengthWidth: 'Довжина/Ширина',
    links: 'Посилання',
    listView: 'Вид списку',
    loading: 'Завантаження',
    localSystem: 'Локальна установка',
    mainData: 'Основні дані',
    management: 'Управління',
    manualEditionRedirect: 'Ручне перенаправлення редагування',
    margins: 'Межі',
    maxHeight: 'Максимальна висота, пікс',
    maxWidth: 'Ширина (макс)',
    members: 'Члени',
    message: 'Повідомлення',
    minWidth: 'Ширина (мін)',
    minutes: 'Хвилини',
    missingParameter: 'Відсутній параметр',
    modalTitle: 'Увага!',
    module: 'Модуль',
    montage: 'Монтаж',
    msg: 'Повідомлення',
    msges: {
      selectItemFirst: 'Спочатку виберіть запит на пропозицію!',
    },
    multipleFiles: 'Кілька файлів',
    myCalendar: 'Мій календар',
    myFiles: 'Мої файли',
    navigation: 'Навігація',
    nettoWorth: 'Чиста вартість',
    new: 'Новий',
    newContact: 'Новий контакт',
    newField: 'Нове поле',
    newItem: 'Новий елемент',
    newRow: 'Новий рядок',
    newTable: 'Нова таблиця',no: 'Ні',
    noCalcsAvailable: 'Немає доступних розрахунків',
    noConfirmNipParking: 'Підтвердити без NIP',
    noData: 'Немає даних',
    noFieldsAvailable: 'Немає доступних полів',
    noFilters: 'Немає фільтрів',
    noFiltersAvailable: 'Немає доступних фільтрів',
    noFound: '<не знайдено>',
    noGroupsAvailable: 'Немає доступних групувань',
    noItemsAvailable: 'Немає доступних елементів',
    noPalacing: 'Немає розташування',
    noParentSubsystem: 'Немає підсистеми інструменту',
    noSelectedFiles: 'Не вибрано файлів',
    noSortingAvailable: 'Немає доступного сортування',
    nonOracleLoad: 'Не завантажуйте в Oracle',
    notEmptyField: 'Це поле не може бути порожнім',
    notFoundCodeSMS: 'Введено невірний код SMS, спробуйте ще раз',
    notFoundDriverParking: 'Дані водія не підтверджено. Зверніться до обслуговування паркінгу.',
    notFoundTicket: 'Немає номера квитка в системі',
    notSelected: 'Не вибрано',
    notSendMessage: "Не хочу отримувати повідомлення від 'Synergy'",
    notWeighting: 'Очікування на зважування',
    nothingToPreview: 'Немає нічого для попереднього перегляду.',
    notificationDate: 'Дата подачі',
    numberOfPieces: 'Кількість штук',
    numbers: 'Номери',
    objectFields: "Поля об'єкта",
    onChange: 'При зміні',
    onlyEmpty: 'Тільки порожні',
    openBtn: 'Кнопка "Відкрити"',
    orderConfirmPrint: 'Підтвердження замовлення',
    orderData: 'Дані замовлення',
    orders: 'Замовлення',
    other: 'Інше',
    pageOrientation: 'Орієнтація',
    pageSize: 'Розмір паперу',
    paid: 'Оплачено',
    partition: 'Розділ',
    payByCardparking: 'Оплатити карткою',
    payCardBankParking: 'Оплатіть банківською карткою',
    payCardSuccess: 'Оплата карткою пройшла успішно',
    payCardWarning: 'Не вдалося оплатити карткою. Спробуйте ще раз',
    payParking: 'Оплатити',
    paymentParking: 'Оплати',
    perPage: 'Ліміт',
    picturePath: 'Шлях до зображення',
    planedCompletitionDate: 'Планована дата завершення',
    preview: 'Попередній перегляд',
    previousWeek: 'Попередній тиждень',
    prices: 'Ціни',
    printSettings: 'Налаштування друку',
    private: 'Приватний',
    processing: 'Обробка',
    processingError: 'Сталася помилка під час виконання операції',
    productData: 'Дані продукту',
    productServiceName: 'Назва товару/послуги',
    productionOrder: 'Замовлення',
    productionOrders: 'Виробничі замовлення',
    productivity: 'Продуктивність',
    products: 'Продукти',
    projection: 'Проекція',
    punishmentParking: 'Штраф водія',
    punishmentPayParking: 'Нараховані оплати',
    query: 'Запит',
    quickFilter: 'Швидкий фільтр',
    quickFilters: 'Швидкі фільтри',
    recalculation: 'Перерахунок',
    receiveVATPerson: 'Особа, уповноважена на отримання рахунку-фактури ПДВ',
    recepientsCondition: 'Умова для одержувача',
    reclamationDecisionProtocol: 'Протокол рішення',
    reclamationDecisionProtocolPrint: 'Протокол рішення',
    reclamationMileageProtocol: 'Протокол пробігу',
    reclamationMileageProtocolPrint: 'Протокол пробігу',
    reclamationNotification: 'Рекламаційне повідомлення',
    reclamationRegistrationProtocol: 'Протокол реєстрації',
    reclamationRegistrationProtocolPrint: 'Протокол реєстрації',
    reclamationServiceProtocol: 'Сервісний протокол',
    reclamationServiceProtocolPrint: 'Сервісний протокол',
    regestrationDate: 'Дата реєстрації',
    register: 'Реєстр',
    registration: 'ЗАЯВКА',
    required: 'Обов’язкове',
    reservations: 'Резервації',
    resetTicket: 'Відв’язати квиток від розпорядження',
    right: 'Право',
    rows: 'Рядки',
    rowsLimit: 'Рядків на сторінку',
    sales: 'ПРОДАЖ',
    salesOrdersByCustomer: 'Замовлення клієнта за клієнтами',
    salesOrdersQuantity: 'Кількість нових замовлень клієнтів',
    salesOrdersValue: 'Вартість замовлень клієнтів',
    salesOrdersValueActual: 'Вартість нових замовлень клієнтів',
    salesPoint: 'Точка продажу',
    scanBarCode: 'Сканувати штрих-код',
    scanCode: 'Сканувати код',
    scanIdCardParking: 'Сканувати посвідчення особи',
    scanTicket: 'Сканувати квиток',
    search: 'Шукати...',
    searchAcquisitionChannel: 'Шукати канал залучення',
    searchAddress: 'Шукати адресу',
    searchAgreement: 'Шукати угоду',
    searchAssortment: 'Шукати асортимент',
    searchAuthor: 'Шукати автора',
    searchBase: 'Шукати базу',
    searchBasicDocument: 'Шукати основний документ',
    searchByTicketNumber: 'Шукати за номером квитка',
    searchCarrier: 'Шукати перевізника',
    searchCashFlowElement: 'Шукати елемент грошового потоку',
    searchCategory: 'Шукати категорію',
    searchCompletion: 'Шукати завершення',
    searchConstructor: 'Шукати конструктора',
    searchContactPerson: 'Шукати контактну особу',
    searchContractor: 'Шукати підрядника',
    searchControlCompany: 'Шукати контрольну компанію',
    searchCounterparty: 'Шукати контрагента...',
    searchCounterpartyAccountingGroup: 'Шукати облікову групу контрагента',
    searchCountry: 'Шукати країну',
    searchCurrency: 'Шукати валюту',
    searchCustomer: 'Шукати клієнта',
    searchCustomerOrder: 'Шукати замовлення клієнта',
    searchDecision: 'Шукати рішення',
    searchDeliveryOrder: 'Шукати замовлення',
    searchDepartment: 'Шукати відділ',
    searchDirective: 'Шукати розпорядження',
    searchDirector: 'Шукати керівника',
    searchDispAuthor: 'Шукати автора розпорядження',
    searchDisposition: 'Шукати розпорядження',
    searchDocumentationForms: 'Шукати форму ведення документації',
    searchDriver: 'Шукати водія',
    searchExecution: 'Шукати виконання',
    searchExecutor: 'Шукати виконавця',
    searchExpectation: 'Шукати очікування',
    searchForwarder: 'Шукати експедитора',
    searchGrossExecutor: 'Шукати виконавця брутто',
    searchIndustry: 'Оберіть галузь',
    searchItem: 'Шукати',
    searchLead: 'Шукати ліда',
    searchLegalForms: 'Шукати правову форму',
    searchLocality: 'Шукати населений пункт',
    searchManager: 'Шукати торгового представника',
    searchMine: 'Шукати шахту',
    searchNettoExecutor: 'Шукати виконавця нетто',
    searchOpType: 'Шукати тип операції',
    searchOrder: 'Шукати замовлення',
    searchOrderAuthor: 'Шукати автора замовлення',
    searchOriginWarehouse: 'Шукати місце походження',
    searchOwner: 'Шукати власника',
    searchPaymentDueDate: 'Шукати терміни платежів',
    searchPaymentType: 'Шукати тип платежу',
    searchPosition: 'Шукати позицію',
    searchProduct: 'Шукати товар',
    searchProductAccountingGroup: 'Шукати облікову групу товару',
    searchProductTo: 'Шукати товар до',
    searchProductVariant: 'Шукати варіант товару',
    searchProducts: 'Шукати продукти',
    searchProject: 'Шукати проект',
    searchRail: 'Шукати вагон',
    searchRealization: 'Шукати реалізацію',
    searchReason: 'Шукати причину',
    searchRelation: 'Шукати зв’язок',
    searchResponsible: 'Шукати відповідального',
    searchScale: 'Шукати вагу',
    searchShip: 'Шукати судно',
    searchStation: 'Шукати станцію',
    searchStatus: 'Шукати статус',
    searchSubject: 'Шукати предмет',
    searchSupplier: 'Шукати постачальника...',
    searchTaraExecutor: 'Шукати виконавця тари',
    searchTaskExecutor: 'Шукати виконавця завдань',
    searchTaxations: 'Шукати оподаткування',
    searchTrailer: 'Шукати причіп',
    searchTrain: 'Шукати поїзд',
    searchType: 'Шукати тип',
    searchUnitOfMeassure: 'Шукати одиницю виміру',
    searchVehicle: 'Шукати автомобіль',
    searchVendor: 'Шукати постачальника',
    searchVendorCustomer: 'Шукати замовника',
    searchWarehouse: 'Шукати склад',
    searchWarehouseFrom: 'Шукати склад з',
    searchWarehouseNumber: 'Шукати номер складу',
    searchWarehouseSector: 'Шукати сектор складу',
    searchWarehouseTo: 'Шукати склад до',
    searchWeighingStation: 'Шукати вагову станцію',
    seconds: 'Секунди',
    selectBtn: 'Кнопка "Оберіть з списку"',
    selectCustomer: 'Оберіть клієнта',
    selectDate: 'Оберіть дату',
    selectEmployee: 'Оберіть працівника',
    selectExecutor: 'Оберіть виконавця',
    selectExecutorRole: 'Оберіть роль виконавця',
    selectFileTitle: 'Оберіть файл!',
    selectForm: 'Оберіть документ',
    selectImportance: 'Оберіть важливість',
    selectLanguage: 'Оберіть мову',
    selectMethod: 'Оберіть метод',
    selectPossition: 'Оберіть позицію',
    selectProductTitle: 'Оберіть товар!',
    selectUser: 'Оберіть користувача',
    selected: 'Вибрано',
    selectedFields: 'Вибрані поля',
    selectedFilters: 'Вибрані фільтри',
    selectedServices: 'Вибрані послуги',
    seller: 'Продавець',
    sendMessageFromSpecific: 'Хочу отримувати повідомлення від вибраних електронних адрес',
    serviceParking: 'Послуга',
    servicesParking: 'Інші послуги',
    servicesParkingAdd: 'Пропоновані послуги',
    setZero: 'Обнулити',
    setZeroReceipt: 'Обнулити квитанцію',
    settings: 'Налаштування',
    settlementsData: 'Розрахунки',
    shared: 'Спільний',
    shipData: 'Дані судна',
    showMore: 'Показати більше',
    showTotals: 'Показати підсумки',
    signatureSettings: 'Налаштування електронного підпису користувача',
    sinceLastMonth: 'З останнього місяця',
    sortDisabled: 'Сортування вимкнене',
    sortable: 'Сортоване',
    sorting: 'Сортування',
    start: 'Початок',
    statisticByTraders: 'Статистика за торговими представниками',
    statisticCustomersRequestsByTraders: 'Статистика за торговими представниками - Запити на пропозицію',
    statisticSalesOrdersByTraders: 'Статистика за торговими представниками - Замовлення клієнтів',
    stopWeighting: 'Зважування зупинено',
    styles: 'Стилі',
    subsystem: 'Підсистема',
    taras: 'Тара',
    taskTemplatesData: 'Дані шаблону завдань',
    technicalData: 'Технічні дані',
    textAlign: 'Вирівнювання',
    textPosition: 'Положення в тексті',
    ticketInfo: 'Інформація про квиток',
    to: 'до',
    toatalAmountParking: 'Загальна сума (грн)',
    toolbar: 'Панель інструментів',
    toolbarItems: 'Елементи панелі інструментів',
    top: 'Верх',
    total: 'Разом',
    totalProjects: 'Всього проектів',
    totalTasks: 'Всього завдань',
    undefined: 'Не визначено',
    unitOfMeasure: 'од.',
    unitParking: 'грн',
    uploadFileInfo: 'Легко завантажте тут деякі файли за допомогою кнопки.',
    uploadYourFirstFile: 'Завантажте свій перший файл',
    usePagination: 'Використовувати пагінацію',
    userState: 'Статус користувача',
    valueType: 'Тип значення',
    values: 'Значення',
    vehicle: 'Автомобіль/вагон',
    vendorsAndCustomers: 'Постачальники та замовники',
    vendorsAndCustomersData: 'Дані постачальників і замовників',
    view: 'Перегляд',
    viewItems: 'Елементи перегляду',
    views: 'Перегляди',
    wastes: 'Відходи',
    weekNumber: 'Номер тижня',
    width: 'Ширина',
    worth: 'Вартість',
    writeComment: 'Напишіть коментар',
    yes: 'Так',
  },
  components: {
    addReminderView: 'Додати нагадування',
    advancedSearch: 'Розширений пошук',
    historyOfChanges: 'Історія змін',
    linkedDocs: 'Пов’язані документи',
    periodManager: 'Налаштування періоду',
    relatedTasks: 'Пов’язані завдання',
    report: 'Звіт',
    searchByTicketNumber: 'Шукати за номером квитка',
    subordination: 'Структура підпорядкування',
    versionDetails: 'Деталі версії',
    versionsCompare: 'Порівняння версій',
    viewSettings: 'Налаштування перегляду',
  },
  configurator: {
    byName: 'за назвою / описом ...',
    byNumber: 'за номером ...',
    selectedParameters: 'Вибрані параметри',
  },
  customerRequest: {
    addComment: 'Новий коментар',
    createNew: 'Створити запит',
    edit: 'Редагування запиту на пропозицію № ',
    editDecision: 'Зміна рішення',
    editResult: 'Редагування результату',
    editStatus: 'Зміна статусу',
    goToAnalytics: 'Перейти до аналітики',
    msg: {
      delete: 'Запит на пропозицію буде позначено для видалення!',
      emptyCustomer: 'Не заповнено клієнта!',
      emptyReference: 'Не заповнено посилання!',
      noWrite: 'Запит на пропозицію має бути збережений!',
      orderAvailable: 'Запит на пропозицію з таким посиланням вже існує: {0}',
      return: 'Запит на пропозицію буде відновлено!',
      selectItemFirst: 'Спочатку виберіть запит на пропозицію!',
    },
    new: 'Новий запит на пропозицію',
    newDecision: 'Нове рішення',
    newStatus: 'Виберіть новий статус',
    newVersion: 'Нова версія запиту на пропозицію',
    presentation: 'Запит на пропозицію № {0} від {1}',
    selectDecision: 'Виберіть рішення',
    startBp: 'Запустити процес узгодження',
    title: 'Запит на пропозицію',
  },
  discountBelongs: {
    all: 'Все',
    main: 'Основний прайс-лист',
    main_and_surcharge: 'Прайс-лист і доплати',
    surcharge: 'Доплати',
    surcharge_and_price: 'Доплати і додаткові артикули',
  },
  discountTypes: {
    formula: 'Формула',
    percent: 'Відсоток',
    price: 'Ціна',
  },
  editTypes: {
    created: 'Доданий',
    deleted: 'Видалений',
    edited: 'Змінений',
    markedToDeleteRestore: 'Позначений для видалення / Відновлений',
  },
  email: {
    all: 'Усі',
    attachTo: {
      customerRequest: 'Запит на пропозицію',
      interaction: 'Взаємодія',
      reclamation: 'Скарга',
    },
    attachments: 'Вкладення',
    bcc: 'Сліпа копія',
    body: 'Повідомлення',
    bodyPh: 'Текст повідомлення...',
    cc: 'Копія',
    contents: 'Вміст',
    create: {
      customerRequestCorrection: 'Корекція запиту на пропозицію',
      newCustomerRequest: 'Новий запит на пропозицію',
      newInteraction: 'Нова взаємодія',
      newReclamation: 'Нова скарга',
    },
    defaultEmailAccount: 'Стандартний обліковий запис електронної пошти',
    drafts: 'Чернетки',
    email: 'Електронна пошта',
    emailMoved: 'Вибрані повідомлення були переміщені!',
    emailSended: 'Електронна пошта надіслана',
    fillConterpartiesBegin: 'Система почала заповнювати контрагентів (осіб контактів) до електронної пошти',
    fillConterpartiesEnd: 'Система закінчила заповнювати контрагентів (осіб контактів) до електронної пошти',
    fillCounterparties: 'Заповніть контрагентів / осіб контактів в електронній пошті',
    fillCounterparty: 'Заповніть контрагента / особу контакту',
    forReceive: 'Для отримання',
    forSend: 'Для надсилання',
    from: 'Від',
    imapHost: 'IMAP сервер',
    imapPort: 'IMAP порт',
    imapTls: 'Використовувати SSL/TLS',
    inbox: 'Вхідні',
    invitedBy: 'запросив вас',
    labels: 'Мітки',
    manageLabels: 'Управління мітками',
    manageTemplates: 'Управління шаблонами',
    message: 'Повідомлення',
    moveTo: 'Перемістити до...',
    moveToJunk: 'Перемістити до спаму',
    moveToTrash: 'Перемістити до кошика',
    msg: {
      selectEmailFirst: 'Не вибрано жодного електронного листа!',
    },
    newMessage: 'Нове повідомлення',
    noEmails: 'Немає електронних листів',
    noTitle: '<Без теми>',
    none: 'Жоден',
    notProcessed: 'Не оброблені',
    onLineMode: 'Офлайн режим',
    other: 'Інше',
    otherFiles: 'Інші файли',
    processed: 'Оброблені',
    productionFile: 'Виробничий файл',
    recievedEmail: 'Отриманий електронний лист: ',
    response: 'Відповісти',
    responseAll: 'Відповісти всім',
    seen: 'Прочитано',
    selectEmailAccount: 'Виберіть обліковий запис електронної пошти',
    sent: 'Відправлено',
    sentEmail: 'Відправлений електронний лист: ',
    setAs: 'Позначити як',
    setLabel: 'Позначити міткою',
    setMailSignature: 'Встановити власний підпис',
    shown: 'Показано',
    signatures: 'Підписи',
    signed: 'Підписано',
    signedByClient: 'Підписано клієнтом',
    smtpHost: 'SMTP сервер',
    smtpPort: 'SMTP порт',
    smtpTls: 'Використовувати SSL/TLS',
    spam: 'Спам',
    storeReceived: 'Зберегти отримане',
    storeSended: 'Зберегти відправлене',
    subject: 'Тема',
    subjectPh: 'Тема повідомлення',
    title: 'Електронний лист',
    to: 'До',
    toSend: 'До відправлення',
    trash: 'Кошик',
    unseen: 'Непрочитано',
    userSignature: 'Підпис користувача',
    withLinks: 'З посиланнями',
    withoutLinks: 'Без посилань',
    writeSomething: 'Напишіть щось...',
    youOrganizer: 'Ви організатор',
    forSupplierFiles: 'Пропозиції постачальникам',
  },
  enums: {
    ACCUMULATION_RECORD_TYPES: {
      expense: 'Витрата',
      receipt: 'Прибуток',
    },
    ACTIVE_STATUSES: {
      Active: 'Активний',
      Blocked: 'Не активний',
    },
    BOM_STATUSES: {
      active: 'активний',
      canceled: 'скасований',
      draft: 'чернетка',
    },
    CAR_QUEUE_STATUS: {
      Accepted: 'В черзі',
      Deleted: 'Скасовано',
      EntryAddParking: 'На парковці дослідження',
      EntryAllowed: 'Виїзд дозволено',
      EntryReserved: 'Резервування без буферу',
      EntryReservedWithBuffer: 'Резервування з буфером',
      ExitAddParking: 'Виїхав з парковки дослідження',
      OnTheWay: 'В дорозі',
      ParkingExit: 'Виїхав з парковки',
      Paused: 'Пауза',
      TerminalExit: 'Виїхав з терміналу',
    },
    CURRENCY_EXCHANGE_TABLES: {
      A: 'A',
      B: 'B',
      C: 'C',
    },
    CUSTOM_STATE_OF_GOODS: {
      EU: 'З ЄС',
      NonEU: 'З-за меж ЄС',
    },
    DATA_TYPES: {
      boolean: 'Логічний',
      date: 'Дата',
      enum: 'Перелік',
      number: 'Число',
      object: 'Об\'єкт',
      string: 'Рядок',
      text: 'Текст',
    },
    DELIVERY_NOTE_STATE: {
      Closed: 'Закритий',
      OnBrutto: 'На брутто',
      OnTare: 'На таруванні',
      Returns: 'Повернення',
      ToApproved: 'На затвердження',
    },
    EXPRESSIONS: {
      average: 'Середнє',
      max: 'Макс',
      min: 'Мін',
      sum: 'Сума',
    },
    IMPORTANCES: {
      HIGH: 'Високий',
      LOW: 'Низький',
      NORMAL: 'Звичайний',
      NOT_SET: 'Не визначено',
    },
    INVENTORY_CATEGORY_TYPE: {
      income: 'Прибуток',
      shipment: 'Витрата',
      transfer: 'Переміщення',
    },
    ITEMS_PRESENTATIONS: {
      icon: 'Іконка',
      title: 'Назва',
      titleAndIcon: 'Назва та іконка',
    },
    NAV_VIEW_TYPES: {
      detail: 'Детальний',
      list: 'Список',
      report: 'Звіт',
      static: 'Статичний',
    },
    NOTIFICATION_TYPES: {
      copy: 'Копіювати',
      notSend: 'Не надсилати',
      recipient: 'Одержувач',
    },
    OBJECT_TYPES: {
      catalog: 'Каталог',
      document: 'Документ',
      register: 'Реєстр',
      report: 'Звіт',
    },
    PACKAGE_MATERIALS: {
      Folia: 'Плівка',
      Karton: 'Картон',
    },
    PAYMENT_TYPE_STATUSES: {
      Finished: 'Завершено',
      Ongoing: 'Триває',
    },
    PRODUCT_DOCUMENT_TYPES: {
      Doc1: 'Документ 1',
      Doc2: 'Документ 2',
    },
    PROJECT_STATUSES: {
      Finished: 'Завершено',
      Ongoing: 'Триває',
    },
    PROTOCOL_SCALE: {
      COM: 'COM',
      IoT: 'IoT',
      Mechanical: 'Механічна',
      Modbus: 'Modbus',
      S7: 'S7',
      'TCP/IP': 'TCP/IP',
    },
    PROTOCOL_SCALE_PARSE: {
      IT4000E: 'IT4000E',
      ToledoModbus: 'ToledoModbus',
      ToledoSimple: 'ToledoSimple',
      ToledoSimpleTwice: 'ToledoSimpleTwice',
    },
    REPORT_GROUP_POSITIONS: {
      cols: 'Стовпці',
      rows: 'Рядки',
    },
    REPORT_PERIOD_TYPES: {
      date: 'На дату',
      period: 'За період',
      without: 'Без періоду',
    },
    RESEARCH_RESULT: {
      Negative: 'Негативний результат',
      Positive: 'Позитивний результат',
    },
    SORT_DIRECTIONS: {
      asc: 'За зростанням',
      desc: 'За зменшенням',
    },
    TEXT_ALIGNS: {
      center: 'По центру',
      left: 'По лівому краю',
      right: 'По правому краю',
    },
    TEXT_POSITION: {
      begin: 'На початку',
      end: 'В кінці',
      part: 'В частині рядка',
    },
    TOOLBAR_ITEM_TYPES: {
      button: 'Кнопка',
      dropdown: 'Випадний список',
      dropdownItem: 'Елемент списку',
    },
    TRANSPORT_TYPES: {
      Car: 'Автомобіль',
      Carriage: 'Вагон',
      Trailer: 'Напівпричіп / Причіп',
    },
    TRANSPORT_WAYS: {
      ourTransport: 'Наш транспорт',
      shippingCompany: 'Транспортна компанія',
    },
    TYPE_OF_DISPOSITION: {
      Loading: 'Завантаження',
      Unloading: 'Розвантаження',
    },
    TYPE_OF_DOCUMENT: {
      Automobile: 'Автомобільний',
      Internal: 'Внутрішній',
      Railway: 'Залізничний',
      Water: 'Водний',
    },
    TYPE_OF_MOVEMENT: {
      Expense: 'Витрата',
      Receipt: 'Прибуток',
    },
    TYPE_OF_OPERATION: {
      DirectVariantReceipt: 'Пряме приймання',
      DirectVariantShipment: 'Пряме відвантаження',
      Receipt: 'Прийом',
      ReceiptConteiners: 'Прийом контейнерів',
      Relocation: 'Переміщення',
      Resigning: 'Переформування',
      Reweighing: 'Перев weighing',
      Shipment: 'Відвантаження',
    },
    TYPE_OF_PAYMENT: {
      Bank: 'Банк',
      Cash: 'Готівка',
    },
    TYPE_OF_SCALE: {
      Bunker: 'Бункер',
      Gross: 'Брутто',
      GrossAndTara: 'Брутто та тара',
      Tara: 'Тара',
      WithoutWeighing: 'Без ваги',
    },
    VEHICLE_TYPE: {
      car: 'До 3.5т',
      truck: 'Вантажний',
    },
    VERSIONING_METHODS: {
      all: 'Усі операції',
      confirm: 'При підтвердженні',
      create: 'При створенні',
      update: 'При оновленні',
    },
    VIEW_TYPES: {
      detail: 'Детальний',
      list: 'Список',
      other: 'Інше',
      select: 'Вибір',
    },
    WORK_SHIFTS: {
      First: 'I (23:00 - 07:00)',
      Second: 'II (07:00 - 15:00)',
      Third: 'III (15:00 - 23:00)',
    },
    changeTypes: {
      create: 'Додавання',
      update: 'Зміна',
    },
    changedTypes: {
      create: 'Додав',
      update: 'Змінив',
    },
    contactTypes: {
      email: 'Електронна пошта',
      messenger: 'Messenger',
      phone: 'Телефон',
      skype: 'Skype',
      social: 'Соціальні мережі',
    },
    navigationPlacings: {
      general: 'Важливе',
      other: 'Інше',
      usual: 'Звичайне',
    },
    statusList: {
      Finished: 'Закрито',
      Ongoing: 'Триває',
    },
    typesOfMovement: {
      Expense: 'Витрата',
      Receipt: 'Прибуток',
    },
    typesOfPayment: {
      Bank: 'Банк',
      Cash: 'Готівка',
    },
  },
  errorLog: {
    qrCodeNotFound: 'QR-код не знайдено'
  },
  excelEditor: {
    apply: 'Застосувати',
    back: 'Назад',
    customFilter: 'Налаштований фільтр',
    exactMatch: '= Точно дорівнює',
    exportExcel: 'Експортувати в Excel',
    filtered: 'Відфільтровано:',
    first: 'Перший',
    greaterThan: '&gt; Більше ніж',
    greaterThanOrEqualTo: '≥ Більше ніж або дорівнює',
    importExcel: 'Імпортувати з Excel',
    invalidInputValue: 'Недійсне значення вводу',
    last: 'Останній',
    lessThan: '&lt; Менше ніж',
    lessThanOrEqualTo: '≤ Менше ніж або дорівнює',
    loaded: 'Завантажено:',
    missingKeyColumn: 'Відсутня ключова колонка',
    near: '≒ Близько',
    next: 'Наступний',
    noMatchedColumnName: 'Не знайдено відповідної назви колонки',
    noRecordIndicator: 'Запис відсутній',
    noRecordIsRead: 'Жоден запис не читається',
    notMatch: '≠ Не дорівнює',
    previous: 'Попередній',
    processing: 'Обробка',
    readonlyColumnDetected: 'Виявлена колонка лише для читання',
    regularExpression: '~ Регулярний вираз',
    reset: 'За замовчуванням',
    selected: 'Вибрано:',
    sortAscending: 'Сортувати за зростанням',
    sortDescending: 'Сортувати за спаданням',
    sortingAndFiltering: 'Сортування та фільтрація',
    tableSetting: 'Налаштування таблиці',
  },
  fm: {
    actions: {
      add: 'Додати',
      addColumn: 'Додати колонку',
      addCorrespondence: 'Додати кореспонденцію',
      addItem: 'Додати позицію',
      addOption: 'Додати опцію',
      addTab: 'Додати вкладку',
      cancel: 'Скасувати',
      clear: 'Очистити',
      code: 'Згенерувати код',
      confirm: 'Підтвердити',
      copyData: 'Копіювати дані',
      getData: 'Отримати дані',
      import: 'Імпортувати JSON',
      json: 'Згенерувати JSON',
      preview: 'Попередній перегляд',
      reset: 'Скинути',
      upload: 'Завантажити',
    },
    components: {
      advance: {
        title: 'Розширений компонент',
      },
      basic: {
        title: 'Основний компонент',
      },
      fields: {
        _list: 'Список',
        blank: 'Користувацький',
        btnGroup: 'Група кнопок',
        button: 'Кнопка',
        card: 'Картка',
        cascader: 'Cascader',
        checkbox: 'Checkbox',
        collapse: 'Сховати',
        color: 'Колір',
        container: 'Контейнер',
        date: 'Дата',
        divider: 'Роздільник',
        dropdown: 'Випадаючий список',
        dynamic: 'Динамічний',
        dynamicList: 'Динамічний список',
        editor: 'Редактор',
        file: 'Файл',
        fileupload: 'Завантажити файл',
        grid: 'Сітка',
        group: 'Група',
        img: 'Зображення',
        imgupload: 'Завантажити зображення',
        input: 'Введення',
        inputGroup: 'Група введення',
        link: 'Посилання',
        number: 'Число',
        pagination: 'Пагінація',
        radio: 'Radio',
        rate: 'Оцінка',
        select: 'Вибір',
        slider: 'Слайдер',
        switch: 'Перемикач',
        table: 'Таблиця',
        tabs: 'Вкладки',
        text: 'Текст',
        textarea: 'Текстове поле',
        time: 'Час',
        toolbar: 'Панель інструментів',
        'view-list': 'Список',
      },
      layout: {
        title: 'Розташування',
      },
    },
    config: {
      form: {
        actions: 'Дії',
        customClass: 'Користувацький клас',
        dataSource: 'Джерело даних',
        functionDescription: 'Опис функції',
        functionNaem: 'Назва функції',
        labelPosition: {
          left: 'Ліворуч',
          right: 'Праворуч',
          title: 'Назва',
          top: 'Згори',
        },
        labelWidth: 'Ширина етикетки',
        methods: 'Методи',
        size: 'Розмір',
        title: 'Форма',
        toolbarContent: 'Вміст панелі інструментів',
      },
      widget: {
        accordion: 'Акордеон',
        action: 'Адреса завантаження',
        align: 'Вирівнювання',
        alignAround: 'По периметру',
        alignBaseline: 'По базовій лінії',
        alignBetween: 'Між',
        alignBottom: 'Внизу',
        alignCenter: 'По центру',
        alignContent: 'Вирівнювання вмісту',
        alignEnd: 'Кінець',
        alignH: 'Горизонтальне вирівнювання',
        alignMiddle: 'По середині',
        alignSelf: 'Вирівняти себе',
        alignStart: 'Початок',
        alignStretch: 'Розтягнути',
        alignTop: 'Вгорі',
        alignV: 'Вертикальне вирівнювання',
        allowHalf: 'Дозволити половину',
        appear: 'З’явитися',
        array: 'Масив',
        arrowControl: 'Використовуйте стрілки для вибору часу',
        attribute: 'Дія атрибута',
        block: 'Блок',
        bodyClass: 't-body стиль',
        boolean: 'Boolean',
        borderCard: 'Кордон-картка',
        bottom: 'Внизу',
        card: 'Вкладки',
        center: 'Центр',
        childrenOption: 'Підопція',
        clearable: 'Показати кнопку очищення',
        color: 'Колір',
        cols: 'колонки',
        columnOption: 'Налаштування колонок',
        contentPosition: 'Положення вмісту',
        custom: 'Користувацький',
        customClass: 'Стилі за замовчуванням',
        dataBind: 'Прив’язка даних',
        date: 'Дата',
        default: 'За замовчуванням',
        defaultType: 'Тип даних',
        defaultValue: 'Значення за замовчуванням',
        disabled: 'Вимкнено',
        dropleft: 'Випадне ліворуч',
        dropright: 'Випадне праворуч',
        dropup: 'Випадне вгору',
        editable: 'Поле редаговане',
        email: 'E-mail',
        endPlaceholder: 'Місце заповнювача кінцевого часу',
        fields: 'Колонки',
        filterable: 'Фільтрувальний',
        float: 'Плавання',
        fluid: 'Заповнення',
        format: 'Формат',
        height: 'Висота',
        hex: 'Шістнадцятковий',
        hidden: 'Схований',
        hover: 'Підсвітка вибраного',
        imageAction: 'Адреса завантаження зображення',
        inline: 'Вбудований',
        integer: 'Ціле число',
        isDelete: 'Видаляється',
        isEdit: 'Редагований',
        isQiniu: 'Завантаження з Qiniu Cloud',
        isRange: 'Діапазон часу',
        isTimestamp: 'Отримати часову мітку',
        itemsOption: 'Налаштування списку',
        justify: 'Горизонтальне розташування',
        justifyCenter: 'Вирівняти по центру',
        justifyEnd: 'Кінець',
        justifySpaceAround: 'Простір навколо',
        justifySpaceBetween: 'Простір між',
        justifyStart: 'Початок',
        keyNav: 'Навігація клавішами',
        label: 'Назва',
        labelWidth: 'Ширина етикетки',
        layout: 'Макет',
        left: 'Ліворуч',
        lg: 'lg',
        max: 'Максимум',
        maxlength: 'Максимальна довжина',
        md: 'md',
        min: 'Мінімум',
        model: 'Модель',
        multiple: 'Множинний',
        name: 'Назва',
        newOption: 'Нова опція',
        noCaret: 'Без стрілки',
        noFlip: 'Без перевертання',
        noGutter: 'Вимкнути відстань сітки',
        number: 'Число',
        object: 'Обʼєкт',
        offset: 'зміщення',
        option: 'Опція',
        order: 'порядок',
        password: 'Пароль',
        patternPlaceholder: 'Заповніть регулярні вирази',
        perPage: 'Кількість рядків',
        pill: 'Капсула',
        placeholder: 'Заповнювач',
        range: 'Діапазон',
        readonly: 'Тільки для читання',
        remoteData: 'Віддалені дані',
        remoteFunc: 'Віддалена функція',
        required: 'Обов’язково',
        right: 'Праворуч',
        rowCols: 'Колонки в рядку',
        rowColsLg: 'Колонки в рядку (Lg)',
        rowColsMd: 'Колонки в рядку (Md)',
        rowColsSm: 'Колонки в рядку (Sm)',
        rowColsXl: 'Колонки в рядку (Xl)',
        search: 'Пошук',
        selectMode: 'Режим вибору',
        selectable: 'Вибиральний',
        showAlpha: 'Підтримка параметрів прозорості',
        showInput: 'Показати текстове поле',
        showLabel: 'Показати мітку',
        showPassword: 'Показати пароль',
        showType: 'Тип показу',
        showWordLimit: 'Показати ліміт слів',
        size: 'Розмір',
        sm: 'sm',
        sortBy: 'Сортувати за',
        split: 'Розділити',
        squared: 'Квадратний',
        startPlaceholder: 'Заповнювач початкового часу',
        staticData: 'Статичні дані',
        step: 'Крок',
        string: 'Рядок',
        striped: 'Смугастий',
        subTitle: 'Підзаголовок',
        tab: 'Вкладка',
        tabName: 'Назва вкладки',
        tabOption: 'Налаштування міток',
        tabPosition: 'Позиція вкладки',
        tabTitle: 'Атрибути',
        tag: 'Тег',
        tel: 'Телефон',
        text: 'Текст',
        time: 'Час',
        tip: 'Текстова підказка',
        title: 'Заголовок',
        tokenFunc: 'Функція для отримання Qiniu Uptoken',
        top: 'Зверху',
        type: 'Тип',
        uploadLimit: 'Максимальна кількість завантажень',
        url: 'URL',
        validate: 'Валідація',
        validatorPattern: 'Невідповідний шаблон',
        validatorRequired: 'Обов’язковий',
        validatorType: 'Невірний формат',
        value: 'Значення',
        variant: 'Варіант',
        variants: {
          danger: 'Небезпека',
          dark: 'Темний',
          info: 'Інформація',
          light: 'Світлий',
          outlineDanger: 'Контурна небезпека',
          outlineDark: 'Контурний темний',
          outlineInfo: 'Контурна інформація',
          outlineLight: 'Контурний світлий',
          outlinePrimary: 'Контурний основний',
          outlineSecondary: 'Контурний другорядний',
          outlineSuccess: 'Контурний успіх',
          outlineWarning: 'Контурне попередження',
          primary: 'Основний',
          secondary: 'Другорядний',
          success: 'Успіх',
          warning: 'Попередження',
        },
        vertical: 'Вертикальний',
        visible: 'Видимий',
        width: 'Ширина',
        xl: 'xl',
      },
    },
    description: {
      configEmpty: 'Будь ласка, додайте елемент',
      containerEmpty: 'Ви можете перетягнути та відпустити елемент зліва, щоб додати до Компонентів',
      tableEmpty: 'Ви можете перетягнути та відпустити елемент зліва, щоб додати до Компонентів',
      uploadJsonInfo: 'Нижче є формат JSON, ви можете замінити його своїм кодом JSON.',
    },
    message: {
      copySuccess: 'Копіювання завершено',
      deleteItem: 'Елемент буде відзначений для видалення!',
      recipientEmpty: 'Не вказано одержувача!',
      reportOptionGetError: 'Помилка отримання налаштувань звіту',
      restoreItem: 'Елемент буде відновлений!',
      topicEmpty: 'Тема не може бути порожньою!',
      validError: 'Аутентифікація форми не вдалася',
    },
    upload: {
      delete: 'видалити',
      edit: 'замінити',
      preview: 'перегляд',
    },
  },
  interaction: {
    actualStatus: 'Актуальний статус',
    addComment: 'Додати коментар',
    addEvent: 'Додати подію',
    addNewVersion: 'Додати нову версію',
    attachments: 'Додатки до взаємодії',
    commentsAndLinkedEmails: 'Коментарі / Пов’язані листи',
    edit: 'Редагування взаємодії № ',
    msg: {
      delete: 'Взаємодія буде відзначена для видалення!',
      emptyCustomer: 'Не вказано клієнта!',
      noWrite: 'Взаємодія має бути збережена!',
      orderAvailable: 'Взаємодія з таким посиланням вже існує: {0}',
      return: 'Взаємодія буде відновлена!',
      selectItemFirst: 'Спочатку виберіть взаємодію!',
    },
    new: 'Нова взаємодія',
    newVersion: 'Нова версія взаємодії',
    number: 'Номер взаємодії',
    numberEdition: 'Редагування номера взаємодії',
    presentation: 'Взаємодія № {0} від {1}',
    selectFiles: 'Виберіть файли!',
    tasksAndSalesOrders: 'Завдання / Замовлення клієнта',
    versionsHistory: 'Історія версій',
  },
  login: {
    authCode: 'Код підтвердження',
    authCodePh: 'Введіть код підтвердження',
    authDescription: 'Введіть ваш код підтвердження, щоб продовжити вхід',
    description: 'Введіть ваш логін і пароль, щоб отримати доступ до адміністративної панелі',
    forgotPassword: 'Не пам’ятаєте пароль?',
    invalidAuthCode: 'Неправильно введений код підтвердження',
    invalidLogin: 'Неправильний логін (електронна пошта) або пароль',
    logIn: 'Увійти',
    noHaveAccount: 'Немає аккаунту?',
    outOfDateCode: 'Код підтвердження вичерпано. Запросіть новий код.',
    password: 'Пароль',
    passwordPh: 'Введіть пароль',
    register: 'Процес реєстрації',
    rememberMe: 'Запам’ятати мене',
    securityCheck: 'Перевірка безпеки',
    sendNewCode: 'Відправити новий код',
    unexpectedError: 'Сталася несподівана помилка, спробуйте ще раз',
    username: 'Користувач',
    usernamePh: 'Введіть ваш логін',
  },
  msg: {
    authCodeSended: 'Новий код підтвердження був надісланий.',
    bpExecuteActionError: 'Не вдалося виконати дію.',
    catalogWriteError: 'Не вдалося зберегти елемент каталогу',
    catalogWriteSuccess: 'Елемент каталогу був збережений',
    countryNotFilled: 'Заповніть країну',
    createTrackingError: 'Не вдалося додати об’єкт до трекінгу',
    createTrackingSuccess: 'Об’єкт був доданий до трекінгу',
    delete: 'Елемент буде позначено для видалення!',
    deleteError: 'Помилка видалення елемента',
    documentConfirmError: 'Не вдалося підтвердити документ',
    documentConfirmErrorMarekedToDelete: 'Не вдалося підтвердити документ, позначений для видалення',
    documentConfirmSuccess: 'Документ був підтверджений',
    documentConfirmUndoSuccess: 'Підтвердження документа було скасовано',
    documentUndoConfirmError: 'Не вдалося скасувати підтвердження документа',
    documentWriteSuccess: 'Документ був збережений',
    emptyObjectFIelds: 'Поля об’єкта не можуть бути порожніми',
    filingError: 'Дані невірні',
    groupHasNestedItems: 'Група має підпорядковані елементи',
    impossibleDelete: 'Не можливо видалити.',
    insufficientRights: 'Недостатньо прав для виконання операції',
    maxLimitRows: 'Перевищено максимальний ліміт даних {limit} рядків. Будь ласка, зменшіть діапазон.',
    noAccess: 'Доступ заборонено',
    noFilesForSend: 'Не можна надіслати електронну пошту. Файл для клієнта не був прикріплений.',
    noFoundResults: 'Не знайдено результатів для',
    noRelatedViewSettings: 'Відсутні пов’язані налаштування перегляду',
    notFoundNextStage: 'Не вдалося знайти наступний етап.',
    nothingFound: 'Не знайдено результатів',
    nothingSelected: 'Нічого не вибрано',
    passwordConfirmationError: 'Паролі не співпадають',
    processStartFailure: 'Помилка під час запуску процесу',
    processStartSuccess: 'Процес успішно розпочався',
    reminderAddedSuccesfully: 'Нагадування було додано',
    reminderNotAdded: 'Заповніть дані коректно',
    restore: 'Елемент буде відновлено!',
    searchResultsFor: 'Шукаємо результати для',
    setDefaultSettings: 'Налаштування користувача видалено.',
    startTypingForSearch: 'Почніть вводити для пошуку.',
    technicalBreak: 'Технічна перерва',
    viesFalse: 'Контрагент не є платником ПДВ-ЄС',
    viesTrue: 'Контрагент є платником ПДВ-ЄС',
    wrongNip: 'Неправильний формат ЕГРПОУ',
  },
  navbar: {
    checkAll: 'Перевірити всі',
    clearAll: 'Очистити всі',
    hello: 'Привіт!',
    logOut: 'Вийти',
    newNotes: 'Нові сповіщення',
    notifications: 'Сповіщення',
    profile: 'Мій профіль',
  },
  navigation: {
    editRoute: 'Редагувати вид',
    editSubsystem: 'Редагування підсистеми',
    getPrezentation: 'Отримати презентацію з репозиторію',
    newPartition: 'Новий розділ',
    newRoute: 'Новий вид',
    newSubsystem: 'Нова підсистема',
  },
  operators: {
    '!=': 'Не дорівнює',
    '<': 'Менше',
    '<=': 'Менше або дорівнює',
    '=': 'Дорівнює',
    '>': 'Більше',
    '>=': 'Більше або дорівнює',
    between: 'Між',
    eq: 'Дорівнює',
    filled: 'Заповнене',
    gt: 'Більше',
    gte: 'Більше або дорівнює',
    iLike: 'Містить',
    inList: 'У списку',
    like: 'Містить',
    lt: 'Менше',
    lte: 'Менше або дорівнює',
    ne: 'Не дорівнює',
    notFilled: 'Не заповнене',
    notILike: 'Не містить',
    notInList: 'Не у списку',
    notLike: 'Не містить',
  },
  order: {
    byOrderPeriod: 'По періоду замовлення',
    createTask: 'Створити завдання',
    deliveryDays: 'Дні доставки: ',
    deliveryMethods: {
      ON_CERTAIN_DATE: 'На визначену дату',
      STANDARD: 'Стандарт',
      UNTIL_CERTAIN_DATE: 'До визначеної дати',
      VIP: 'VIP',
    },
    enterDeliveryMethod: 'Виберіть метод доставки',
    enterMaterial: 'Виберіть матеріал',
    enterProject: 'Введіть назву проекту',
    enterReference: 'Введіть посилання',
    msg: {
      confirmDouble: 'Замовлення з таким посиланням вже існує. Підтвердіть, що хочете перейти до оцінки?',
      confirmRemoveProduct: 'Ви впевнені, що хочете видалити цей продукт?',
      delete: 'Замовлення буде відмічене для видалення!',
      emptyBlock: 'Відсутнє заповнення!',
      emptyCurrency: 'Не вказана валюта!',
      emptyCustomer: 'Не вказаний клієнт!',
      emptyDeliveryMethod: 'Не вказано метод доставки!',
      emptyFields: 'Не заповнені всі обов’язкові поля!',
      emptyReference: 'Не вказане посилання!',
      emptyStatus: 'Не вказаний статус!',
      orderAvailable: 'Замовлення з таким посиланням вже існує: {0}',
      return: 'Замовлення буде відновлене!',
      selectOrderFirst: 'Спочатку виберіть замовлення!',
      writeOrder: 'Замовлення має бути збережене!',
      writed: 'Замовлення було збережене',
    },
    new: 'Нове замовлення',
    objectTitle: 'Замовлення',
    orderName: 'Замовлення',
    packageMaterial: {
      Folia: 'Плівка',
      Karton: 'Картон',
    },
    presentation: 'Замовлення № {0} від {1}',
    priceDetails: 'Деталі ціни',
    roadDays: 'Дні в дорозі: ',
    shipmentDays: 'Дні відправлення: ',
    status: {
      CANCELED: 'Скасоване',
      CLOSED: 'Закрите',
      CONFIRMED: 'Підтверджене',
      NEW: 'Нове замовлення',
      OFERTA: 'Пропозиція',
      READY_FOR_PRODUCTION: 'Готове до виробництва',
    },
    title: 'Замовлення клієнтів'
  },
  paymentOperation: {
    createTask: 'Створити завдання',
    enterProject: 'Введіть назву проекту',
    enterReference: 'Введіть посилання',
    msg: {
      confirmDouble: 'Операція з таким посиланням вже існує. Підтвердіть, що хочете перейти до оцінки?',
      delete: 'Операція буде відмічена для видалення!',
      emptyCurrency: 'Не вказана валюта!',
      emptyCustomer: 'Не вказаний клієнт!',
      emptyFields: 'Не заповнені всі обов’язкові поля!',
      emptyReference: 'Не вказане посилання!',
      orderAvailable: 'Операція з таким посиланням вже існує: {0}',
      return: 'Операція буде відновлена!',
      selectOrderFirst: 'Спочатку виберіть операцію!',
      writeOrder: 'Операція має бути збережена!',
      writed: 'Операція була збережена',
    },
    new: 'Нова операція',
    objectTitle: 'Операція оплати',
    presentation: 'Операція № {0} від {1}',
    title: 'Операції оплат',

  },
  pricelistKinds: {
    main: 'Основний',
    surcharge: 'Доплата',
  },
  pricingMethods: {
    fixed: 'Фіксована ціна',
    formula: 'Формула',
    percent: 'Відсоток',
    scale: 'Цінова шкала',
    table: 'Цінова таблиця',
  },
  project: {
    action: 'Дія',
    assignedTo: 'Призначено на',
    behind: 'Затримано',
    completed: 'Завершено',
    completedTasksOf: 'Завершені завдання з',
    inProgress: 'В процесі',
    monthlyReport: 'Місячний звіт',
    projectStatus: 'Статус проекту',
    recentActivity: 'Остання активність',
    settings: 'Налаштування',
    status: 'Статус',
    taskOverview: 'Огляд завдань',
    tasks: 'Завдання',
    totalTimeSpent: 'Загальний витрачений час',
    weeklyReport: 'Тижневий звіт',
  },
  question: {
    businessProcessStart: 'Ви впевнені, що хочете розпочати бізнес-процес?',
    closeModifiedView: 'Дані були змінені. При закритті виду зміни будуть втрачені!',
  },
  reclamation: {
    add: 'Додати рекламацію',
    addOrder: 'Додати замовлення',
    addUpdatePositions: 'Додавання / Редагування позицій замовлення рекламації',
    bulkInteractionForPositions: 'Масова взаємодія по позиціях',
    negative: 'Відхилено',
    new: 'Нова рекламація',
    ongoing: 'У процесі',
    open: 'Відкрите',
    productionJobNumber: 'Номер виробничого завдання',
    toReceive: 'До отримання',
  },
  register: {
    address: 'Адреса',
    addressPh: 'Введіть адресу',
    backHome: 'Повернутися на головну сторінку',
    canResetPasswordMsg: 'Тепер ви можете змінити свій пароль.',
    city: 'Місто',
    cityPh: 'Введіть місто',
    commercialInChargeOfYou: 'Відповідальний за вас торговий представник',
    commercialInChargeOfYouPh: 'Введіть відповідального за вас торгового представника',
    companyDepartment: 'Відділ компанії',
    companyDepartmentPh: 'Введіть відділ компанії',
    confirm: 'Ваш акаунт успішно зареєстровано',
    country: 'Країна',
    countryPh: 'Введіть країну',
    deliveryDepartment: 'Відділ доставки',
    deliveryDepartmentPh: 'Введіть відділ доставки',
    email: 'E-mail',
    emailCheck: 'Перевірте електронну пошту від порталу та натисніть прикріплене посилання, щоб підтвердити email.',
    emailConfirmed: 'E-mail підтверджено',
    emailNotConfirmed: 'Не вдалося підтвердити e-mail',
    emailPh: 'Введіть свій e-mail',
    emailSent: 'E-mail успішно надіслано. Будь ласка, перевірте свою поштову скриньку.',
    emailSentError: 'Виникла помилка під час спроби надіслати e-mail. Будь ласка, спробуйте пізніше.',
    enterDetailsPh: 'Введіть свої дані',
    enterNewPassword: 'Введіть новий пароль',
    enterPasswordLimit: 'Введіть не менше 7 символів.',
    enterValidEmail: 'Введіть правильну електронну пошту',
    firmAddress: 'Адреса компанії',
    firmAddressPh: 'Введіть адресу компанії',
    firmName: 'Назва компанії',
    firmNamePh: 'Введіть назву компанії',
    firmTitle: 'Реєстрація компанії',
    firstLastName: 'Ім’я та прізвище',
    haveAccount: 'Уже маєте акаунт?',
    login: 'Логін',
    loginPh: 'Введіть свій логін',
    manager: 'Менеджер',
    name: 'Ім’я',
    notFilledAllFilds: 'Не заповнені всі поля',
    notVatNumber: 'Не маю внутрішнього номера ПДВ',
    passwordLinkSent: 'Посилання для зміни пароля надіслано на вказану електронну пошту.',
    phone: 'Телефон',
    phonePh: 'Введіть номер телефону',
    postalCode: 'Поштовий індекс',
    postalCodePh: 'Введіть поштовий індекс',
    reenterEmail: 'Введіть знову свою електронну пошту для нового посилання.',
    reenterPassword: 'Введіть пароль знову',
    reenterPasswordError: 'Повинно відповідати введеному паролю.',
    register: 'Процес реєстрації',
    resendEmail: 'Надіслати e-mail знову',
    resendYourEmail: 'Надіслати свій e-mail знову',
    resetPasswordError: 'Виникла помилка під час обробки вашого запиту. Спробуйте ще раз надіслати інформацію для скидання пароля.',
    resetPasswordLate: 'Здається, ви трохи запізнилися. Спробуйте ще раз надіслати e-mail.',
    sendTo: 'E-mail надіслано на',
    surename: 'Прізвище',
    terms: 'Умови користування порталом',
    title: 'Реєстрація користувача',
    titleDescription: 'Не маєте акаунту? Зареєструйтесь, це займе менше хвилини',
    vatNumber: 'Внутрішній номер ПДВ',
    vatNumberPh: 'Введіть внутрішній номер ПДВ',
  },
  report: {
    byDate: 'На день',
    byPeriod: 'За період',
    byPeriodFrom: 'За період: з ',
    byPeriodTo: ' до ',
    calcs: 'Розрахунки',
    calculated: 'розрахункові',
    changeVariantName: 'Змінити назву варіанту звіту',
    columnName: 'Назва колонки',
    deleteVariant: 'Видалити варіант звіту',
    filtered: 'фільтрувальні',
    filters: 'Фільтри',
    funcs: 'Розрахунки',
    functional: 'функціональні',
    functions: {
      average: 'Середнє',
      max: 'Макс',
      min: 'Мін',
      sum: 'Сума',
    },
    groupLvls: 'Рівні групування',
    grouped: 'груповані',
    groups: 'Групування',
    hideDetails: 'Сховати деталі для групування',
    isTotal: 'Підсумкові',
    msg: {
      generateError: 'Помилка генерації звіту',
      periodControl: 'Початок періоду не може бути більшим за кінець.',
    },
    newVariant: 'Новий варіант звіту',
    notGenerated: 'Звіт не згенеровано. Натисніть "Генерувати", щоб згенерувати звіт',
    onlyForThisUser: 'Тільки для цього користувача',
    resourses: 'Підсумування',
    selectedFields: 'Вибрані поля',
    sorts: 'Сортування',
    total: 'Разом',
    variants: 'Варіанти звіту',
    withParentGroup: 'На одному рівні з батьківським групуванням',
    writeVariant: 'Зберегти варіант',
  },
  reports: {
    email: 'Email',
    inquiriesAnalize: 'Аналіз запитів на пропозиції',
    interactionAnalize: 'Аналіз взаємодій',
    interactionTimeAnalize: 'Аналіз часу роботи взаємодій',
    salesAnalize: 'Аналіз продажу',
    salesOrderRegister: 'Реєстрація замовлень',
  },
  route: {
    qualityParameters: 'Якісні показники',
    accessGroup: 'Група доступу',
    accessGroups: 'Групи доступу',
    accessLogs: 'Журнали доступу',
    accessRestriction: 'Обмеження доступу',
    accessRestrictions: 'Обмеження доступу',
    action: 'Дія',
    addEditHistory: 'Додавання / Редагування історії',
    addUpdateProductionOrders: 'Додавання / Редагування виробничих замовлень',
    additionalParameters: 'Додаткові параметри',
    administration: 'Адміністрування',
    appModule: 'Модуль системи',
    appModules: 'Модулі системи',
    appObject: 'Обʼєкт системи',
    appObjects: 'Обʼєкти системи',
    appSetting: 'Налаштування системи',
    appSettingGroup: 'Група налаштувань системи',
    appSettingItem: 'Визначення налаштування системи',
    appSettingItems: 'Визначення налаштувань системи',
    appSettings: 'Налаштування системи',
    assortment: 'Асортимент',
    assortments: 'Асортименти',
    availableNavigation: 'Доступна навігація (Меню)',
    bankExpense: 'Виходящий переказ',
    bankReceipt: 'Вхідний переказ',
    bom: 'BOM',
    boms: 'BOM',
    box: 'Складська комірка',
    boxes: 'Складські комірки',
    bpAndTasks: 'Бізнес-процеси та завдання',
    bpDefinition: 'Визначення бізнес-процесу',
    bpDefinitions: 'Визначення бізнес-процесів',
    bpExemplars: 'Бізнес-процеси',
    calendar: 'Календар',
    carrier: 'Перевізник',
    carriers: 'Перевізники',
    carsQueueManagment: 'Управління чергою автомобілів',
    cashExpense: 'Готівковий витрат',
    cashFlowItem: 'Елемент грошового потоку',
    cashFlowItems: 'Грошові потоки',
    cashReceipt: 'Готівковий прийом',
    component: 'Компонент',
    components: 'Компоненти',
    contactPerson: 'Контактна особа',
    contactPersons: 'Контактні особи',
    controlCompanies: 'Контрольні компанії',
    controlCompany: 'Контрольна компанія',
    costArticle: 'Тип витрат',
    costArticles: 'Типи витрат',
    costomer: 'Клієнт',
    costomers: 'Клієнти',
    counterparties: 'Контрагенти',
    counterparty: 'Контрагент',
    counterpartyAccountingGroup: 'Бухгалтерська група контрагента',
    counterpartyAccountingGroups: 'Бухгалтерські групи контрагентів',
    counterpartyAddress: 'Адреса контрагента',
    counterpartyAddresses: 'Адреси контрагентів',
    countries: 'Країни',
    country: 'Країна',
    crStatuse: 'Статус запиту на пропозицію',
    crStatuses: 'Статуси запитів на пропозиції',
    crTag: 'Тег запиту на пропозицію',
    crTags: 'Теги запитів на пропозиції',
    currencies: 'Валюти',
    currency: 'Валюта',
    customer: 'Дані клієнта',
    customerRequest: 'Запит на пропозицію',
    customerRequestAnalytics: 'Аналіз запиту на пропозицію',
    customerRequestStatuses: 'Статуси запитів на пропозицію',
    customerRequestTags: 'Теги запитів на пропозицію',
    customerRequests: 'Запити на пропозицію',
    dashboard: 'Панель управління',
    dashboards: 'Панелі управління',
    deliveryNotes: 'Накладні',
    deliveryOrders: 'Замовлення',
    deliveryPayment: 'Оплата за користування паркінгом',
    deliveryPayments: 'Оплати за користування паркінгом',
    deliveryRoute: 'Маршрут доставки',
    deliveryRoutes: 'Маршрути доставки',
    deliverySchedule: 'Графік доставки',
    deliveryScheduleStatus: 'Статус графіків доставки',
    deliveryScheduleStatuses: 'Статуси графіків доставки',
    deliverySchedules: 'Графіки доставки',
    deliveryType: 'Тип доставки',
    deliveryTypes: 'Типи доставки',
    department: 'Відділ',
    departments: 'Відділи',
    discount: 'Виробничий знижка',
    discounts: 'Виробничі знижки',
    dispositionStatuse: 'Статус розпорядження',
    dispositionStatuses: 'Статуси розпоряджень',
    dispositions: 'Розпорядження',
    documentNumerators: 'Нумератори документів',
    documentPrefixes: 'Префікси документів',
    documentationForm: 'Документування бухгалтерії',
    documentationForms: 'Форми документування',
    driver: 'Водій',
    drivers: 'Водії',
    emailAccount: 'Електронна пошта',
    emailAccounts: 'Електронні пошти',
    emailDetails: 'Електронна пошта',
    emailLabel: 'Мітка електронної пошти',
    emailLabels: 'Мітки електронної пошти',
    emailTemplateEdition: 'Редагування шаблону електронної пошти',
    emailTemplates: 'Шаблони електронної пошти',
    employee: 'Працівник',
    employees: 'Працівники',
    enum: 'Перерахування',
    enums: 'Перерахування',
    equipment: 'Обладнання',
    equipments: 'Обладнання',
    event: 'Подія',
    eventStatus: 'Статус події',
    eventStatuses: 'Статуси подій',
    eventType: 'Тип події',
    eventTypes: 'Типи подій',
    events: 'Події',
    exchangeNodes: 'Обмінні вузли',
    exchangeObjectEdition: 'Редагування обʼєкта обміну',
    exchangeObjects: 'Обʼєкти обміну',
    exchangeRate: 'Курс валюти',
    exchangeRates: 'Курси валют',
    executor: 'Виконавець завдань',
    executorRole: 'Роль виконавця',
    executorRoles: 'Ролі виконавців',
    executors: 'Виконавці завдань',
    filesManager: 'Менеджер файлів',
    financialPeriods: 'Фінансові періоди',
    firm: 'Компанія',
    firms: 'Компанії',
    formBuilder: 'Конструктор форм',
    forms: 'Видовище',
    goToParentDocuments: 'Перейти до повʼязаних документів',
    interaction: 'Взаємодія',
    interactionStatuses: 'Статуси взаємодій',
    interactionTags: 'Теги взаємодій',
    interactions: 'Взаємодії',
    inventoryCategories: 'Категорії документів складу',
    inventoryDocuments: 'Документи складу',
    inventoryOperationType: 'Тип операцій складу',
    inventoryOperationTypes: 'Типи операцій складу',
    inventoryStatuses: 'Статуси документів складу',
    legalForm: 'Юридична форма',
    legalForms: 'Юридичні форми',
    loadingPlan: 'План завантаження судна',
    loadingPlans: 'Плани завантаження суден',
    localities: 'Місцевості',
    locality: 'Місцевість',
    logistic: 'Логістика',
    mailbox: 'Поштова скринька',
    main: 'Основна',
    mainDesktopSettings: 'Налаштування основного робочого столу',
    mine: 'Шахта',
    mines: 'Шахти',
    navigation: 'Налаштування навігації',
    newEmail: 'Новий електронний лист',
    numerators: 'Нумератори документів',
    objectVersioningSetting: 'Налаштування версіонування обʼєкта',
    objectVersioningSettings: 'Налаштування версіонування обʼєктів',
    orderForTransport: 'Замовлення на транспортування',
    orderSubject: 'Замовлений предмет',
    orderSubjects: 'Замовлені предмети',
    orderType: 'Тип замовлення',
    orderTypes: 'Типи замовлень',
    ordersForTransports: 'Замовлення на транспортування',
    otherSettings: 'Інші налаштування',
    outgoingEmailDetails: 'Надіслано електронний лист',
    outgoingSms: 'Вихідні SMS',
    paymentType: 'Тип платежу',
    paymentTypes: 'Типи платежів',
    position: 'Посада',
    positions: 'Посади',
    pricelist: 'Ціновий лист',
    pricelists: 'Цінові листи',
    printForm: 'Форма друку',
    product: 'Дані позиції',
    productVariant: 'Варіант продукту',
    additionalParametersSets: 'Набори додаткових параметрів',
    productVariants: 'Варіанти продукту',
    production: 'Виробництво',
    products: 'Продукти',
    project: 'Проєкт',
    projects: 'Проєкти',
    punishment: 'Оплата за користування паркінгом',
    punishmentType: 'Тип оплати за користування паркінгом',
    punishments: 'Оплати за користування паркінгом',
    punishmentsTypes: 'Типи оплат за користування паркінгом',
    "purchase": "Закупівля",
    "reclCatalogs": "Словники",
    "reclDecisions": "Рішення",
    "reclExecutions": "Виконання",
    "reclPerpetrators": "Особи, відповідальні за рекламації",
    "reclReasons": "Причини рекламацій",
    "reclStates": "Статуси замовлень",
    "reclSubjects": "Предмети замовлень",
    "reclTypes": "Типи замовлень",
    "reclamation": "Рекламація",
    "reclamationClientDemand": "Очікування клієнта",
    "reclamationClientDemands": "Очікування клієнта",
    "reclamationDecision": "Рішення",
    "reclamationDecisions": "Рішення",
    "reclamationExecutionType": "Тип виконання замовлень",
    "reclamationExecutionTypes": "Типи виконання замовлень",
    "reclamationPerpetrator": "Особа, відповідальна за рекламацію",
    "reclamationPerpetrators": "Особи, відповідальні за рекламацію",
    "reclamationReason": "Причина рекламації",
    "reclamationReasons": "Причини рекламацій",
    "reclamationStatistic": "Статистика рекламацій",
    "reclamationStatus": "Статус замовлень",
    "reclamationStatusGroup": "Група статусів",
    "reclamationStatusGroups": "Групи статусів",
    "reclamationStatuses": "Статуси замовлень",
    "reclamationSubject": "Предмет замовлень",
    "reclamationSubjects": "Предмети замовлень",
    "reclamationType": "Тип замовлення",
    "reclamationTypes": "Типи замовлень",
    "reclamations": "Рекламації",
    "reclamationviewform": "Рекламація (перегляд)",
    "registrationprotocol": "Протокол реєстрації",
    "reglamentTask": "Регламентне завдання",
    "reglamentTasks": "Регламентні завдання",
    "remainingViews": "Залишкові вигляди",
    "report": "Звіт",
    "reportSetting": "Налаштування звіту",
    "reportSettings": "Налаштування звітів",
    "reports": "Звіти",
    "restApi": "Rest API",
    "roleGroups": "Групи прав",
    "sales": "Продажі",
    "salesOrder": "Замовлення клієнта",
    "salesOrderStatus": "Статус замовлення клієнта",
    "salesOrderStatuses": "Статуси замовлень клієнтів",
    "salesOrders": "Замовлення клієнтів",
    "salesPoints": "Пункти продажу",
    "salesPrice": "Цінник",
    "salesPrices": "Цінники",
    "scale": "Вага",
    "scaleProtocol": "Протокол ваги",
    "scaleProtocols": "Протоколи ваг",
    "scales": "Ваги",
    "scalesCars": "Автомобільні ваги",
    "scheduledJob": "Регламентне завдання",
    "schemeOfCargo": "Схема перевантаження",
    "schemesOfCargo": "Схеми перевантаження",
    "serviceAgreement": "Договір надання послуг",
    "serviceAgreements": "Договори надання послуг",
    "serviceCharge": "Оплата послуг",
    "serviceCharges": "Оплати послуг",
    "settings": "Налаштування",
    "settlements": "Розрахунки",
    "ship": "Корабель",
    "shipLoading": "Завантаження корабля",
    "shipLoadings": "Завантаження кораблів",
    "shipRegistration": "Реєстрація корабля",
    "shipRegistrations": "Реєстрації кораблів",
    "shipment": "Відправлення",
    "shippingOfComplaintItems": "Відправлення рекламаційних елементів",
    "ships": "Кораблі",
    "shipsPlanning": "Планування кораблів",
    "standing": "Посада працівника",
    "standings": "Посади працівників",
    "statusGroups": "Групи статусів",
    "storagePeriod": "Перiод зберігання",
    "storagePeriods": "Перiоди зберігання",
    "task": "Завдання",
    "taskTemplate": "Шаблон завдань",
    "taskTemplates": "Шаблони завдань",
    "tasks": "Завдання",
    "taxation": "Оподаткування",
    "taxations": "Оподаткування",
    "termsOfPayment": "Умови платежу",
    "timeTracking": "Облік часу",
    "unitOfMeasure": "Одиниця виміру",
    "unitsOfMeasure": "Одиниці виміру",
    "user": "Користувач",
    "userNavigation": "Налаштування навігації користувачів",
    "userProfile": "Профіль користувача",
    "userRole": "Роль користувача",
    "userRoles": "Ролі користувачів",
    "userSettingGroup": "Група налаштувань користувача",
    "userSettingItem": "Опис налаштування користувача",
    "userSettingItems": "Опис налаштувань користувачів",
    "userSettings": "Налаштування користувачів",
    "users": "Користувачі",
    "usersAndAccess": "Користувачі та права",
    "usersList": "Список користувачів",
    "usersSettings": "Налаштування користувачів",
    "vatRates": "Ставки ПДВ",
    "vehicle": "Транспортний засіб",
    "vehicles": "Транспортні засоби",
    "vendorsAndCustomers": "Постачальники та замовники",
    "viewSetting": "Налаштування вигляду",
    "viewSettings": "Налаштування виглядів",
    "warehouse": "Склад",
    "warehouses": "Склади",
    "weighingStation": "Вагова станція",
    "weighingStations": "Вагові станції",
    "weightingsHistories": "Історія ваг",
    "weightingsHistory": "Історія ваг",
    "work": "Виконувана робота",
    "workingPlaces": "Місця роботи",
    "works": "Виконувані роботи",
    "worksheet": "Листок роботи",
    "worksheets": "Листки роботи"
  },
  settings: {
    lsbThemeDark: "Темна",
    lsbThemeDefault: "За замовчуванням",
    lsbThemeLight: "Світла",
    lsbTypeCondensed: "Стислий",
    lsbTypeFixed: "Фіксований",
    lsbTypeScrollable: "Прокручуваний",
    title: "Налаштування"
  },
  ships: {
    imo: "Міжнародна морська організація"
  },
  table: {
    department: 'Відділ',
    addContact:"Додати контакт",
    typeProduct: "Тип продукту",
    unused: "Невживаний",
    otherFont: "Інший шрифт",
    productType: 'Тип продукту',
    serviceCategory: 'Категорія послуги',
    qualityParameter: 'Якісний показник',
    standard: 'Стандарт',
    batchOfFinishedProducts: "Партія готової продукції",
    shiftAssigments: "Завдання на зміну",
    brigade: "Бригада",
    specification: "Специфікація",
    packer: "Упаковник",
    total: "Всього",
    batchNumber: "Номер партії",
    label: "Етикетка",
    seriesNumber: "Номер серії",
    localSeriesNumber: "Локальний номер серії",
    taskCategory: 'Категорія завдання',
    CounterpartyCode: "Код контрагента",
    abbreviation: "Скорочення",
    acceptFiles: "Доступні файли",
    accessRole: "Роль доступу",
    accountingArea: "Область бухгалтерії",
    accountingName: "Назва для бухгалтерії",
    acquisitionChannel: "Джерело залучення клієнта",
    action: "Дія",
    actions: "Дії",
    active: "Активний",
    activeDate: "Дата надходження",
    activitiesDescription: "Опис діяльності",
    actualWarehouse: "Місце зберігання",
    added: "Додано",
    additional: "Додатковий",
    additionalProducts: "Додаткові продукти",
    address: "Адреса",
    addressesPhones: "Адреси та телефони",
    adminAccess: "Адміністраторський доступ",
    advicesNumber: "Номер авізо / складу",
    aftDraft: "Задній осад",
    aftDraftDeparture: "Задній осад при відправленні",
    afterLoadingDraft: "Осад після завантаження",
    agent: "Агент",
    agreement: "Угода",
    akord: "Акорд",
    algorithm: "Алгоритм",
    allowChangeExecutor: "Дозволити зміну виконавця",
    allowChangeStage: "Дозволити зміну етапу",
    allowNav: "Доступні деталі",
    allowNull: "Дозволити Null",
    allowRetaring: "Дозволити повторне ввезення",
    allowSetZero: "Дозволити обнулення квитка",
    alphaCode2: "Код Alpha-2",
    alphaCode3: "Код Alpha-3",
    alreadyOrdered: "Замовлено",
    amount: "Сума",
    amountToOrder: "Кількість для замовлення",
    amountsType: "Тип суми",
    api: "API",
    appObject: "Об'єкт системи",
    approved: "Затверджено",
    approvedAuthor: "Автор затвердження",
    approvedDate: "Дата затвердження",
    arrivalDate: "Дата прибуття",
    arrivalDateDriver: "Дата і час прибуття на парковку",
    arrivalDay: "Дата прибуття на парковку",
    arrivalDraft: "Осад прибуття",
    arriveFrom: "Прибуття від",
    article: "Стаття",
    assortment: "Асортимент",
    assortmentGmpPLus: "Асортимент GMP+",
    attentionCount: "Кількість зауважень",
    attribs: "Атрибути",
    author: "Автор",
    autoIncrement: "Автоматичне зростання",
    automaticWeightingEnd: "Автоматичне завершення зважування",
    averagePrice: "Середня ціна",
    balance: "Залишок",
    balancedScale: "Стабільна вага",
    bankAccount: "Номер рахунку",
    barCode: "Штрих-код",
    barCodePrefix: "Префікс штрих-коду",
    base: "Основний",
    baseDocument: "Основний документ",
    basic: "Основний",
    basicDocument: "Основний документ",
    basicItem: "Основний елемент",
    batch: "Партія",
    batches: "Партії",
    begin: "Початок",
    beginDate: "Дата початку",
    beginWeighting: "Початок зважування",
    belongs: "Відноситься до",
    bidSum: "Сума пропозиції",
    birthDate: "Дата народження",
    blockSelfServiceInterface: "Блокувати інтерфейс самообслуговування",
    blockUnblockSelfServiceInterface: "Блокувати/Розблокувати інтерфейс самообслуговування",
    body: "Тіло",
    booked: "Заброньовано",
    bookedHere: "Заброньовано тут",
    box: "Складська комірка",
    boxTo: "Складська комірка до",
    bpStageEditing: "Редагування етапу бізнес-процесу",
    breaks: "Опадні перерви",
    browseText: "Текст вибору",
    brutto: "Брутто",
    bruttoAuthor: "Автор брутто",
    bruttoTime: "Дата брутто ваги",
    bruttoWeight: "Вага брутто",
    btsStatement: "Заява BTS",
    buisnessProcess: "Бізнес-процес",
    buyer: "Покупець",
    calendarEvent: "Подія в календарі",
    cancelWeighting: "Скасувати зважування",
    capasity: "Ємність",
    captain: "Капітан",
    carBrand: "Марка автомобіля",
    carNumber: "Номер автомобіля",
    carPositionReport: "Звіт про розташування автомобіля",
    carQueueStatus: "Статус черги автомобілів",
    carServiceTime: "Час обслуговування автомобіля",
    cargoNumber: "Номер вантажу",
    carriageNumber: "Номер вагона",
    carrier: "Перевізник",
    carsBuffer: "Буфер автомобіля",
    carsBufferParking: "Буфер автомобілів на парковці",
    carsBufferTerminal: "Буфер автомобілів на терміналі",
    carsQueueStatus: "Статус черги автомобілів",
    cashFlowItem: "Елемент грошового потоку",
    category: "Категорія",
    changeDate: "Дата зміни",
    changeType: "Тип редагування",
    characteristic: "Характеристика",
    chargedServices: "Нараховані послуги",
    checkExecution: "Перевірити виконання",
    checkReceivedSms: "Перевірка отриманих SMS",
    checkRielaProducts: "Перевірити продукти Riela",
    checker: "Перевіряльник",
    city: "Місто",
    client: "Клієнт",
    clientId: "Ідентифікатор клієнта",
    clientName: "Ім'я клієнта",
    clientSurname: "Прізвище клієнта",
    closeDate: "Дата закриття",
    closed: "Закрито",
    closingDate: "Дата закриття",
    code: "Код",
    code1C: "Код 1C",
    color: "Колір",
    comPort: "COM порт",
    comment: "Примітка",
    comments: "Коментарі",
    commission: "Комісія, %",
    component: "Компонент",
    computerName: "Назва комп'ютера",
    confirmation: "Підтвердження",
    confirmed: "Підтверджено",
    connection: "З'єднання",
    constr: "Конструктор",
    constructionAddress: "Адреса будівництва",
    constructionCity: "Місто будівництва",
    constructionPostCode: "Поштовий індекс будівництва",
    constructor: "Конструктор",
    contact: "Контакт",
    contactPerson: "Контактна особа",
    containerNumber: "Номер контейнера",
    contents: "Вміст",
    contractor: "Підрядник",
    controlCompany: "Контрольна компанія",
    controlSignalLight0: "0 як сигнал управління",
    controlSignalLight1: "1 як сигнал управління",
    controller: "Контролер",
    correspondence: "Кореспонденція",
    correspondenceAddress: "Адреса кореспонденції",
    cost: "Вартість",
    costs: "Витрати",
    counterparties: "Контрагенти",
    counterparty: "Контрагент",
    counterparty2: "Власник",
    counterpartyAccountingGroup: "Бухгалтерська група контрагента",
    counterpartyAddress: "Адреса контрагента",
    country: "Країна",
    createdAt: "Створено",
    currency: "Валюта",
    currencyStr: "Валюта (стр)",
    currentDateExecution: "Виконати в один день",
    customGoodsStatus: "Митний статус товару",
    customer: "Клієнт",
    customerAccess: "Доступно для мене",
    customerLockOrder: "Блокувати замовлення для клієнта",
    customersGroup: "Група клієнтів",
    daily: "Денний",
    dataLinked: "Пов'язано з змінною",
    dataType: "Тип даних",
    date: "Дата",
    dateA: "Дата A",
    dateB: "Дата B",
    dateFrom: "Діє з",
    dateOfBirth: "Дата народження",
    dateOfTest: "Дата тестування",
    dateTo: "Діє до",
    dateType: "Тип дати",
    days: "Дні",
    daysExceeded: "Перевищено днів",
    daysOfMonth: "Дні місяця",
    daysOfWeek: "Дні тижня",
    daysSinceAdmission: "Дні з моменту прийому",
    dbName: "Назва (БД)",
    decimalPrecision: "Розмір",
    decimalScale: "Точність",
    decision: "Рішення",
    declaredTare: "Декларована тара",
    declaredTonnage: "Декларований тоннаж",
    default: "За замовчуванням",
    defaultText: "Текст за замовчуванням",
    defaultValue: "Значення за замовчуванням",
    defaultWarehouse: "Основний склад",
    delete: "Видалення",
    deleteConfirmation: "Ви дійсно хочете видалити елемент з бази даних?",
    deliveryAddress: "Адреса доставки",
    deliveryDate: "Дата доставки",
    deliveryMethod: "Спосіб доставки",
    deliveryNote: "Накладна",
    deliveryNoteNumber: "Номер накладної",
    deliveryOrder: "Замовлення",
    deliverySchedule: "Графік доставки",
    deliveryStatus: "Статус доставки",
    deliveryTime: "Термін доставки (дні)",
    departureDate: "Дата відправлення",
    departureDraft: "Осад при відправленні",
    depth: "Глибина",
    deputy: "Заступник",
    descComment: "Коментар",
    description: "Опис",
    desktopMode: "Режим настільного ПК (AMP)",
    desktopName: "Назва настільного ПК (AMP)",
    destination: "Місце призначення",
    destinationPort: "Порт призначення",
    detailPath: "Шлях до елемента редагування",
    detailView: "Перегляд елемента",
    deviation: "Відхилення",
    dimensionX: "Ось X",
    dimensionY: "Ось Y",
    dimensions: "Розміри",
    disableControlInDispositions: "Вимкнути контролі на розпорядженнях",
    disableControlOnScales: "Вимкнути контролі на вагах",
    disabled: "Тільки для читання",
    discount: "Знижка",
    discountPercent: "% знижки",
    discountSum: "Сума знижки",
    discountType: "Тип знижки",
    discription: "Опис",
    disposition: "Розпорядження",
    dispositionAndProductCard: "Розпорядження та картка товару",
    dispositionAuthor: "Розпорядження видано",
    dispositionCon: "Розпорядження",
    dispositionForScale: "Розпорядження (на вагу)",
    doNotLoadToOracle: "Не завантажувати в Oracle",
    docType: "Тип документа",
    dockNumber: "Номер дока",
    documentType: "Тип документа",
    documentationForm: "Бухгалтерська документація",
    documents: "Документи",
    done: "Виконано",
    download: "Завантаження",
    driver: "Водій",
    driverPhoneNumber: "Номер телефону водія",
    driverTicket: "Квиток водія",
    editCondition: "Редагування умови",
    email: "Електронна пошта",
    emailAccount: "Електронна пошта",
    employee: "Працівник",
    endDate: "Дата закінчення",
    endOfWeighting: "Кінець зважування",
    endTime: "Кінець часу",
    endWeighting: "Кінець зважування",
    ending: "Кінець",
    entry: "Дата в'їзду",
    entryTicket: "Квиток на в'їзд",
    enumerator: "Лічильник",
    equipment: "Обладнання",
    equipmentNumber: "Номер обладнання",
    equipmentShipPrint: "Номери кранів",
    euMember: "Член ЄС",
    exchangeRate: "Курс обміну",
    exchangeRateDate: "Дата курсу обміну валют",
    exchangeRateTableNumber: "Номер таблиці курсів валют",
    execution: "Виконання",
    executionDays: "Дні на виконання",
    executionHours: "Години на виконання",
    executionMinutes: "Хвилини на виконання",
    executionPeriod: "Термін виконання",
    executionTerm: "Термін реалізації",
    executionTermRecl: "Реалізація",
    executor: "Виконавець",
    executorName: "Назва виконавця",
    executorRole: "Роль виконавця",
    expireDate: "Дата закінчення терміну",
    exporter: "Експортер",
    exporters: "Експортери",
    expression: "Вираз",
    externalCode: "Зовнішній код",
    externalData: "Зовнішнє джерело даних",
    externalDatabase: "Зовнішня база даних",
    externalDateFormat: "Формат дати",
    externalDateKind: "Тип дати",
    externalDialect: "Діалект",
    externalId: "Зовнішній ідентифікатор",
    externalIdShort: "Зовнішнє ID",
    externalPassword: "Пароль",
    externalPort: "Порт",
    externalServer: "Сервер",
    externalUser: "Зовнішній користувач",
    externalUsername: "Користувач бази даних",
    facebook: "Facebook",
    factor: "Коефіцієнт",
    fakturaCheck: "Рахунок-фактура",
    fakturaNIP: "NIP",
    fakturaName: "Назва",
    female: "Жіночий",
    field: "Поле",
    fieldType: "Тип поля",
    fieldTypePath: "Шлях до типу",
    fields: "Поля",
    fileSize: "Розмір, кБ",
    filter: "Фільтр",
    filters: "Фільтри",
    financialPeriod: "Фінансові терміни",
    firm: "Компанія",
    firstWeighting: "Перше зважування",
    folder: "Папка",
    folders: "Папки",
    forFolder: "Для папок",
    forReception: "Склад для прийому",
    forServices: "Для послуг",
    forShipment: "Для відправки",
    foreman: "Бригадир",
    formTypeToShow: "Тип форми для відображення",
    formula: "Формула",
    forwardDraft: "Осад вперед",
    forwardDraftDeparture: "Осад вперед при відправленні",
    forwarder: "Експедитор",
    forwarders: "Експедитори",
    from: "Від",
    fullData: "Повні дані",
    fullName: "Повна назва",
    fullPackagingLength: "Повна довжина/упаковка",
    fullRights: "Повні права",
    fullSearch: "Повнотекстовий пошук",
    function: "Функція",
    functions: "Функції",
    goodsCosts: "Витрати на товари",
    goodsInsufficientFree: "Недостатні товари (вільні)",
    grossAmount: "Вартість (брутто)",
    grossWeight: "Вага брутто",
    groupChange: "Групова зміна",
    groupType: "Тип групи",
    guarantee: "Гарантія",
    handlers: "Оператор",
    hasLinks: "Є зв'язки",
    hashParam: "Параметр Hash (#)",
    header: "Заголовок",
    headers: "Заголовки",
    height: "Висота",
    hidden: "Прихований",
    hiddenM: "Прихований",
    hideDetails: "Сховати деталі",
    hierarchic: "Ієрархічний",
    hold: "Тримати",
    holds: "Тримання",
    holdsPrint: "Тримання / тримання",
    hour: "Год.",
    hours: "Години",
    hoursQuantity: "Кількість годин",
    howToContact: "Як зв'язатися",
    httpMethod: "Метод HTTP",
    hyperLink: "Гіперпосилання",
    icon: "Іконка",
    id: "ID",
    idCard: "Посвідчення особи",
    idNumber: "Номер посвідчення",
    image: "Зображення",
    imo: "IMO",
    importance: "Пріоритет",
    includedInMain: "Включено в основний",
    incomingDate: "Дата документа",
    incomingNumber: "Номер зовнішнього документа",
    index: "Індекси",
    industry: "Сфера",
    initDate: "Дата початку",
    inquiry: "Запит",
    instagram: "Instagram",
    interaction: "Взаємодія",
    internalComment: "Внутрішній коментар",
    internalReminder: "Внутрішнє нагадування",
    inventoryBatches: "Партії запасів",
    invoiceItems: "Пункти рахунку",
    iotGateParameters: "Параметри шлюзу IoT",
    ip: "IP",
    isActive: "Активний",
    isCanceled: "Скасовано",
    isCardNumber: "Номер картки",
    isCardSerial: "Серія картки",
    isClosed: "Закрито",
    isDefault: "За замовчуванням",
    isFinal: "Фінальний",
    isFolder: "Папка",
    isGeneral: "Загальний",
    isHandcrafted: "Ручна робота",
    isPaid: "Оплачено",
    isPrint: "Друкований",
    isSelect: "Вибраний",
    isService: "Сервісний",
    isStarted: "Почато",
    isStatic: "Статичний",
    isTotal: "Показати разом",
    issuanceRailwayInstructions: "Видача залізничних розпоряджень",
    item: "Товар",
    itemNumber: "Поз.",
    items: "Елементи",
    itemsReady: "Елементи готові до отримання",
    jOM: "О.М.",
    key: "Ключ",
    krs: "KRS",
    labelPosition: "Розміщення заголовка",
    lang: "Переклад",
    language: "Мова",
    lastNumber: "Останній номер",
    lead: "Керівництво",
    leader: "Керівник",
    legalForm: "Правова форма",
    length: "Довжина",
    lightTable: "Світлова таблиця",
    lineNumber: "№",
    link: "Посилання",
    linkedin: "LinkedIn",
    links: "Зв'язки",
    loadCapacity: "Навантажувальна здатність",
    loadingEnd: "Кінець завантаження",
    loadingStart: "Початок завантаження",
    locality: "Місцевість",
    lockArrivesFrom: "Блокування прибуття з",
    lodging: "Проживання",
    loggingOn: "Увімкнути журналювання",
    login: "Логін",
    mail: "Пошта",
    main: "Головна",
    mainToolbar: "Основна панель інструментів",
    male: "Чоловічий",
    manager: "Менеджер",
    manual: "Ручний",
    markDeletedMails: "Синхронізація видалених",
    markedToDelete: "Позначено для видалення",
    material: "Матеріал",
    max: "Макс",
    maxDiscount: "Максимальна знижка",
    maxLength: "Максимальна довжина",
    maxLoss: "Макс. втрата",
    maxRows: "Максимальна кількість рядків",
    maxValue: "Максимальна значення",
    message: "Повідомлення",
    method: "Метод",
    min: "Мин",
    minA: "Мин A",
    minOrderValue: "Мінімальна вартість замовлення",
    minValue: "Мінімальна значення",
    mine: "Шахта",
    minimumStock: "Мінімальний запас",
    minusTrafficLight: "Занадто близько",
    minutes: "Хвилини",
    minutesCarService: "Час обслуговування автомобіля",
    mode: "Режим",
    model: "Модель",
    modify: "Редагування",
    module: "Модуль",
    month: "Місяць",
    monthDay: "День місяця",
    monthNumber: "Номер місяця",
    months: "Місяці",
    moving: "Переміщення",
    multi: "Багато",
    multiType: "Складений тип",
    multiple: "Множинний",
    multiplier: "Коефіцієнт",
    name: "Назва",
    nameFull: "Повне ім'я",
    netWeight: "Нетто вага",
    netto: "Нетто",
    nettoAuthor: "Автор нетто",
    nettoOverAll: "Загальне нетто, Т",
    nettoRegistered: "Реєстроване нетто, Т",
    nettoTime: "Дата нетто ваги",
    networkPort: "Мережевий порт",
    nextStep: "Наступний крок",
    nip: "NIP",
    noFilters: "Без фільтрів",
    notNull: "Не NULL",
    notUse: "Не використовувати",
    notUseDiscount: "Не застосовувати знижки",
    note: "Примітка",
    notes: "Примітки",
    notificationType: "Тип сповіщення",
    number: "Номер",
    numberAndDateDSKAndMRN: "Номер, дата DSK/MRN",
    numberAndDateSdk: "Номер, дата SDK",
    numberCopies: "Кількість копій",
    numberFormat: "Формат номера",
    numberOfCopies: "Кількість копій",
    numberOfDays: "Кількість днів",
    numberOfWeighings: "Кількість зважувань",
    numberPrecision: "Точність числа",
    numberScale: "Точність",
    numberStr: "Номер (Str)",
    object: "Об'єкт",
    objectDescription: "Опис об'єкта",
    objectId: "ID об'єкта",
    objectType: "Тип об'єкта",
    offer: "Пропозиція",
    okTrafficLight: "Ок",
    onlyQuantities: "Тільки кількості для замовлення",
    onlySelected: "Тільки вибрані",
    operation: "Операція",
    operationType: "Тип операції",
    operator: "Умова",
    order: "Замовлення",
    orderAdditionally: "Замовити додатково",
    orderAuthor: "Замовлення видано",
    orderForTransport: "Замовлення на транспортування",
    orderInsufficientGoods: "Замовлені товари недостатні",
    orderNum: "Зам.",
    orderNumber: "№ замовлення",
    ordered: "Замовлено",
    orders: "Замовлення",
    organization: "Організація",
    orientation: "Орієнтація",
    owner: "Власник",
    packageMaterial: "Матеріал упаковки",
    packageNumber: "Номер пакунка",
    panelMode: "Режим панелі",
    paramValues: "Значення параметрів",
    params: "Параметри",
    parent: "Належить до",
    parentDepartment: "Належить до відділу",
    parentField: "Батьківське поле",
    parkingAriveDateAndTime: "Дата та час прибуття на парковку",
    password: "Пароль",
    passwordConfirmation: "Підтвердження пароля",
    path: "Шлях",
    payedAdvance: "Сплачений аванс",
    payerVat: "Платник ПДВ",
    payerVies: "Платник ПДВ-ЄС",
    payment: "Документ оплати",
    paymentDate: "Термін оплати",
    paymentMethod: "Спосіб оплати",
    paymentTerms: "Умови оплати",
    paymentType: "Тип оплати",
    period: "Період",
    periodType: "Тип періоду",
    periodicity: "Частота",
    phone: "Телефон",
    phoneNumber: "№ телефону",
    placeholder: "Підказка",
    placing: "Розміщення",
    plannedDockingTime: "Запланований час докування",
    plannedEntry: "Запланована дата в'їзду",
    plusAnswers: "Відповіді Plus",
    plusTrafficLight: "Занадто далеко",
    port: "Порт",
    position: "Позиція",
    positionDescription: "Опис позиції",
    positionNumber: "Номер позиції",
    positions: "Позиції",
    possitionWh: "Позиція WH",
    postCode: "Поштовий код",
    postDate: "Дата відправлення",
    predefinedName: "Задане ім'я",
    prefix: "Префікс",
    presentation: "Презентація",
    price: "Ціна",
    priceCode: "Код прайс-листа",
    priceNational: "Ціна (країна)",
    priceType: "Тип ціни",
    priceWithoutVAT: "Ціна Нетто",
    pricelist: "Ціновий список",
    pricelistKind: "Тип прайс-листа",
    pricesType: "Тип цін",
    pricingMethod: "Метод оцінки",
    primaryKey: "Основний ключ",
    printerList: "Список принтерів",
    printerName: "Назва принтера",
    priority: "Пріоритет",
    processName: "Назва процесу",
    processed: "Ороблений",
    product: "Продукт",
    productAccountingGroup: "Бухгалтерська група продукту",
    productCard: "Картка товару",
    productVariant: "Варіант продукту",
    productionDate: "Дата виробництва",
    productionOrder: "Виробниче замовлення",
    productionQuantity: "Кількість виробництв",
    productionStock: "Стан складу (виробничий)",
    products: "Продукти",
    proforma: "Про-Форма",
    progressValue: "Прогрес",
    prohibitionWorkSeveralSessions: "Заборона роботи в кількох сесіях",
    project: "Проект",
    protocol: "Протокол",
    ptotocols: "Протоколи",
    punishment: "Накладена плата",
    punishmentType: "Тип плати за користування паркінгом",
    punishments: "Плати за користування паркінгом",
    quantity: "Кількість",
    quantityToReserve: "Кількість для резервування",
    quarterNumber: "Номер кварталу",
    query: "Запит",
    queryParam: "Параметр запиту (?)",
    queueOn: "У черзі",
    rangeA: "Діапазон A",
    rangeB: "Діапазон B",
    rate: "Курс",
    rateDate: "Дата курсу",
    read: "Перегляд",
    readOnly: "Тільки для читання",
    realizationDate: "Дата реалізації",
    reason: "Причина",
    receipts: "Квитанції",
    receivedSms: "Отримані",
    recipient: "Одержувач",
    recipients: "Одержувачі",
    reclamationNumber: "№ рекламації",
    reclamationOrderNumber: "Номер замовлення",
    reclamationRelatedTasks: "Завдання, пов'язані з рекламацією",
    recorder: "Реєстратор",
    ref: "Посилання",
    reference: "Референція",
    referenceNumber: "№ референції",
    reflect: "Відображати",
    region: "Регіон",
    registry: "Реєстр",
    regon: "REGON",
    remained: "Залишилось",
    remainedEn: "Remained",
    report: "Звіт",
    reportR27: "Звіт R27",
    reports: "Звіти",
    requiredComment: "Необхідний коментар",
    researchResult: "Результати досліджень",
    reservations: "Резервування",
    resource: "Ресурс",
    responsibility: "Відповідальність",
    responsible: "Відповідальний",
    result: "Результат",
    resultEmail: "Електронна пошта",
    retaring: "Ретарування",
    returnToWarehouse: "Повернення на склад",
    reverseChange: "Зворотна зміна",
    reverseOperation: "Зворотна операція",
    role: "Роль",
    rollPrinter: "Роликовий принтер",
    route: "Маршрут",
    rowNumber: "№ рядка",
    rowsCount: "Кількість рядків",
    saleDate: "Дата продажу",
    salesDate: "Дата продажу",
    salesOrder: "Замовлення клієнта",
    scale: "Вага",
    scale2: "Вага 2",
    scaleBrutto: "Брутто вага",
    scaleChangeReport: "Звіт про зміни ваги",
    scaleNetto: "Нетто вага",
    scaleProtocol: "Протокол ваги",
    scaleReport: "Звіт про вагу",
    scaleTare: "Тара вага",
    scaleTwo: "Вага 2",
    scalesNumber: "Номер ваги",
    scalesType: "Тип ваги",
    scalesVersion: "Версія ваги",
    schemeOfCargo: "Схема вантажу",
    script: "Сценарій",
    searchField: "Поле для пошуку",
    secondWeighting: "Друге зважування",
    selectProtocol: "Вибір протоколу",
    selectedOrders: "Вибрані замовлення",
    sendCommand: "Надіслати команду",
    sendDriversSms: "Надіслати SMS водіям",
    sender: "Відправник",
    sendingDate: "Дата відправлення",
    sent: "Відправлено",
    separator: "Роздільник",
    series: "Серія",
    server: "Сервер",
    serverModule: "Модуль на сервері",
    service: "Послуга",
    serviceAgreementsServices: "Основні послуги",
    serviceChargesServices: "Основні послуги",
    serviceOrders: "Сервісні замовлення",
    setSchedule: "Встановити розклад",
    settlementDocument: "Послуги нараховані",
    sex: "Стать",
    shemeOfCargoNum: "Сх.",
    shift: "Зміна",
    shiftOrders: "Замовлення змін",
    ship: "Корабель",
    shipIn: "Ввійшов",
    shipLoading: "Завантаження корабля",
    shipOut: "Вийшов",
    shipRegistration: "Реєстрація корабля",
    shipRegistrationPrint: "Звіт про перевантаження",
    shipStart: "Розпочато",
    shipStop: "Завершено",
    shipmentAddress: "Адреса відправлення",
    shipmentDate: "Дата відправлення",
    shipmentType: "Тип відправлення",
    shipped: "Відправлено",
    shortName: "Скорочене ім'я",
    showBusyTime: "Показати зайнятий час",
    showTaskDetails: "Показати деталі завдання",
    showXTotal: "Горизонтальні підсумки",
    showYTotal: "Вертикальні підсумки",
    signature: "Підпис",
    singularName: "Однина назва",
    singularTitle: "Заголовок однини",
    size: "Розмір",
    skonto: "Сконто",
    sms: "SMS",
    smsId: "ID SMS",
    sort: "№",
    sortBy: "Сортувати за",
    sortDirection: "Напрямок сортування",
    sortId: "Порядок",
    sortNumber: "№",
    sortNumberFull: "Повний номер",
    stage: "Етап",
    stageDescription: "Опис етапу",
    stageType: "Тип етапу",
    startDate: "Дата початку",
    startOfWeighting: "Початок зважування",
    startTime: "Час початку",
    state: "Стан",
    station: "Станція",
    status: "Статус",
    statusStr: "Статус (Str)",
    statusgroup: "Група статусів",
    step: "Крок",
    stepManuallSelect: "Ручний вибір наступного етапу",
    stockLevelAsNeeded: "Рівень складу за потребою",
    stockLevelMinA: "Рівень складу після minA",
    stop: "Стоп",
    storagePeriod: "Перiод зберiгання",
    store: "Сховище",
    storeCode: "Код складу",
    storeFilesToHardDrive: "Зберегти файли на жорсткому диску",
    street: "Вулиця",
    subExecutor: "Підрядник",
    subject: "Предмет",
    subordination: "Підпорядкування",
    sum: "Сума",
    sumBrutto: "Сума брутто",
    sumBruttoTotalItems: "Сума брутто (всього)",
    sumGrossNational: "Сума брутто (країна)",
    sumNetNational: "Сума нетто (країна)",
    sumNetto: "Сума нетто",
    sumNettoTotalItems: "Сума нетто (всього)",
    sumPayment: "Сума платежу",
    sumVAT: "Сума ПДВ",
    sumVATNational: "Сума ПДВ (країна)",
    sumVat: "Сума ПДВ",
    sumWithVAT: 'Сума з ПДВ',
    sumWithoutVAT: 'Сума без ПДВ',
    summary: 'Зведення',
    supplier: 'Постачальник',
    supplierArticle: 'Стаття постачальника',
    supplierReclamations: 'Рекламації до постачальника',
    symbol: 'Символ',
    system: 'Системний',
    systemObject: 'Обʼєкт системи',
    systemRowNumber: 'Номер рядка (системний)',
    table: 'Таблиця для курсів обміну валют',
    tableName: 'Назва таблиці',
    tableNumber: 'Номер таблиці НБУ',
    tag: 'Тег',
    tags: 'Теги',
    taras: 'Тара',
    tare: 'Тара',
    tareAuthor: 'Автор тари',
    tareTime: 'Дата ваги тари',
    task: 'Завдання',
    tasks: 'Завдання',
    taxRate: 'Ставка податку',
    taxation: 'Оподаткування',
    telephone: 'Телефон',
    template: 'Шаблон',
    termsOfPayment: 'Умови оплати',
    termsOfUse: 'Умови користування',
    tests: 'Тести',
    text: 'Текст',
    ticketNumber: 'Номер квитка',
    time: 'Час',
    title: 'Назва',
    to: 'До',
    toggleGroup: 'Перемикає групу',
    tonnage: 'Тоннаж',
    tooltip: 'Підказка',
    topic: 'Тема',
    totalAll: 'Загалом',
    totalEn: 'Total',
    totalHours: 'Загалом год.',
    totalTime: 'Час загалом',
    trackModel: 'Модель транспорту',
    trackTime: 'Час загалом за даний підхід',
    trafficLight: 'Світлофор',
    trailer: 'Напівпричіп',
    trainNumber: 'Номер поїзда',
    transportQuantity: 'Кількість трансп.',
    transportWay: 'Тип транспорту',
    type: 'Тип',
    typeOfCounterparty: 'Тип контрагента',
    typeOfDocument: 'Тип документа',
    typeOfLocation: 'Тип місця',
    typeOfMovement: 'Тип руху',
    typeOfOperation: 'Тип операції',
    typeOfPayment: 'Тип оплати',
    typeParse: 'Тип парсування',
    typeTrafficLightSignal: 'Тип сигналу світлофора',
    unbalancedScale: 'Нестабільні ваги',
    unique: 'Унікальний',
    unitOfMeasure: 'О. вим.',
    updatePeriod: 'Період оновлення',
    updatedAt: 'Дата оновлення',
    url: 'URL',
    use: 'Використовувати',
    use2FA: 'Двофакторна аутентифікація',
    useActualWarehouse: 'Використовувати фактичний склад',
    useCustomerAccess: 'Використовувати обмеження доступу за клієнтами',
    useDate: 'Урахувати дати',
    useDepartmentRestriction: 'Використовувати обмеження за відділами',
    useForExternalDrivers: 'Штраф для зовнішніх водіїв',
    useForInternalDrivers: 'Штраф для внутрішніх водіїв',
    useProductCondition: 'Використовувати стан продукту',
    useRestriction: 'Використовувати загальні обмеження',
    useStability: 'Використовувати стабільність',
    useTrafficLight: 'Використовувати світлофор',
    useTranslate: 'Використовувати переклад',
    user: 'Користувач',
    userAccess: 'Доступ користувача',
    usingVideoRecorder: 'Використання відеореєстратора',
    uuid: 'UUID',
    value: 'Значення',
    valueType: 'Тип значення',
    variable: 'Змінна',
    variant: 'Варіант',
    vatAmount: 'Сума ПДВ',
    vatRate: 'Ставка ПДВ',
    vehicle: 'Номер автомобіля',
    vehicleType: 'Тип автомобіля',
    vehicleWagon: 'Номер вагона',
    vendor: 'Постачальник',
    version: 'Версія',
    versionUuid: 'UUID версії',
    versioningMethod: 'Метод версіонування',
    videoRecorder: 'Відеореєстратор',
    view: 'Перегляд',
    viewType: 'Тип перегляду',
    visible: 'Видимий',
    volume: 'Обʼєм',
    wagon: 'Вагон',
    wagonType: 'Тип вагона',
    waitingTime: 'Час очікування',
    warehouse: 'Склад',
    warehouseAvailability: 'Стан складу',
    warehouseFrom: 'Склад від',
    warehouseSection: 'Сектор складу',
    warehouseShipPrint: 'Поділ вантажу на склади/площі',
    warehouseSquare: 'Склад/Площа',
    warehouseTo: 'Склад до',
    wastes: 'Відходи',
    web: 'www',
    weighingStation: 'Вагове місце',
    weight: 'Вага',
    weightAndVolume: 'Вага і обʼєм',
    weightingType: 'Тип зважування',
    whoPays: 'Хто платить',
    width: 'Ширина',
    work: 'Виконані роботи',
    workDateFrom: 'Початок роботи',
    workDateTo: 'Кінець роботи',
    workQuantity: 'Кількість роботи',
    workShifts: 'Зміна',
    worker: 'Працівник',
    workersQuantity: 'Кількість працівників',
    workingPlace: 'Місце роботи',
    worksheet: 'Лист роботи',
    yearFourDigits: 'Рік (4 знаки)',
    yearNumber: 'Рік',
    yearTwoDigits: 'Рік (2 знаки)',
    signingDate: 'Дата підписання пропозиції'
  },
  tagsView: {
    close: 'Закрити',
    closeAll: 'Закрити всі',
    closeOthers: 'Закрити інші',
    refresh: 'Оновити'
  },
  task: {
    description: 'Опис завдання',
    executionReceive: 'Прийняти до виконання',
    executionResult: 'Результат виконання завдання',
    forExecutor: 'Для виконавця',
    forExecutorRole: 'Для ролі виконавця',
    newTask: 'Нове завдання',
    redirection: 'Перенаправлення завдання',
    showExecuted: 'Показати виконані',
    showMyne: 'Показати мої',
    showTasks: 'Показати завдання'
  },
  theme: {
    leftSidebar: 'Лівий бічний панель'
  },
  valueTypes: {
    boolean: 'Логічний',
    date: 'Дата',
    number: 'Число',
    ref: 'Посилання',
    string: 'Текст'
  },
  weekDays: {
    Fr: 'Пт',
    Mo: 'Пн',
    Sa: 'Сб',
    Su: 'Нд',
    Th: 'Чт',
    Tu: 'Вт',
    We: 'Ср'
  }
}

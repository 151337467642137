import axios from 'axios'
import _ from 'lodash'

const initState = {
  currentView: {
    date: new Date(),
    isAllBoxes: false,
    shipRegistration: null,
    deliveryOrder: null,
    product: null,
    deliveryOrdersArray: [],
    mapSrc: null,
    data: [],
    wmsTransactions: [],
    currentTab: 0,
    isShowOperation: true,

    mode: '',
    type: 'rect',
    color: '#5FA9C8',
    defaultColor: '#5FA9C8',
    shapeTypes: [
      { value: 'rect', text: 'Square' },
      { value: 'circle', text: 'Circle' },
      { value: 'custom', text: 'Custom' },
    ],
    selectedMapFile: null,
    parentViewId: null,
    selectedCell: null,
    selectedBox: null,
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  addObjectView(state, view) {
    const existView = state.objectViews.find((el) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setCurrentViewProperty(state, payload) {
    Object.keys(payload).forEach((key) => {
      state.currentView[key] = payload[key]
    })
  },

  setObjectViewProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index][payload.property] = payload.value
    }
  },

  setObjectProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index].object[payload.property] = payload.value
    }
  },

  delObjectView(state, viewId) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  setCurrentView(state, currentView) {
    state.currentView = currentView
  },
  resetState(state) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
}

export const getters = {
  currentView(state) {
    return state.currentView
  },

  objectView: (state) => (viewId) => {
    return state.objectViews.find((el) => el.viewId === viewId)
  },

  isAllBoxes(state) {
    return state.currentView.isAllBoxes
  },

  shipRegistration(state) {
    return state.currentView.shipRegistration
  },

  deliveryOrder(state) {
    return state.currentView.deliveryOrder
  },

  deliveryOrdersArray(state) {
    return state.currentView.deliveryOrdersArray
  },
  product(state) {
    return state.currentView.product
  },
  mapSrc(state) {
    return state.currentView.mapSrc
  },
  // mapImage(state) {
  //   return state.currentView.mapImage
  // },
  data(state) {
    return state.currentView.data
  },
  currentTab(state) {
    return state.currentView.currentTab
  },
  isShowOperation(state) {
    return state.currentView.isShowOperation
  },
  mode(state) {
    return state.currentView.mode
  },
  type(state) {
    return state.currentView.type
  },
  color(state) {
    return state.currentView.color
  },
  defaultColor(state) {
    return state.currentView.defaultColor
  },
  shapeTypes(state) {
    return state.currentView.shapeTypes
  },
  selectedMapFile(state) {
    return state.currentView.selectedMapFile
  },
  date(state) {
    return state.currentView.date
  },
  wmsTransactions(state) {
    return state.currentView.wmsTransactions
  },
  parentViewId(state) {
    return state.currentView.parentViewId
  },
  selectedCell(state) {
    return state.currentView.selectedCell
  },
  selectedBox(state) {
    return state.currentView.selectedBox
  },
}

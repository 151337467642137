export default {
  route: {
    main: 'Home',
    dashboard: 'Dashboard',
    sales: 'Sales',
    tasks: 'Tasks',
    salesOrders: 'Customers orders',
    products: 'Products',
    counterparties: 'Counterparties',
    reclamations: 'Reclamations',
    reclCatalogs: 'Catalogs',
    reclSubjects: 'Orders subjects',
    statusGroups: 'Statuses groups',
    reclStates: 'Orders statuses',
    reclTypes: 'Orders types',
    reclExecutions: 'Executions',
    reclDecisions: 'Decisions',
    settings: 'Settings',
    firms: 'Firms',
    salesPoints: 'Sales points',
    users: 'Users',
    usersList: 'Users list',
    accessGroups: 'Access groups',
    otherSettings: 'Other settings',
    administration: 'Administration',
    emailTemplates: 'Email templates',
    numerators: 'Document numerators',
    executors: 'Executors',
    executorRoles: 'Executor roles',
    exchangeObjects: 'Objects to exchange',
    taskTemplates: 'Tesk templates',
    customerRequests: 'Customer requests',
    crStatuses: 'Customer request statuses',
    mailbox: 'Emails',
    salesOrder: 'Klantorder',
    customerRequest: 'Offerte aanvraag',
    newEmail: 'Nieuwe e-mail',
    emailDetails: 'E-mail',
    task: 'Interne taak',
    product: 'Productgegevens',
    customer: 'Klantgegevens',
    accessGroup: 'Toegangsgroep',
    user: 'Gebruiker',
    executor: 'Taak uitvoerder',
    executorRole: 'Uitvoerder',
    taskTemplate: 'Taaksjablon',
    reclamation: 'Klacht',
    reclamationStatistic: 'Klachtenstatistieken',
    emailAccounts: 'Een e-mailaccounts',
    emailAccount: 'Een e-mailaccount',
    dashboards: 'Dashborden',
    calendar: 'Kalender',
    counterparty: 'Aannemer',
    costomers: 'Klanten',
    costomer: 'Klant',
    carriers: 'Carriers',
    carrier: 'Carrier',
    vendorsAndCustomers: 'Leveranciers en klanten',
    reclamationviewform: 'Klacht (voorbeeld)',
    userProfile: 'Gebruikersprofiel',
    timeTracking: 'Tijdklok',
    registrationprotocol: 'Registratieprotocol',
    reclPerpetrators: 'Verantwoordelijk voor klachten',
    reclReasons: 'Redenen van klachten',
    costArticles: 'Soorten kosten',
    costArticle: 'Soort kosten',
    deliveryTypes: 'Soorten verzendingen',
    financialPeriods: 'Financiële voorwaarden',
    shipment: 'Verzending',
    settlements: 'Afrekeningen',
    deliveryType: 'Soort verzending',
    firm: 'Bedrijf',
    ships: 'Schepen',
    shippingOfComplaintItems: 'Verzending van klachtenartikelen',
    scales: 'Weegschaal',
    event: 'Evenement',
    events: 'Evenementen',
    crStatuse: 'Aanvraagstatus',
    customerRequestsStatuses: 'Aanvraagstatussen',
    customerRequestAnalytics: 'Analyse van vragen',
    outgoingEmailDetails: 'Verstuurde e-mail',
    crTags: 'Aanvraag tags',
    crTag: 'Aanvraag tag',
    customerRequestsTags: 'Aanvraag tags',
    usersAndAccess: 'Gebruikers en gebruikersrechten',
    bpAndTasks: 'Bedrijfsprocessen en taken',
    bpDefinitions: 'Definitie van bedrijfsprocessen',
    bpDefinition: 'Definitie van bedrijfsproces',
    bpExemplars: 'Bedrijfsproces',
    employees: 'Werknemers',
    employee: 'Werknemer',
    report: 'Melding',
    reports: 'Meldingen',
    salesOrderStatuses: 'Status van klantenorders',
    salesOrderStatus: 'Status van klantorders',
    currencies: 'Valuta',
    currency: 'Valuta',
    countries: 'Landen',
    country: 'Land',
    termsOfPayment: 'Betalingsvoorwaarden',
    pricelists: 'Prijslijsten',
    pricelist: 'Prijslijst',
    discounts: 'Productiekortingen',
    discount: 'Productiekorting',
    documentPrefixes: 'Document voorvoegsels',
    interactionStatuses: 'Interactie statussen',
    interactionTags: 'Interactie tags',
    interactions: 'Interacties',
    interaction: 'Interactie',
    action: 'Actie',
    userSettings: 'Gebruikersinstellingen',
    navigation: 'Navigatie-instellingen',
    logistic: 'Logistiek',
    production: 'Productie',
    scalesCars: 'Voertuig weegschalen',
    usersSettings: 'Gebruikersinstellingen',
    formBuilder: 'Bouwer van formulieren',
    forms: 'Weergaven',
    deliveryOrders: 'Bestellingen',
    dispositions: 'Verzendingen',
    deliveryNotes: 'CMR vrachtbrief',
    userRoles: 'Gebruikersrollen',
    userRole: 'Gebruikerrol',
    dispositionStatuses: 'Dispositie statussen',
    dispositionStatuse: 'Dispositie status',
    contactPersons: 'Contactpersonen',
    contactPerson: 'Contactperson',
    assortments: 'Assortimenten',
    assortment: 'Assortiment',
    controlCompanies: 'Controlerende bedrijven',
    controlCompany: 'Controlerende bedrijv',
    mines: 'Mijnen',
    mine: 'Mijn',
    schemesOfCargo: "Afleverschema's",
    schemeOfCargo: 'Afleverschema',
    ship: 'Scheep',
    vehicles: 'Vervoermiddelen',
    vehicle: 'Vervoermidel',
    drivers: 'Chauffeurs',
    driver: 'Chauffeur',
    scale: 'Gewicht',
    workingPlaces: 'Werklocaties',
    scaleProtocols: 'Weegprotocollen',
    scaleProtocol: 'Weegprotocol',
    positions: 'Posities',
    position: 'Positie',
    projects: 'Projecten',
    project: 'Project',
    orderSubjects: 'Bestelde artikelen',
    orderSubject: 'Bestelde artikel',
    warehouse: 'Magazijn',
    warehouses: 'Magazijnen',
    unitsOfMeasure: 'Meeteenheden',
    unitOfMeasure: 'Meeteenhed',
    weighingStations: 'Weegstations',
    weighingStation: 'Weegstation',
    appModules: 'Systeemmodules',
    appModule: 'Systeemmodul',
    objectVersioningSettings: 'Object versiebeheer instellingen',
    objectVersioningSetting: 'Object versiebeheer instelling',
    reclamationClientDemands: 'Klantverwachtingen',
    reclamationClientDemand: 'Klantverwachting',
    reclamationDecisions: 'Beslissingen',
    reclamationDecision: 'Beslissing',
    reclamationExecutionTypes: 'Soorten orderuitvoeringen',
    reclamationExecutionType: 'Soort orderuitvoering',
    reclamationPerpetrators: 'Verantwoordelijk voor klachten',
    reclamationPerpetrator: 'Verantwoordelijk voor klachten',
    reclamationReasons: 'Redenen van klachten',
    reclamationReason: 'Redenen van klacht',
    reclamationSubjects: 'Onderwerpen van bestellingen',
    reclamationSubject: 'Onderwerp van bestelling',
    reclamationTypes: 'Soorten van bestellingen',
    reclamationType: 'Soort van bestelling',
    reclamationStatuses: 'Bestelstatussen',
    reclamationStatus: 'Bestelstatus',
    reclamationStatusGroups: 'Statusgroepen',
    reclamationStatusGroup: 'Statusgroep',
    paymentTypes: 'Betaalmethoden',
    paymentType: 'Betaalmethod',
    cashFlowItems: 'Kasstromen',
    cashFlowItem: 'Kasstroomelement',
    bankReceipt: 'Binnenkomende overdracht',
    bankExpense: 'Uitgaande overdracht',
    cashReceipt: 'De kassa accepteert',
    cashExpense: 'De kassa betaalt',
    emailLabels: 'Tag e-mail',
    emailLabel: 'Taggen e-mail',
    emailTemplateEdition: 'E-mailsjabloon bewerken',
    departments: 'Afdelingen',
    department: 'Afdeling',
    accessRestrictions: 'Toegangsbeperkingen',
    accessRestriction: 'Toegangsbeperking',
    eventTypes: 'Soorten evenementen',
    eventType: 'Soort evenement',
    standings: 'Personeelsfuncties',
    standing: 'Personeel functie',
    eventStatuses: 'Status van evenementen',
    eventStatus: 'Status van evenement',
    userSettingItems: 'Gebruikersinstellingen',
    userSettingItem: 'Gebruiker instellingen',
    userSettingGroup: 'Groep gebruikersinstellingen',
    appSettingGroup: 'Groep systeeminstellingen',
    appSettingItem: 'Definitie van de systeeminstellingen',
    appSettingItems: 'Definitie van de systeeminsteling',
    appSettings: 'Systeeminstellingen',
    appSetting: 'Systeeminsteling',
    documentNumerators: 'Documentnummers',
    viewSettings: 'weergave-instellingen',
    viewSetting: 'weergave-insteling',
    appObjects: 'Systeemobjecten',
    appObject: 'Systeemobject',
    availableNavigation: 'Navigatie  (Menu)',
    remainingViews: 'Andere weergaven',
    boxes: 'Opslagcellen',
    box: 'Opslagcel',
    exchangeObjectEdition: 'Het uitwisselingsobject bewerken',
    inventoryDocuments: 'Magazijndocumenten',
    inventoryStatuses: 'Statussen magazijndocumenten',
    inventoryCategories: 'Categorieën magazijndocumenten',
    vatRates: 'BTW-tarieven',
    addUpdateProductionOrders: 'Productieorders toevoegen/bewerken',
    addEditHistory: 'Geschiedenis toevoegen/bewerken',
    additionalParameters: 'Extra parameters',
    additionalParametersSets: 'Sets met aanvullende parameters',
    productVariants: 'Productvarianten',
    productVariant: 'Productvariant',
    deliveryRoutes: 'Verzendroutes',
    deliveryRoute: 'Verzendroute',
    deliveryScheduleStatuses: 'Status van verzendingsplannen',
    deliveryScheduleStatus: 'Status van verzendingsplan',
    localities: 'Plaats',
    locality: 'Stad',
    deliverySchedules: 'Verzendplannen',
    deliverySchedule: 'Verzendplan',
    punishmentsTypes: 'Soorten parkeertarieven',
    punishmentType: 'Soort parkeertarieven',
    punishments: 'Kosten voor het gebruik van de parkeerplaats',
    punishment: 'Parkeerkosten',
    reportSettings: 'Rapportinstellingen',
    reportSetting: 'Rapport instellen',
    roleGroups: 'Rechtengroepen',
    accessLogs: 'Logboeken',
    deliveryPayments: 'Betalingen voor het gebruik van de parkeerplaats',
    deliveryPayment: 'Betaling voor het gebruik van de parkeerplaats',
    orderTypes: 'Soorten bestellingen',
    orderType: 'Soort bestelling',
    outgoingSms: 'Uitgaande SMS',
    counterpartyAddresses: 'Klantensdres',
    counterpartyAddress: 'Klantadres',
    userNavigation: 'Gebruikersnavigatie instellen',
    ordersForTransports: 'Transportopdrachten',
    orderForTransport: 'Transportopdracht',
    exchangeRates: 'Valutakoersen',
    exchangeRate: 'Valutakoers',
    carsQueueManagment: 'Beheer van wachtrijen',
    exchangeNodes: 'Knooppunten uitwisselen',
  },
  navbar: {
    logOut: 'Log Out',
    clearAll: 'Clear all',
    newNotes: 'New notifications',
    checkAll: 'Check all',
    hello: 'Hello!',
    profile: 'Mijn account',
    notifications: 'Meldingen',
  },
  login: {
    title: 'Login Form',
    username: 'Username',
    usernamePh: 'Enter your e-mail',
    invalidLogin: 'User Email or Password invalid',
    unexpectedError: 'An unexpected error has occurred, try again',
    password: 'Password',
    passwordPh: 'Enter your password',
    fogotPassword: 'Forgot your password?',
    logIn: 'Log In',
    noHaveAccount: "Don't have an account?",
    register: 'Register now',
    description: 'Voer uw login en wachtwoord in om toegang te krijgen tot het administratiepaneel',
    forgotPassword: 'Wachtoord vergeten?',
    rememberMe: 'Onthoud mij',
    securityCheck: 'Veiligheidscontrole',
    sendNewCode: 'Stuur nieuwe code',
    authCode: 'Verificatie code',
    authCodePh: 'Voer verificatiecode in',
    invalidAuthCode: 'Verkeerd ingevoerde verificatiecode',
    outOfDateCode: 'De verificatiecode is verlopen. Vraag een nieuwe aan.',
  },
  register: {
    title: 'User registration',
    titleDescription: 'You do not have an account? Sign up, it will take less than a minute',
    firstLastName: 'Name and surname',
    enterDetailsPh: 'Enter your details',
    email: 'Email',
    emailPh: 'Enter your email',
    terms: 'Terms of use of the portal',
    register: 'Register',
    haveAccount: 'Already have an account?',
    confirm: 'Your account is registered successfully',
    sendTo: 'A email has been send to',
    emailCheck: 'Please check for an email from company and click on the included link to reset your password.',
    backHome: 'Back to Home',
    name: 'Naam',
    surename: 'Achternaam',
    login: 'Login',
    loginPh: 'Voer uw login in',
    notFilledAllFilds: 'Niet alle velden zijn ingevuld',
    emailConfirmed: 'De e-mail is bevestigd',
    emailNotConfirmed: 'E-mail kon niet worden bevestigd',
    resendYourEmail: 'Stuur uw e-mail opnieuw',
    enterValidEmail: 'Voer een geldig e-mailadres in',
    emailSent: 'E-mail succesvol verzonden. Controleer uw mailbox.',
    emailSentError: 'Er is een fout opgetreden tijdens het verzenden van een e-mail. Probeer het later nog eens.',
    passwordLinkSent: 'Er is een link om je wachtwoord te wijzigen naar het opgegeven e-mailadres gestuurd.',
    enterNewPassword: 'Voer een nieuw wachtwoord in',
    enterPasswordLimit: 'Voer minstens 7 tekens in.',
    reenterPassword: 'Voer wachtwoord opnieuw in',
    reenterPasswordError: 'Het moet in overeenstemming zijn met het ingevoerde wachtwoord.',
    reenterEmail: 'Voer je e-mailadres opnieuw in voor de nieuwe link.',
    resendEmail: 'E-mail opnieuw verzenden',
    canResetPasswordMsg: 'U kunt nu uw wachtwoord wijzigen.',
    resetPasswordLate: 'Het lijkt erop dat je een beetje te laat bent. Probeer opnieuw te e-mailen.',
    resetPasswordError: 'Er is een fout opgetreden tijdens het verwerken van uw verzoek. Probeer de reset-informatie opnieuw te verzenden.',
  },
  customerRequest: {
    title: 'Offerteaanvraag',
    new: 'Nieuw aanbod aanvragen',
    newVersion: 'Nieuwe versie van offerteaanvraag',
    edit: 'Wijzig offerteaanvraag nr. ',
    presentation: 'Offerteaanvran van {0} tot {1}',
    createNew: 'Vraag creëren',
    editStatus: 'Statuswijziging',
    addComment: 'Nieuwe commentaar',
    editResult: 'Resultaat bewerken',
    msg: {
      selectItemFirst: 'Kies ervoor om eerst een offerte aan te vragen!',
      return: 'De offerteaanvraag wordt hersteld!',
      delete: 'De offerteaanvraag wordt gemarkeerd voor verwijdering!',
      orderAvailable: 'Er bestaat al een offerteaanvraag met een dergelijke referentie: {0}',
      emptyCustomer: 'Onvervulde klant!',
      noWrite: 'De offerteaanvraag moet worden opgeslagen!',
      noFoundResults: 'No results found for',
      startTypingForSearch: 'Start typing to search.',
      emptyReference: 'Een onvervulde referentie!',
    },
    editDecision: 'Wijziging van de beslissing',
    newStatus: 'Selecteer nieuwe status',
    selectDecision: 'Kies uw beslissing',
    newDecision: 'Nieuwe beslissing',
    goToAnalytics: 'Ga naar analyse',
    startBp: 'Start het afstemmingsproces',
  },
  order: {
    title: 'Customers orders',
    objectTitle: 'Order',
    new: 'New order',
    presentation: 'Order nr {0} at {1}',
    createTask: 'Create task',
    msg: {
      selectOrderFirst: 'Select order first!',
      return: 'The order will be rolled over',
      delete: 'The order will be marked for deletion!',
      orderAvailable: 'An order with such reference already exists: {0}',
      confirmRemoveProduct: 'You really want to remove this product?',
      emptyCustomer: 'Customer not filled!',
      writeOrder: 'The order must be saved!',
      emptyStatus: 'Onvervulde status!',
      emptyCurrency: 'Ongevulde valuta!',
      emptyBlock: 'Geen vulling!',
      emptyReference: 'Ongevulde referentie',
      emptyDeliveryMethod: 'Levermethode niet ingevuld!',
      emptyFields: 'Niet alle verplichte velden zijn ingevuld!',
      writed: 'De bestelling werd opgenomen',
      confirmDouble: 'Er bestaat al een bestelling met deze referentie. Bevestigt u dat u wilt doorgaan met bestelling?',
    },
    status: {
      OFERTA: 'Offer',
      NEW: 'New order',
      CONFIRMED: 'Confirmed',
      READY_FOR_PRODUCTION: 'Ready for production',
      CANCELED: 'Canceled',
      CLOSED: 'Closed',
    },
    deliveryMethods: {
      STANDARD: 'Standard',
      UNTIL_CERTAIN_DATE: 'Until certain date',
      ON_CERTAIN_DATE: 'On certain date',
      VIP: 'VIP',
    },
    packageMaterial: {
      Karton: 'Carton',
      Folia: 'Foil',
    },
    enterReference: 'Enter a reference',
    enterProject: 'Enter project name',
    enterDeliveryMethod: 'Select the type of delivery',
    enterMaterial: 'Choose material',
    shipmentDays: 'Departure days: ',
    roadDays: 'Road days: ',
    deliveryDays: 'Delivery days: ',
    byOrderPeriod: 'According to the scope of the order',
    priceDetails: 'Price details',
    orderName: 'Bestelling',
  },
  commands: {
    accept: 'Accept',
    search: 'Search',
    add: 'Add',
    clear: 'Clear',
    cancel: 'Cancel',
    ok: 'OK',
    writeAndClose: 'Write and close',
    confirmAndClose: 'Confirm and close',
    write: 'Write',
    close: 'Close',
    autofill: 'Automatisch aanvullen',
    addProduct: 'Add product',
    addExtraProduct: 'Add extra product',
    addFile: 'Add file',
    addToCalendar: 'Add to calendar',
    eventsList: 'List of events',
    select: 'Select',
    configProduct: 'Configure product',
    confirm: 'Confirm',
    confirmSelected: 'Confirm selection',
    confirmPrices: 'Confirm prices',
    calculate: 'Calculate',
    insertNumber: 'Voer het nummer in',
    runTimeTracking: 'Tijd bijhouden beginnen',
    markAll: 'Alles markeren',
    unmarkAll: 'Deselecteer alles',
    showDetail: 'Details tonen',
    addNew: 'Nieuwe toevoegen',
    delete: 'Verwijder',
    enterDescription: 'Beschrijving invoeren',
    activate: 'Activeren',
    allowManual: 'Bewerken',
    enterNumber: 'Voer het nummer in',
    enterComment: 'Geef een commentaar',
    enterSth: 'Vul gegevens in',
    completeAllConsts: 'Vul alle kosten in',
    addRefreshResult: 'Resultaat toevoegen / bijwerken',
    goToEditing: 'Naar bewerken',
    goToRegister: 'Ga naar registratie',
    goToParentDocument: 'Ga naar het betreffende document',
    addAttachment: 'Bijlage toevoegen',
    writeChanges: 'Wijzigingen opslaan',
    addTitle: 'Titel toevoegen',
    addGroup: 'Groep toevoegen',
    addPhase: 'Fase toevoegen',
    addSetting: 'Instelling toevoegen',
    selectContactFromList: 'Selecteer een contact uit de lijst',
    addFromList: 'Toevoegen vanuit de lijst',
    mannualEntry: 'Handmatig invoeren',
    execute: 'Volg',
    redirectTaskExecution: 'Uitvoeren en doorsturen naar de volgende stap',
    createOrder: 'Maak een bestelling',
    runAndClose: 'Uitvoeren en sluiten',
    update: 'Vernieuwen',
    download: 'Downloaden',
    edit: 'Bewerken',
    editNumber: 'Nummer bewerken',
    addComment: 'Commentaar toevoegen',
    writeEmail: 'Schrijf een email',
    addEvent: 'Evenement toevoegen',
    showCanceled: 'Toon inactief',
    send: 'Stuur',
    sendResult: 'Stuur het resultaat',
    refresh: 'Vernieuwen',
    resend: 'Opnieuw sturen',
    reset: 'Reset',
    resetPassword: 'Wachtwoord resetten',
    addNotificationContent: 'Inhoud voor meldingen toevoegen',
    redirect: 'Omleiding',
    createFrom: 'Creëer uit',
    print: 'Print',
    more: 'Lees meer',
    setStatus: 'Status instellen',
    sendListToAuthor: "Stuur de lijst met stuurprogramma's naar de auteur",
    generate: 'Genereren',
    change: 'Verander',
    getComputerID: 'Computer-ID downloaden',
    getInfoEquipment: 'Apparaatgegevens downloaden',
    attachTo: 'Meld u aan bij',
    sendNow: 'Nu versturen',
    selectAll: 'Alles selecteren',
    copy: 'Kopie',
    deleteRestore: 'Verwijderen/herstellen',
    enterCode: 'Voer de code in',
    showHistory: 'Geschiedenis van wijzigingen tonen',
    open: 'Open',
    compare: 'Vergelijk',
    advancedSearch: 'Geavanceerd zoeken',
    addViewSetting: 'Weergave-instelling toevoegen',
    moveUp: 'Omhoog',
    moveDown: 'Omlaag',
    changeView: 'Weergave wijzigen',
    forward: 'Omleiding',
    setDefault: 'Standaard instellen',
    inputTranslations: 'Voer vertalingen in',
    changeStatus: 'Status wijzigen',
    changeNumber: 'Verander het nummer',
    changeDecision: 'Verander de beslissing',
    openReport: 'Rapport openen',
    salesReport: 'Verkooprapport',
    addFields: 'Velden toevoegen',
    addField: 'Veld toevoegen',
    addFilters: 'Filters toevoegen',
    end: 'Volledig',
    setPeriod: 'Periode instellen',
    addCustomer: 'Klant toevoegen',
    planRoute: 'Route plannen',
    calculateRoute: 'Route berekenen',
    findOptimalRoute: 'De optimale route zoeken',
    copySettingsFromUser: 'Instellingen van een andere gebruiker kopiëren',
    addAction: 'Aktie toevoegen',
    editAction: 'Edytie Aktie',
    getFileForTranslation: 'Download het bestand voor vertaling',
    loadTranslationFromFile: 'Een vertaling uit een bestand laden',
    addItem: 'Voeg element toe',
  },
  common: {
    search: 'Search...',
    filter: 'Filter',
    modalTitle: 'Attention!',
    emptyField: '<empty>',
    mainData: 'Main',
    products: 'Products',
    files: 'Files',
    notEmptyField: 'This field cannot be empty',
    selectDate: 'Choose date',
    enterComment: 'Enter comment',
    enterQuantity: 'Enter quantity',
    total: 'Total',
    selectFileTitle: 'Choose file!',
    selectProductTitle: 'Choose product!',
    noSelectedFiles: 'No files selected',
    searchCustomer: 'Search a customer',
    double: 'Double',
    incorrectData: 'Incorrect data entered in the field!',
    from: 'from',
    to: 'to',
    noFound: '<not found>',
    selected: 'Selected',
    confirmSelected: 'Confirm selected',
    addinDimensions: 'Addin dimensions',
    numberOfPieces: 'Number of pieces',
    lengthWidth: 'Length/Width',
    height: 'Height',
    recalculation: 'Recalculation',
    active: 'Active',
    deactive: 'Deactive',
    productData: 'Produkt details',
    picturePath: 'Path to the picture',
    enterPictureName: 'Enter a picture name...',
    customerData: 'Customer data',
    erp: 'ERP',
    executorData: 'Executor data',
    executorRoleData: 'Executor role data',
    emptyExecutorList: 'Artist list is empty ...',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    executionToday: 'Execution today',
    taskTemplatesData: 'Task templates data',
    fillByTemplate: '-- Fill by template --',
    enterName: 'Enter name',
    selectImportance: 'Select importance',
    selectExecutorRole: 'Select executor role',
    selectExecutor: 'Select executor',
    history: 'History',
    yes: 'Yes',
    no: 'No',
    filters: 'Filters',
    missingParameter: 'Geen',
    eventType: 'Soort evenement',
    eventEnd: 'Einde evenement',
    eventStart: 'Begin van het evenement',
    message: 'Berichten',
    notSendMessage: "Ik wil geen berichten krijgen van 'Synergy'",
    sendMessageFromSpecific: 'Ik wil berichten ontvangen van geselecteerde e-mails',
    start: 'start',
    setZero: 'Op nul stellen',
    setZeroReceipt: 'De kassabon resetten',
    technicalData: 'Technische gegevens',
    writeComment: 'Geef commentaar',
    selectLanguage: 'Taal kiezen',
    currency: 'Valuta',
    financialPeriod: 'Financiële voorwaarden',
    addUpdateCostsOfComplaintOrder: 'De kosten van een schade toevoegen/bewerken',
    fileDescription: 'Bestandsbeschrijving',
    dispositionOfNumber: 'Dispositie nr. ',
    deliveryOrderOfNumber: 'Bestelling nr. ',
    issuedIn: ' vanaf ',
    complaintVerificationProtocol: 'Protocol schadeverificatie',
    registration: 'AANMELDING',
    undefined: 'Niet gespecificeerd',
    customerFiles: 'Klantbestanden',
    searchAuthor: 'Auteur zoeken',
    searchAssortment: 'Assortiment zoeken',
    searchAddress: 'Adres zoeken',
    searchDriver: 'Chafeur zoeken',
    searchDirector: 'Team leider zoeken',
    searchDispAuthor: 'Zoek naar de auteur van de dispositie',
    searchOrderAuthor: 'Zoeken naar de auteur van de bestelling',
    searchWeighingStation: 'Een weegstation zoeken',
    searchVehicle: 'Een voertuig zoeken',
    searchOriginWarehouse: 'Plaats van vestiging zoeken',
    searchWarehouse: 'Magazijn zoeken',
    chooseCarrier: 'Selecteer een vervoerder',
    searchCategory: 'Categorie zoeken',
    searchControlCompany: 'Inspectiebedrijf zoeken',
    searchOpType: 'Type bewerking zoeken',
    searchProducts: 'Producten zoeken',
    searchProductTo: 'Zoek product naar',
    searchProductVariant: 'Productvariant zoeken',
    searchWarehouseTo: 'Zoek in het magazijn naar',
    searchWarehouseFrom: 'Zoek magazine van',
    searchWarehouseSector: 'Magazijnsector zoeken',
    searchWarehouseNumber: 'Zoeken naar voorraadnummer',
    searchForwarder: 'Een vrachtvervoerder zoeken',
    searchScale: 'Gewicht zoeken',
    searchShip: 'Schip zoeken',
    searchRelation: 'Relatie zoeken',
    searchStation: 'Positie zoeken',
    searchProduct: 'Goederen zoeken',
    searchManager: 'Handelaar zoeken',
    searchBase: 'Zoeken naar de basis',
    searchDirective: 'Bestelling zoeken',
    searchOrder: 'Bestelling zoeken',
    searchCustomerOrder: 'Klantorder zoeken',
    searchExecution: 'Uitvoering zoeken',
    searchExecutor: 'Aannemer zoeken',
    searchNettoExecutor: 'Netaannemer zoeken',
    searchTaraExecutor: 'Een tarra-aannemer zoeken',
    searchGrossExecutor: 'Zoeken naar bruto aannemer',
    searchConstructor: 'Constructeur zoeken',
    searchTaskExecutor: 'Aannemer zoeken',
    searchOwner: 'Eigenaar zoeken',
    searchRail: 'Zoek wagen',
    searchCarrier: 'Een vervoerder zoeken',
    searchCurrency: 'Valuta zoeken',
    searchCountry: 'Land zoeken',
    searchLocality: 'Zoek een stad',
    scanCode: 'Scan de code',
    scanTicket: 'Scan uw ticket',
    scanBarCode: 'Scan de barcode',
    searchPaymentDueDate: 'Betalingsvoorwaarden zoeken',
    customerRequestNumberEdit: 'Offertenummer bewerken',
    customerRequestNumber: 'Offertenummer',
    searchContractor: 'Klant zoeken',
    searchType: 'Type zoeken',
    searchBasicDocument: 'Basisdocument zoeken',
    searchProject: 'Project zoeken',
    searchRealization: 'Zoeken naar orderuitvoering',
    justification: 'Rechtvaardiging',
    searchAgreement: 'Contract zoeken',
    rows: 'gedichten',
    searchSubject: 'Artikel zoeken',
    searchDecision: 'Een beslissing zoeken',
    searchResponsible: 'Zoek de verantwoordelijke',
    searchDisposition: 'Dispositie zoeken',
    searchReason: 'Zoeken naar de reden',
    searchTrailer: 'Trailer zoeken',
    searchExpectation: 'Wachten zoeken',
    searchStatus: 'Status zoeken',
    searchVendor: 'Leverancier zoeken',
    searchPaymentType: 'Betalingsmethode zoeken',
    searchCashFlowElement: 'Zoek cashflow element',
    searchPosition: 'Artikel zoeken',
    searchUnitOfMeassure: 'Eenheid zoeken',
    searchMine: 'Mijn zoeken',
    searchVendorCustomer: 'Ontvanger zoeken',
    searchCompletion: 'Uitvoering zoeken',
    searchTrain: 'Trein zoeken',
    numbers: 'Nummers',
    length: 'Lengte',
    sales: 'VERKOOP',
    orders: 'Bestellingen',
    width: 'Breedte',
    shipData: 'Gegevens schip',
    carrierData: 'Carrier gegevens',
    dateAdded: 'Datum toevoeging',
    regestrationDate: 'Registratiedatum',
    filePath: 'Bestandspad',
    decisionDate: 'Datum beslissing',
    describeProduct: 'Beschrijf het product...',
    administration: 'Administratie',
    complaintsInfo: 'Klachtgegevens',
    emptyUserList: 'De lijst met geb...',
    emptyHistoryList: 'Geen gegevens over objectwijzigingen gevonden...',
    allDays: 'Alle dagen',
    recepientsCondition: 'Voorwaarde voor de ontvanger',
    executionCondition: 'Verzendingsvoorwaarden',
    planedCompletitionDate: 'Geplande uitvoeringsdatum',
    selectForm: 'Kies document',
    selectPossition: 'Item kiezen',
    searchItem: 'Zoek',
    enterTextHere: 'Voer hier de tekst in...',
    prices: 'Prijzen',
    perPage: 'Beperk',
    msg: 'Aanmelding',
    containsWords: 'Bevat woorden',
    subsystem: 'Subsysteem',
    partition: 'Hoofdstuk',
    view: 'Bekijk',
    preview: 'Voorbeeld',
    orderData: 'Opdrachtgegevens',
    dispositionData: 'Dispositie gegevens',
    deliveryNoteData: 'Vrachtbrief gegevens',
    vendorsAndCustomers: 'Leveranciers en klanten',
    vendorsAndCustomersData: 'Gegevens van leveranciers en klanten',
    notSelected: 'Niet geselecteerd',
    msges: {
      selectItemFirst: 'Kies eerst uw offerteaanvraag!',
    },
    settings: 'Instellingen',
    general: 'Algemeen',
    externalCodes: 'Externe codes',
    forwarders: 'Expediteurs',
    vehicle: 'Auto/wagen',
    valueType: 'Type waarde',
    register: 'Registreer',
    actions: 'Activiteiten',
    showMore: 'Meer tonen',
    processingError: 'Er is een fout opgetreden tijdens de uitvoering van de bewerking',
    selectUser: 'Gebruiker kiezen',
    selectMethod: 'Selecteer methode',
    selectEmployee: 'Werknemer kiezen',
    signatureSettings: 'E-mailinstellingen gebruiker',
    defaultPrinterSettings: 'Printer standaardinstellingen',
    localSystem: 'Lokale installatie',
    textPosition: 'Positie in de tekst',
    views: 'Weergaven',
    navigation: 'Navigatie',
    fields: 'Velden',
    quickFilter: 'Snel filter',
    quickFilters: 'Snele filters',
    formatter: 'Formatteren',
    format: 'Format',
    availableFields: 'Vrije velden',
    noFieldsAvailable: 'Geen velden beschikbaar',
    noFiltersAvailable: 'Geen filters beschikbaar',
    noGroupsAvailable: 'Geen groepen beschikbaar',
    noSortingAvailable: 'Geen sortering beschikbaar',
    noCalcsAvailable: 'Geen berekeningen beschikbaar',
    noFilters: 'Geen filters',
    sortable: 'Sorteerbaar',
    filterable: 'Filterbaar',
    groupable: 'Groepeerbaar',
    calculated: 'Berekend',
    items: 'Posities',
    help: 'Hulp',
    wastes: 'Afval',
    taras: 'Tarra',
    reservations: 'Boekingen',
    loading: 'Bezig met laden',
    additionalProducts: 'Andere producten',
    styles: 'Style',
    fillTranslationsFromDictionary: 'Vul de vertalingen uit het woordenboek in',
    all: 'Alle',
    onlyEmpty: 'Enkel leeg',
    selectedFields: 'Geselecteerde velden',
    selectedFilters: 'Geselecteerde filters',
    details: 'Details',
    statisticByTraders: 'Statistieken van handelaren',
    analizeByCustomers: 'Analyse volgens klant',
    myCalendar: 'Mijn agenda',
    addCalendar: 'Kalender toevoegen',
    addDisposition: 'Dispositie toevoegen',
    shared: 'Gezamenlijk',
    private: 'Prive',
    attendees: 'Deelnemers aan de vergadering',
    totalProjects: 'Alle projecten',
    totalTasks: 'Alle taken',
    members: 'Leden',
    productivity: 'Productiviteit',
    customerRequestsByCustomer: 'Offerteaanvragen volgens klanten',
    customerRequestsStatistics: 'Offerteaanvragen statistieken',
    customerRequestsValue: 'Offerteaanvragen warde',
    currentWeek: 'Huidige week',
    previousWeek: 'Vorige week',
    weekNumber: 'Weeknummer',
    enterWeekNumber: 'Vul het weeknummer in',
    actual: 'Actueel',
    projection: 'Projectie',
    notWeighting: 'Wachten op weging',
    beginWeighting: 'Wegen bezig',
    stopWeighting: 'Wegen gestopt',
    endWeighting: 'Wegen afgerond',
    customerQuantity: 'Huidige aantal klanten',
    interactionQuantity: 'Huidige aantal interacties',
    customerRequestsQuantity: 'Offerteaanvrage aantal',
    customerRequestsValueActual: 'Huidige waarde van offerteaanvragen',
    sinceLastMonth: 'Sinds afgelopen maand',
    invoiceProFormPl: 'Proforma factuur (pl)',
    invoiceProFormEn: 'Proforma factuur (en)',
    anyQuestion: 'Heeft u vragen?',
    dateOfIssue: 'Uitgiftedatum',
    seller: 'Verkoper',
    bankAccount: 'Bankrekening',
    buyer: 'Inkoper',
    productServiceName: 'Naam van goederen/diensten',
    bruttoPrice: 'Brutoprijs',
    bruttoWorth: 'Brutowaarde',
    worth: 'Waarde',
    inWords: 'In woorden',
    documentIssue: 'Het document is uitgegeven door',
    documentReceived: 'Het document is ontvangen door',
    unitOfMeasure: 'j.m',
    bankName: 'Banknaam',
    fillNew: 'Invulling voor nieuwe',
    newContact: 'Nieuw contact',
    orderConfirmPrint: 'Orderbevestiging',
    required: 'Verplicht',
    labelCols: 'Titel breedte',
    contentCols: 'Inhoud breedte',
    default: 'Basis',
    links: 'Koppelingen',
    selectCustomer: 'Klant kiezen',
    fieldCols: 'Veldbreedte',
    newRow: 'Nieuwe rij',
    noData: 'Geen data',
    allFiles: 'Alle bestanden',
    query: 'Aanvraag',
    newField: 'Nieuw veld',
    newItem: 'Nieuw element',
    sortDisabled: 'Sorteren uitgeschakeld',
    groupDisabled: 'Groepering uitgeschakeld',
    filterDisabled: 'Filteren uitgeschakeld',
    functionalDisabled: 'Opsomming uitgeschakeld',
    textAlign: 'Plaatsing',
    manualEditionRedirect: 'Handmatige omleiding van bewerkingen',
    calcDisabled: 'Opsomming uitgeschakeld',
    databaseTranslation: 'Interface-vertaling',
    toolbar: 'Opdrachtinterface',
    toolbarItems: 'Commandobalk-elementen',
    noItemsAvailable: 'Geen componenten beschikbaar',
    commandName: 'Commandonaam',
    module: 'Module',
    onChange: 'Bij het wijzigen van',
    emptyTicketParking: 'Voer ticketnummer in',
    acceptDispositionParking: 'Aanvaarding van opdrachten',
    paymentParking: 'Betalingen',
    servicesParking: 'Andere services',
    beginningStartParking: '<< Terug naar beginpagina',
    exitNoDispositionParking: 'De parkeerplaats verlaten zonder instructies',
    codeConfirmedParking: 'Code bevestigen',
    codeFromSMSParking: 'Scan of voer de code van de SMS in',
    confirmDriverParking: 'Bevestiging van de chauffeur',
    scanIdCardParking: 'Scan uw identiteitskaart',
    enterIdManuallyParking: 'Voer uw identiteitskaart handmatig in',
    notFoundDriverParking: 'De gegevens van de bestuurder zijn niet bevestigd. Neem contact op met het personeel van de parkeer.',
    checkAllDataParking: 'CONTROLEER ALLE GEGEVENS',
    correctTheDataParking: 'Gegevens corrigeren',
    instructionHasBeenRegisteredParking: 'De zending is geregistreerd in de wachtrij. We kijken uit naar een sms om je te informeren over je vertrek!',
    againScanParking: 'Document niet gescand! Scan opnieuw!',
    servicesParkingAdd: 'Aangeboden services',
    selectedServices: 'Geselecteerde services',
    toatalAmountParking: 'Totaal bedrag  (Zł)',
    payParking: 'Betaal',
    serviceParking: 'Service',
    punishmentParking: 'Boete voor bestuurder',
    unitParking: 'Zł',
    payByCardparking: 'Betalen met kaart',
    detailsPunishmentParking: 'Details van in rekening gebrachte vergoedingen',
    showTotals: 'Toon samenvattingen',
    additional: 'Extra',
    addNipParking: 'BTW invoeren',
    confirmNipParking: 'Bevestig',
    noConfirmNipParking: 'Bevestiging zonder BTW',
    payCardBankParking: 'Betalen met bankpas',
    additionalPunishmantParking: 'Toeslag voor parkeerdiensten',
    documentReaderParking: 'De documentlezer bevindt zich aan de rechterkant van Selfboxu',
    instructionOrTicket: 'Instructie/ticketnummer',
  },
  weekDays: {
    Mo: 'Mo',
    Tu: 'Tu',
    We: 'We',
    Th: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    Su: 'Su',
  },
  table: {
    sort: '#',
    sortNumber: 'N',
    code: 'Code',
    number: 'Number',
    name: 'Name',
    article: 'Article',
    type: 'Type',
    customer: 'Customer',
    reference: 'Reference',
    createdAt: 'Created At',
    deliveryDate: 'Delivery date',
    shipmentDate: 'Shipment date',
    status: 'Status',
    author: 'Author',
    price: 'Price',
    sum: 'Sum',
    sumWithoutVAT: 'Sum without VAT',
    sumVAT: 'Sum VAT',
    sumWithVAT: 'Sum with VAT',
    currency: 'Currency',
    note: 'Note',
    paymentTerms: 'Terms of payment',
    project: 'Project',
    deliveryMethod: 'Delivery method',
    packageMaterial: 'Package material',
    comment: 'Comments',
    product: 'Product',
    description: 'Description',
    jOM: 'JOM',
    quantity: 'Quantity',
    discountPercent: 'Discount %',
    discountSum: 'Discount sum',
    fileSize: 'Size, kB',
    country: 'Country',
    email: 'Email',
    address: 'Address',
    abbreviation: 'Abbreviation',
    actions: 'Actions',
    priceCode: 'Price code',
    executor: 'Executor',
    executionPeriod: 'Execution period',
    changeDate: 'Change date',
    user: 'User',
    nip: 'NIP',
    region: 'Region',
    pricesType: 'Prices type',
    customersGroup: 'Customers group',
    language: 'Language',
    executorName: 'Executor name',
    importance: 'Importance',
    executorRole: 'Executor role',
    contents: 'Contents',
    template: 'Szablon',
    base: 'Base',
    task: 'Task',
    category: 'Category',
    priority: 'Priority',
    postDate: 'Post date',
    topic: 'Topic',
    objectType: 'Object type',
    objectDescription: 'Object description',
    objectId: 'Object ID',
    changeType: 'Change type',
    sent: 'Sent',
    constructor: 'Constructor',
    const: 'Konstruktor',
    manager: 'Manager',
    commission: 'Commission, %',
    version: 'Version',
    orderNumber: 'Order number',
    isActive: 'Aktywny',
    title: 'Title',
    notes: 'Notes',
    startTime: 'Start time',
    endTime: 'End time',
    id: 'Id',
    uuid: 'UUID',
    numberStr: 'Numer (Str)',
    prefix: 'Prefiks',
    noFilters: 'Geen filters',
    sortNumberFull: 'Volgnummer',
    tasks: 'Taken',
    comments: 'Commentaar',
    fullName: 'Volledige naam',
    symbol: 'Symbool',
    dateOfBirth: 'Geboortedatum',
    sex: 'Geslacht',
    male: 'Heren',
    female: 'Vrouw',
    markedToDelete: 'Gemarkeerd voor verwijderen',
    financialPeriod: 'Financiële voorwaarden',
    interaction: 'Interactie',
    delete: 'Verwijderen',
    discription: 'Beschrijving',
    processName: 'Procesnaam',
    minutesCarService: 'Autoservicetijd',
    result: 'Resultaat',
    image: 'Beeld',
    referenceNumber: 'Referentienummer',
    numberOfWeighings: 'Aantal wegingen',
    receipts: 'Ontvangst',
    scaleReport: 'Gewichtsrapport',
    carPositionReport: "Rapport over de inzet van auto's",
    scaleChangeReport: 'Gewichtsverplaatsingsrapport',
    manual: 'Handmatig',
    updatedAt: 'Update datum',
    costs: 'Kosten',
    positionDescription: 'Beschrijving artikel',
    shipmentAddress: 'Afleveradres',
    whoPays: 'Wie betaalt',
    documents: 'Documenten',
    shipmentType: 'Soort verzending',
    packageNumber: 'Perceelnummer',
    role: 'Functie',
    progressValue: 'Vooruitgang',
    statusStr: 'Status (Str)',
    bidSum: 'Offertebedrag',
    sumNetto: 'Netto bedrag',
    sumVat: 'BTW-bedrag',
    sumBrutto: 'Brutto bedrag',
    currencyStr: 'Valuta (Str)',
    trackTime: 'Tijd samen voor een bepaalde aanpak',
    totalTime: 'Tijd samen',
    time: 'Tijd',
    hours: 'Uren',
    minutes: 'Minuten',
    responsibility: 'Verantwoordelijkheid',
    unitOfMeasure: 'Meeteenheid',
    discount: 'Korting',
    reports: 'Meldingen',
    responsible: 'Verantwoordelijk',
    phone: 'Telefoon',
    nameFull: 'Naam en achternaam',
    deliverySchedule: 'Verzendplan',
    addressesPhones: 'Adressen en telefoonnummers',
    contact: 'Contact',
    howToContact: 'Contact opnemen',
    productionOrder: 'Productieopdracht',
    subExecutor: 'Onderaannemer',
    saleDate: 'Verkoopdatum',
    lodging: 'Accommodatie',
    constr: 'Constructeur',
    deleteConfirmation: 'Weet U zeker dat je een item uit de database wilt verwijderen?',
    constructionAddress: 'Bouwadres',
    correspondenceAddress: 'Postadres',
    isService: 'Service',
    password: 'Wachtwoord',
    resource: 'Hulpbron',
    isGeneral: 'Algemeen',
    isClosed: 'Gesloten',
    baseDocument: 'Documentatie',
    emailAccount: 'E-mailaccount',
    sendingDate: 'Verzendingsdatum',
    tags: 'Tags',
    proforma: 'Pro-Forma',
    versionUuid: 'UUID versie',
    state: 'Staat',
    date: 'Datum',
    ordered: 'Besteld',
    termsOfPayment: 'Betalingstermijn',
    executionTerm: 'Termijn voor uitvoering',
    order: 'Bestelling',
    resultEmail: 'E-mail',
    days: 'Dagen',
    alphaCode2: 'Alfa-2 kod',
    alphaCode3: 'Alfa-3 kod',
    pricelistKind: 'Prijslijst type',
    pricingMethod: 'Waardebepalingsmethode',
    from: 'Vanaf',
    to: 'Tot',
    confirmed: 'Gecontroleerd',
    includedInMain: 'Inbegrepen in de basis',
    notUseDiscount: 'Geen kortingen gebruiken',
    maxDiscount: 'Maximale korting',
    priceType: 'Prijs type',
    beginDate: 'Begindatum',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    formula: 'Formule',
    belongs: 'Betreft',
    discountType: 'Type korting',
    default: 'Standaard',
    customerAccess: 'Goederen waaruit klanten kunnen kiezen ',
    documentType: 'Documenttype',
    storeFilesToHardDrive: 'Bestanden opslaan op uw harde schijf',
    filters: 'Filtry',
    dimensionX: 'Axis X',
    dimensionY: 'Axis Y',
    max: 'Max',
    min: 'Min',
    step: 'Stap',
    customerLockOrder: 'Blokorder voor klant',
    blockSelfServiceInterface: 'Zelfbedieningsinterface blokkeren',
    blockUnblockSelfServiceInterface: 'Zelfbedieningsinterface vergrendelen/ontgrendele',
    agreement: 'Contract',
    offer: 'Offerte',
    period: 'Periode',
    checkExecution: 'Uitvoering controleren',
    execution: 'Uitvoering',
    checker: 'Controler',
    hasLinks: 'Er zijn koppelingen',
    links: 'Koppeling',
    processed: 'Ontwikkeld',
    icon: 'Pictogram',
    parent: 'Het hoort bij',
    viewType: 'Type weergave',
    readOnly: 'Enkel lezen',
    component: 'Component',
    store: 'Archief',
    model: 'Model',
    path: 'Pad',
    detailPath: 'Pad naar het bewerkingselement',
    view: 'Weergave',
    schemeOfCargo: 'Rapporteer',
    counterparty: 'Klant',
    typeOfOperation: 'Soort operatie',
    warehouse: 'Locatie',
    ship: 'Schip',
    scale: 'Gewicht',
    scale2: 'Gewicht 2',
    scaleTwo: 'Gewicht 2',
    weighingStation: 'Weegstation',
    brutto: 'Bruto',
    tare: 'Tarra',
    netto: 'Netto',
    wagon: 'Wagen',
    loadCapacity: 'Belastingscapaciteit',
    deliveryOrder: 'Bestelling',
    forwarder: 'Expediteur',
    doNotLoadToOracle: 'Laad niet in Oracle',
    expireDate: 'Vervaldatum',
    closeDate: 'Afsluitingsdatum',
    docType: 'Type',
    warehouseSection: 'Magazijnsector',
    item: 'Goederen',
    subject: 'Onderwerp',
    reason: 'Reden',
    declaredTonnage: 'Gedeclareerde tonnage',
    maxLoss: 'Max. holte',
    numberAndDateSdk: 'Nummer, datuum SDK',
    numberAndDateDSKAndMRN: 'Nummer, datuum DSK/MRN',
    customGoodsStatus: 'Douanestatus van goed',
    carServiceTime: 'Autoservicetijd',
    controlCompany: 'Inspectiebedrijf',
    correspondence: 'Correspondentie',
    descComment: 'Commentaar',
    internalComment: 'Intern commentaar',
    disposition: 'Dispositie',
    deliveryNote: 'CMR vrachtbrief',
    trainNumber: 'Trein nummer',
    containerNumber: 'Klant nummer',
    recipient: 'Ontvanger',
    operationType: 'Soort operatie',
    vendor: 'Leverancier',
    contractor: 'Klant',
    btsStatement: 'Verklaring BTS',
    station: 'Functie',
    warehouseSquare: 'Magazijn/Loodsc',
    assortmentGmpPLus: 'Assortiment GMP+',
    closed: 'Gesloten',
    startOfWeighting: 'Begin met wegen',
    endOfWeighting: 'Einde met weging',
    driver: 'Chauffeur',
    carQueueStatus: 'Status van de wachtrij',
    carsQueueStatus: 'Status van de wachtrij',
    driverPhoneNumber: 'Telefoonnummer chauffeur',
    phoneNumber: 'Telefoonnummer',
    carNumber: 'Auto-nummer',
    trailer: 'Aanhangwagen',
    idNumber: 'Bewijsnummer',
    carrier: 'Vervoerder',
    parkingAriveDateAndTime: 'Datum en tijd van aankomst op de parkeerplaats',
    retaring: 'Retargeting',
    done: 'Gemaakt',
    multiple: 'Meervoudig',
    allowRetaring: 'Retargeting toelaten',
    allowSetZero: 'Resetten van de kassabon toestaan',
    assortment: 'Assortiment',
    tests: 'Test',
    dateOfTest: 'Testdatum',
    arriveFrom: 'Aankomst vanaf',
    lockArrivesFrom: 'Blokkeer aankomst van',
    cancelWeighting: 'Weging annuleren',
    position: 'Positie',
    scaleBrutto: 'Brutogewicht',
    scaleTare: 'Tarragewicht',
    scaleNetto: 'Nettogewicht',
    balancedScale: 'Stabiele weegschaal',
    unbalancedScale: 'Onstabiel gewicht',
    bruttoTime: 'Brutogewicht datum',
    tareTime: 'Tarragewicht datum',
    nettoTime: 'Nettogewicht datum',
    bruttoAuthor: 'Bruto auteur',
    tareAuthor: 'Tarra auteur',
    nettoAuthor: 'Netto auteur',
    approved: 'Goedgekeurd',
    approvedDate: 'Datum van goedkeuring',
    approvedAuthor: 'Erkend Auteur',
    carriageNumber: 'Wagonnummer',
    notUse: 'Niet gebruiken',
    useActualWarehouse: 'Gebruik het magazijn',
    sendDriversSms: 'Stuur sms-berichten naar chauffeurs',
    useProductCondition: 'Productstatus gebruiken',
    numberOfCopies: 'Aantal kopieën',
    returnToWarehouse: 'Terug naar het magazijn',
    disableControlOnScales: 'Schakel de bedieningselementen op de weegschaal uit',
    reverseOperation: 'Omgekeerde bediening',
    disableControlInDispositions: 'Controles op weggooien uitschakelen',
    actualWarehouse: 'Vestigingsplaats',
    deliveryNoteNumber: 'CMR Vrachtbriefnummer',
    useStability: 'Stabiliteit gebruiken',
    scalesNumber: 'Gewicht nummer',
    scalesVersion: 'Gewicht versie',
    updatePeriod: 'Verlengingstermijn',
    iotGateParameters: 'Deurparameters Iot',
    lightTable: 'Lichtbord',
    additional: 'Extra',
    mine: 'Mijn',
    advicesNumber: 'Adviesnota / magazijnnummer',
    dispositionAuthor: 'De beschikking werd gegeven door',
    dispositionCon: 'Beschikking',
    orderAuthor: 'Het bevel werd uitgevaardigd door',
    paramValues: 'Betekenis van parameters',
    queryParam: 'Parameter Query (?)',
    hashParam: 'Parameter Hash (#)',
    typeOfDocument: 'Soort document',
    arrivalDateDriver: 'Datum en tijd van aankomst op de parkeerplaats',
    vehicle: 'Autonummer',
    vehicleWagon: 'Wagennummer',
    key: 'Sleutel',
    read: 'Voorbeeld',
    modify: 'Wijzigingen',
    factor: 'Factor',
    deviation: 'Afwijking',
    added: 'Bijgevoegd',
    color: 'Kleur',
    accessRole: 'Rol van toegang',
    weightingType: 'Weegtype',
    firstWeighting: 'Eerste weging',
    secondWeighting: 'Tweede weging',
    barCode: 'Barcode',
    driverTicket: 'Bestuurderskaartje',
    entryTicket: 'Toegangsbewijs',
    defaultWarehouse: 'Basisopslag',
    externalCode: 'Externe code',
    carBrand: 'Automerk',
    declaredTare: 'Gedeclareerde tarra',
    idCard: 'Identiteitskaart',
    attentionCount: 'Aantal opmerkingen',
    isCardNumber: 'Bewijsnummer',
    isCardSerial: 'Serie van bewijs',
    termsOfUse: 'Toestemming voor gebruikersvoorwaarden',
    desktopMode: 'Bureaubladmodus (AMP)',
    desktopName: 'Dashboardnaam (AMP)',
    printerName: 'Naam printer',
    printerList: 'Lijst met printers',
    selectProtocol: 'Keuze van protocol',
    server: 'Server',
    port: 'Port',
    api: 'Api',
    reclamationRelatedTasks: 'Taken in verband met klachten',
    sendCommand: 'Opdracht verzenden',
    typeParse: 'Type parsing',
    registry: 'Inschrijven',
    comPort: 'COM-poort',
    guarantee: 'Garantie',
    initDate: 'Begindatum',
    capasity: 'Capaciteit',
    carsBuffer: 'Autobuffer',
    externalId: 'Externe identificator',
    externalIdShort: 'Externe id',
    queueOn: 'In de wachtrij',
    reflect: 'Reflecteren',
    scalesType: 'Soort gewicht',
    automaticWeightingEnd: 'Automatisch voltooien van weging',
    barCodePrefix: 'Barcode-prefix',
    handlers: 'Onderzoeken',
    scaleProtocol: 'Weegprotocol',
    videoRecorder: 'Videorecorder',
    usingVideoRecorder: 'Gebruik van videorecorder',
    inquiry: 'Aanvraag',
    ptotocols: 'Protocollen',
    protocol: 'Protocol',
    numberCopies: 'Aantal exemplaren',
    productionQuantity: 'Productieaantal',
    orientation: 'Oriëntatie',
    size: 'Formaat',
    rollPrinter: 'Rolprinter',
    clientId: 'Klant ID',
    computerName: 'Computernaam',
    networkPort: 'Netwerkpoort',
    prohibitionWorkSeveralSessions: 'Verbod op werken in meerdere sessies',
    issuanceRailwayInstructions: 'Uitgifte van treininstructies',
    predefinedName: 'Naam gedefinieerd',
    supplier: 'Leverancier',
    versioningMethod: 'Versiebeheer methode',
    main: 'Hoofdpagina',
    postCode: 'Postcode',
    constructionPostCode: 'Gebouw postcode',
    city: 'Stad',
    adminAccess: 'Admin-toegang',
    userAccess: 'Gebruikerstoegang',
    statusgroup: 'Groep status',
    code1C: 'Code 1C',
    sortId: 'Volg nummer',
    placing: 'Plaatsing',
    paymentType: 'Betaalmethode',
    cashFlowItem: 'Kasstroomcomponent',
    sumPayment: 'Totaalbedrag',
    typeOfMovement: 'Soort beweging',
    typeOfPayment: 'Soort betaling',
    leader: 'Leidinggevende',
    deputy: 'Vice leidinggevende',
    employee: 'Medewerker',
    useRestriction: 'Gebruik algemene beperkingen',
    useDepartmentRestriction: 'Gebruik de beperking per afdeling',
    calendarEvent: 'Kalender evenement',
    valueType: 'Soort waarde',
    field: 'Veld',
    searchField: 'Zoekveld',
    operator: 'Conditie',
    value: 'Belang van',
    moving: 'Verplaatsen',
    yearNumber: 'Jaar',
    lastNumber: 'Laatste nummer',
    singularName: 'Enkelvoudige naam',
    singularTitle: 'Titel',
    lang: 'Vertaling',
    beginWeighting: 'Begin met wegen',
    endWeighting: 'Einde weging',
    nettoOverAll: 'Netto algemeen, T',
    nettoRegistered: 'Netto geregistreerd, T',
    enumerator: 'Teller',
    appObject: 'Systeem object',
    object: 'Object',
    incomingDate: 'Document datum',
    incomingNumber: 'Extern documentnummer',
    client: 'Klant',
    contactPerson: 'Contactpersoon',
    basicDocument: 'Kerndocument',
    salesOrder: 'Klantorder',
    exchangeRate: 'Valutakoers',
    exchangeRateDate: 'Valutakoers datum',
    exchangeRateTableNumber: 'Nummer wisselkoers',
    amountsType: 'Soort bedrag',
    warehouseFrom: 'Het magazijn van',
    warehouseTo: 'Het magazijn tot',
    signature: 'Handtekening',
    hidden: 'Verborgen',
    taxRate: 'BTW bedrag',
    basicItem: 'Basiselement',
    forServices: 'Voor services',
    reverseChange: 'Omgekeerde verandering',
    vatAmount: 'Bedrag storting',
    netWeight: 'Nettogewicht',
    bruttoWeight: 'Bruttogewicht',
    vatRate: 'BTW tarief',
    visible: 'Zichtbaar',
    use: 'Gebruik',
    width: 'Breedte',
    imo: 'IMO',
    length: 'Lengte',
    trackModel: 'Transportmodel',
    daysSinceAdmission: 'Dagen na opname',
    birthDate: 'Geboortedatum',
    fieldType: 'Veldtype',
    dataType: 'Soort gegevens',
    averagePrice: 'Gemiddelde prijs',
    grossAmount: 'Waarde (brutto)',
    lineNumber: 'Regelnummer',
    reclamationNumber: 'Klachtnummer',
    clientName: 'Naam klant',
    telephone: 'Telefoon',
    deliveryStatus: 'Verzendstatus',
    amount: 'Waarde',
    deliveryAddress: 'Afleveradres',
    salesDate: 'Verkoopdatum',
    bankAccount: 'Rekeningnummer',
    begin: 'Begin',
    ending: 'Einde',
    stop: 'Stop',
    entry: 'Datum binnenkomst',
    plannedEntry: 'Geplande datum van aankomst',
    route: 'Route',
    counterpartyAddress: 'Klant adres',
    realizationDate: 'Datum van uitvoering',
    maxLength: 'Maximale lengte',
    disabled: 'Enkel lezen',
    login: 'Inloggen',
    passwordConfirmation: 'Wachtwoord bevestigen',
    fullRights: 'Volledige rechten',
    use2FA: 'Tweefactorauthenticatie',
    externalUser: 'Externe gebruiker',
    useCustomerAccess: 'Gebruiksbeperking per klant',
    useForExternalDrivers: 'Boete voor externe bestuurder',
    useForInternalDrivers: 'Boete voor interne bestuurder',
    shortName: 'Korte naam',
    accountingName: 'Naam voor boekhouding',
    isPaid: 'Betaald',
    isCanceled: 'Geannuleerd',
    isHandcrafted: 'Handgemaakt',
    isPrint: 'Afgedrukt',
    punishmentType: 'Soort parkeertarieven',
    periodType: 'Soort periode',
    showYTotal: 'Toon Y Totaal',
    showXTotal: 'Toon X Totaal',
    ref: 'Referentie',
    payment: 'Betalingsbewijs',
    daysExceeded: 'Aantal dagen overschreden',
    ip: 'IP',
    method: 'Metod',
    url: 'URL',
    query: 'Aanvraag',
    params: 'Parameter',
    body: 'Lichaam',
    headers: 'Koppen',
    waitingTime: 'Wachttijd',
    payedAdvance: 'Vooruitbetaling',
    skonto: 'Sub-account',
    sortBy: 'Sortering',
    fakturaCheck: 'Factuur',
    fakturaName: 'Naam',
    fakturaNIP: 'BTW',
    street: 'Straat',
    locality: 'Stad',
    multi: 'Veel',
    executionDays: 'Dagen om uit te voeren',
    executionHours: 'Uren om uit te voeren',
    executionMinutes: 'Minuten om uit te voeren',
    currentDateExecution: 'In één dag uitvoeren',
    script: 'Scenario',
    trafficLight: 'Verkeerslichten',
    useTrafficLight: 'Verkeerslichten gebruiken',
    connection: 'Koppeling',
    okTrafficLight: 'Ok',
    plusTrafficLight: 'Te ver',
    minusTrafficLight: 'Te dichtbij',
    euMember: 'EU-lid',
    allowChangeExecutor: 'Verandering van aannemer toestaan',
    allowChangeStage: 'Verandering van stadium toestaan',
    stage: 'Stadium',
    departureDate: 'Vertrekdatum',
    vehicleType: 'Soort auto',
    transportWay: 'Soort transport',
    onlySelected: 'Alleen bepaalde',
    rate: 'Tarief',
    multiplier: 'Factor',
    rateDate: 'Cursus datum',
    activeDate: 'Ontvangstdatum',
    table: 'Wisselkoerstabel',
    tableNumber: 'Tabel nummer NBP',
    expression: 'Expressie',
    sortDirection: 'Sorteerrichting',
    report: 'Rapport',
    attribs: 'Kenmerken',
    systemRowNumber: 'Rij-nummer (systeem)',
    detailView: 'Weergave van het onderdeel',
    reportR27: 'Rapport R27',
    shift: 'Wijzigen',
    presentation: 'Presentatie',
    variant: 'Variant',
    tooltip: 'Tip',
    forwarders: 'Expediteurs',
    isTotal: 'Toon samen',
    regon: 'Kvk nummer',
    productVariant: 'Productvariant',
    recorder: 'Opnameapparaat',
  },
  configurator: {
    selectedParameters: 'Selected parameters',
    byNumber: 'by nr...',
    byName: 'by name / description ...',
  },
  settings: {
    title: 'Settings',
    lsbThemeDefault: 'Default',
    lsbThemeLight: 'Light',
    lsbThemeDark: 'Dark',
    lsbTypeFixed: 'Fixed',
    lsbTypeCondensed: 'Condensed',
    lsbTypeScrollable: 'Scrollable',
  },
  editTypes: {
    created: 'Created',
    edited: 'Edited',
    markedToDeleteRestore: 'Marked to delete / Restore',
    deleted: 'Deleted',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  documentation: {},
  permission: {},
  guide: {},
  components: {
    historyOfChanges: 'History of changes.',
    relatedTasks: 'Related tasks.',
    versionDetails: 'Gegevens van de versie',
    versionsCompare: 'Versievergelijking',
    linkedDocs: 'Gerelateerde documenten',
    subordination: 'Ondergeschikte structuur',
    advancedSearch: 'Uitgebreid zoeken',
    viewSettings: 'Instellingen weergeven',
    periodManager: 'Periode instellen',
    report: 'Rapport',
  },
  example: {},
  errorLog: {
    qrCodeNotFound: 'Qr-code niet gevonden',
  },
  excel: {},
  zip: {},
  pdf: {},
  theme: {
    leftSidebar: 'Left sidebar',
  },
  valueTypes: {
    number: 'Nummer',
    date: 'Datum',
    string: 'Tekst',
    boolean: 'Logisch',
    ref: 'Referentie',
  },
  msg: {
    authCodeSended: 'Er is een nieuwe verificatiecode verzonden',
    restore: 'Het element wordt hersteld!',
    delete: 'Het item wordt gemarkeerd voor verwijdering!',
    deleteError: 'Verwijderingsfout',
    catalogWriteError: 'Fout bij opslaan van mapitem',
    catalogWriteSuccess: 'Het cataloguselement is opgeslagen',
    filingError: 'Gegevens zijn niet correct',
    createTrackingSuccess: 'Het object is toegevoegd aan de tracking',
    createTrackingError: 'Object niet toegevoegd aan tracking',
    technicalBreak: 'Technische pauze',
    groupHasNestedItems: 'De groep bestaat uit ondergeschikte elementen',
    noFoundResults: 'Er zijn geen resultaten gevonden voor',
    searchResultsFor: 'Er wordt gezocht naar resultaten voor',
    startTypingForSearch: 'Schrijf om te zoeken.',
    passwordConfirmationError: 'Wachtwoorden zijn niet hetzelfde',
    noRelatedViewSettings: 'Geen gerelateerde weergave-instellingen',
    setDefaultSettings: 'Gebruikersinstellingen verwijderd.',
    notFoundNextStage: 'De volgende stap kan niet worden gevonden.',
    wrongNip: 'Onjuist BTW-formaat',
  },
  reclamation: {
    new: 'Nieuwe klacht',
    add: 'Klacht toevoegen',
    addUpdatePositions: 'Een schade-item toevoegen/bewerken',
    addOrder: 'Bestelling toevoegen',
    productionJobNumber: 'Productietaaknummer',
    open: 'Open',
    ongoing: 'In bewerking',
    toReceive: 'Te verzamelen ',
    negative: 'Geweigerd',
  },
  interaction: {
    new: 'Nieuwe interactie',
    addNewVersion: 'Nieuwe versie toevoegen',
    numberEdition: 'Interactienummer bewerken',
    number: 'Interactienummer',
    actualStatus: 'Actuele status',
    versionsHistory: 'Versiegeschiedenis',
    newVersion: 'Nieuwe interactieversie',
    edit: 'Interactie bewerken numer',
    commentsAndLinkedEmails: 'Opmerkingen / Gerelateerde e-mails',
    tasksAndSalesOrders: 'Taken / Klantenorders',
    presentation: 'Interactie nummer {0} vanaf {1}',
    addComment: 'Nieuw commentaar',
    addEvent: 'Nieuw evenement',
    selectFiles: 'Selecteer uw bestanden!',
    attachments: 'Interactie bijlagen',
    msg: {
      selectItemFirst: 'Kies eerst interactie!',
      return: 'Interactie wordt hersteld!',
      delete: 'De interactie wordt gemarkeerd voor verwijdering!',
      orderAvailable: "Er bestaat al interactie met zo'n referentie: {0}",
      emptyCustomer: 'Onvervulde klant!',
      noWrite: 'De interactie moet worden opgenomen!',
    },
  },
  paymentOperation: {
    title: 'Betalingsactiviteiten',
    objectTitle: 'Betalingsoperatie',
    new: 'Nieuwe operatie',
    presentation: 'Betalingsoperatie nummer {0} vanaf {1}',
    createTask: 'Een taak creëren',
    msg: {
      selectOrderFirst: 'Kies eerst je activiteit!',
      return: 'De betaling wordt hersteld!',
      delete: 'De betalingstransactie wordt gemarkeerd voor verwijdering!',
      orderAvailable: 'Er bestaat al een betalingstransactie met een dergelijke referentie: {0}',
      emptyCustomer: 'Onvervulde klant!',
      emptyCurrency: 'Onvervulde valuta!',
      emptyReference: 'Onvervulde referentie!',
      emptyFields: 'Niet alle verplichte velden zijn ingevuld!',
      writeOrder: 'De betaling moet worden geregistreerd!',
      writed: 'De betalingshandeling is geregistreerd',
      confirmDouble: 'Er bestaat al een betalingstransactie met deze referentie. Bevestigt u dat u wilt doorgaan met de waardering?',
    },
    enterReference: 'Referentie invoeren',
    enterProject: 'Projectnaam invoeren',
  },
  enums: {
    DATA_TYPES: {
      number: 'Nummer',
      date: 'Datum',
      text: 'Tekst',
      string: 'Reeks',
      boolean: 'Booleaans',
      object: 'Object',
      enum: 'Enum',
    },
    TEXT_ALIGNS: {
      left: 'Links',
      right: 'Rechts',
      center: 'Middenin',
    },
    IMPORTANCES: {
      NOT_SET: 'Geen parameter',
      HIGH: 'Hoog',
      NORMAL: 'Gewone',
      LOW: 'Laag',
    },
    ACTIVE_STATUSES: {
      Active: 'Actief',
      Blocked: 'niet actief',
    },
    PACKAGE_MATERIALS: {
      Folia: 'Folie',
      Karton: 'Karton',
    },
    OBJECT_TYPES: {
      catalog: 'Catalogus',
      document: 'Document',
      register: 'Registreer',
      report: 'Rapport',
    },
    VIEW_TYPES: {
      list: 'Lijst',
      detail: 'Detail',
      select: 'Keuze',
      other: 'Andere',
    },
    NAV_VIEW_TYPES: {
      list: 'Lijst',
      detail: 'Detail',
      report: 'Rapport',
      static: 'Statisch',
    },
    VERSIONING_METHODS: {
      create: 'Bij de creatie van',
      update: 'Bij het updaten van',
      confirm: 'Bij goedkeuring',
      all: 'Alle operaties',
    },
    REPORT_PERIOD_TYPES: {
      date: 'Voor de datum',
      period: 'Voor de periode',
      without: 'Without period',
    },
    changeTypes: {
      create: 'Toevoeging',
      update: 'Wijzigen',
    },
    changedTypes: {
      create: 'Toegevoegd',
      update: 'Wizigen',
    },
    navigationPlacings: {
      general: 'Belangrijk',
      usual: 'Gewone',
      other: 'Andere',
    },
    contactTypes: {
      messenger: 'Messenger',
      phone: 'Telephone',
      email: 'E-mail',
      skype: 'Skype',
      social: 'Sociale media',
    },
    typesOfPayment: {
      Cash: 'Contant',
      Bank: 'Overschrijving',
    },
    statusList: {
      Finished: 'Gesloten',
      Ongoing: 'In uitvoering',
    },
    typesOfMovement: {
      Receipt: 'Inkomsten',
      Expense: 'Kosten',
    },
    TRANSPORT_TYPES: {
      Car: 'Auto',
      Trailer: 'Oplegger / Aanhangwagen',
      Carriage: 'Wagen',
    },
    TEXT_POSITION: {
      begin: 'In het begin',
      part: 'In een deel van het gedicht',
      end: 'Op het einde',
    },
    TYPE_OF_OPERATION: {
      Shipment: 'Verzending',
      Receipt: 'Aanneming',
      Reweighing: 'Herweging',
      DirectVariantShipment: 'Rechtstreekse uitgifte',
      DirectVariantReceipt: 'Rechtstreekse toegang',
      Resigning: 'Ontslag nemen',
      Relocation: 'Herlocatie',
    },
    TYPE_OF_DOCUMENT: {
      Automobile: 'Auto',
      Railway: 'Spoorweg',
      Water: 'Water',
      Internal: 'Intern',
    },
    TYPE_OF_DISPOSITION: {
      Loading: 'Laden',
      Unloading: 'Lozing',
    },
    INVENTORY_CATEGORY_TYPE: {
      income: 'Inkomsten',
      shipment: 'Kosten',
      transfer: 'Overschrijving',
    },
    PROJECT_STATUSES: {
      Finished: 'Voltooid',
      Ongoing: 'Lopend',
    },
    PAYMENT_TYPE_STATUSES: {
      Finished: 'Voltooid',
      Ongoing: 'Lopend',
    },
    TYPE_OF_PAYMENT: {
      Cash: 'Contant',
      Bank: 'Overschrijving',
    },
    TYPE_OF_MOVEMENT: {
      Receipt: 'Inkomsten',
      Expense: 'Kosten',
    },
    PROTOCOL_SCALE: {
      Mechanical: 'Mechanisch',
      IoT: 'IoT',
      'TCP/IP': 'TCP/IP',
      Modbus: 'Modbus',
      COM: 'COM',
      S7: 'S7',
    },
    PROTOCOL_SCALE_PARSE: {
      ToledoSimple: 'ToledoSimple',
      ToledoSimpleTwice: 'ToledoSimpleTwice',
      ToledoModbus: 'ToledoModbus',
      IT4000E: 'IT4000E',
    },
    CAR_QUEUE_STATUS: {
      OnTheWay: 'Onderweg',
      EntryReservedWithBuffer: 'Reservering in de buffer',
      Accepted: 'In de wachtrij',
      EntryReserved: 'Reserveren zonder buffer',
      EntryAllowed: 'Vertrek toegestaan',
      ParkingExit: 'Hij verliet de parkeerplaats',
      TerminalExit: 'Hij verliet de terminal',
      Deleted: 'Geannuleerd',
      EntryAddParking: 'Op de parkeerplaats voor onderzoek',
      ExitAddParking: 'Hij verliet de onderzoeksparkeerplaats',
      Paused: 'Pauze',
    },
    RESEARCH_RESULT: {
      Positive: 'Positieve test',
      Negative: 'Negatieve test',
    },
    VEHICLE_TYPE: {
      truck: 'Vrachtwagens',
      car: 'Tot 3.5t',
    },
    TRANSPORT_WAYS: {
      shippingCompany: 'Transportbedrijf',
      ourTransport: 'Ons transport',
    },
    CURRENCY_EXCHANGE_TABLES: {
      A: 'A',
      B: 'B',
      C: 'C',
    },
    EXPRESSIONS: {
      sum: 'Totaal',
      min: 'Min',
      max: 'Maks',
      average: 'Gemiddeld',
    },
    SORT_DIRECTIONS: {
      asc: 'Groeien',
      desc: 'Afnemend',
    },
    WORK_SHIFTS: {
      First: 'I (23:00 - 07:00)',
      Second: 'II (07:00 - 15:00)',
      Third: 'III (15:00 - 23:00)',
    },
    ITEMS_PRESENTATIONS: {
      titleAndIcon: 'Titel en pictogram',
      title: 'Titel',
      icon: 'Pictogram',
    },
  },
  pricelistKinds: {
    main: 'Basis',
    surcharge: 'Meerprijs',
  },
  pricingMethods: {
    formula: 'Formule',
    fixed: 'Vast bedrag',
    table: 'Prijs tabel',
    percent: 'Percentage',
    scale: 'Prijslijst',
  },
  discountTypes: {
    price: 'Prijs',
    percent: 'Percentage',
    formula: 'Formule',
  },
  discountBelongs: {
    main: 'Basis prijslijst',
    surcharge: 'Meerprijs',
    main_and_surcharge: 'Prijslijsten en meerprijzen',
    surcharge_and_price: 'Meerprijs en extra artikelen',
    all: 'Alles',
  },
  task: {
    executionReceive: 'Accepteren voor uitvoering',
    showExecuted: 'Toon gemaakt',
    showTasks: 'Toon taken',
    showMyne: 'Toon mijn',
    description: 'Omschrijving van taak',
    executionResult: 'Resultaat',
    newTask: 'Nieuwe taak',
    forExecutorRole: 'Voor de rol van de aannemer',
    forExecutor: 'Voor de opdrachtnemer',
    redirection: 'Taak doorsturen',
  },
  operators: {
    '=': 'Gelijk',
    '!=': 'Niet gelijk',
    '>': 'Groter',
    '<': 'Kleiner',
    '>=': 'Groter of gelijk aan',
    '<=': 'Kleiner of gelijk aan',
    like: 'Inbegrepen',
    notLike: 'Niet inbegrepen',
    inList: 'In de lijst',
    notInList: 'Staat niet op de lijst',
    eq: 'Gelijk',
    ne: 'Niet gelijk',
    gt: 'Groter',
    gte: 'Groter of gelijk aan',
    lt: 'Kleiner',
    lte: 'Kleiner of gelijk aan',
    iLike: 'Inbegrepen',
    notILike: 'Niet inbegrepen',
    filled: 'Ingevuld',
    notFilled: 'Niet Ingevuld',
  },
  reports: {
    salesOrderRegister: 'Bestellijst',
    salesAnalize: 'Verkoopanalyse',
    interactionAnalize: 'Interactieanalyse',
    interactionTimeAnalize: 'Analyse van interactietijden',
    inquiriesAnalize: 'Analyse van offerteaanvragen',
    email: 'Email',
  },
  report: {
    groupLvls: 'Niveaus groeperen',
    variants: 'Verslag varianten',
    writeVariant: 'Variant bewaren',
    onlyForThisUser: 'Enkel voor deze gebruiker',
    newVariant: 'Nieuwe versie van het rapport',
    changeVariantName: 'Rapportvariant hernoemen',
    deleteVariant: 'Rapportvariant verwijderen',
    selectedFields: 'Bepaalde velden',
    columnName: 'Naam van kolom',
    filtered: 'Gefiltreerd',
    grouped: 'Groepeerbaar',
    functional: 'Functionele',
    calculated: ' Berekend',
    byDate: 'per datuum: ',
    byPeriodFrom: 'Periode Van: ',
    byPeriodTo: ' Tot ',
    functions: {
      sum: 'Totaal',
      min: 'Min',
      max: 'Maks',
      average: 'Gemiddeld',
    },
    total: 'Totaal',
    isTotal: 'Totaal is',
    filters: 'Filters',
    groups: 'Groepen',
    resourses: 'Samenvatting',
    sorts: 'Sorteren',
    funcs: 'Berekeningen',
    calcs: 'Berekeningen',
    msg: {
      periodControl: 'Het begin van de periode kan niet groter zijn dan het einde.',
    },
  },
  project: {
    completed: 'Afgerond',
    inProgress: 'Tijdens',
    behind: 'Vertraagd',
    taskOverview: 'Taak bekijken',
    projectStatus: 'Projectstatus',
    weeklyReport: 'Wekelijks rapport',
    monthlyReport: 'Maandelijks rapport',
    action: 'Actie',
    settings: 'Instellingen',
    tasks: 'Taken',
    recentActivity: 'Recente activiteit',
    completedTasksOf: 'Taken uitgevoerd van',
    status: 'Status',
    assignedTo: 'Toegewezen aan',
    totalTimeSpent: 'Totale toegewezen tijd',
  },
  email: {
    message: 'Bericht',
    email: 'Email',
    otherFiles: 'Andere bestanden',
    imapHost: 'Server IMAP',
    smtpHost: 'Server SMTP',
    imapPort: 'Port IMAP',
    smtpPort: 'Port SMTP',
    imapTls: 'Gebruik SSL/TLS',
    smtpTls: 'Gebruik SSL/TLS',
    forReceive: 'Ontvangst',
    emailSended: 'Email is verzonden',
    forSend: 'Voor verzending',
    storeReceived: 'Opslaan ontvangen',
    storeSended: 'Opslaan verzonden',
    attachments: 'Bijlagen',
    newMessage: 'Nieuwe bericht',
    other: 'Overige',
    inbox: 'Inbox',
    invitedBy: 'Uitgenodigd',
    youOrganizer: 'U bent de organisator',
    recievedEmail: 'E-Mail ontvangen: ',
    productionFile: 'Productieschema',
    signed: 'Ondertekend',
    signedByClient: 'Ondertekend door de klant',
    userSignature: 'Handtekening gebruiker',
    sentEmail: 'Verzonden e-mail: ',
    sent: 'Verzonden',
    toSend: 'Wordt verzonden',
    drafts: 'Conceptversies',
    spam: 'Spam',
    trash: 'Prullenbak',
    title: 'E-mail',
    writeSomething: 'Voer inhoud in...',
    contents: 'Inhoud',
    from: 'Van',
    to: 'Naar',
    cc: 'CC',
    bcc: 'BCC',
    subject: 'Thema',
    subjectPh: 'Onderwerp van het bericht',
    body: 'Bericht',
    bodyPh: 'Inhoud van het bericht..',
    signatures: 'Handtekeningen',
    response: 'Beantwoord',
    responseAll: 'Alles beantwoorden',
    onLineMode: 'Online-stand',
    msg: {
      selectEmailFirst: 'Geen e-mail geselecteerd!',
    },
    all: 'Alle',
    none: 'Niets',
    seen: 'Berichten gelezen',
    unseen: 'Berichten ongelezen',
    withLinks: 'Met koppelingen',
    withoutLinks: 'Zonder koppelingen',
    processed: 'Bewerkt',
    notProcessed: 'Niet bewerkt',
    setAs: 'Markeren als',
    create: {
      newInteraction: 'Nieuwe interactie',
      newReclamation: 'Nieuwe klacht',
      newCustomerRequest: 'Nieuwe offerte aanvraag',
      customerRequestCorrection: 'Correctie offerte aanvraag',
    },
    attachTo: {
      interaction: 'Interactie',
      reclamation: 'Klacht',
      customerRequest: 'Offerte aanvraag',
    },
    noEmails: 'Geen emails',
    shown: 'Getoond',
    labels: 'Labels',
    moveToJunk: 'Naar spam',
    moveToTrash: 'Naar de prullenbak',
    moveTo: 'Stuur naar ...',
    setLabel: 'Label instellen',
    manageLabels: 'Beheer uw labels',
    setMailSignature: 'Stel uw eigen handtekening in',
    manageTemplates: 'Sjablonen beheren',
    selectEmailAccount: 'Kies uw e-mailaccount',
    emailMoved: 'De gemarkeerde boodschappen zijn verplaatst!',
    fillCounterparties: 'Klant kiezen / e-mail contactpersonen',
    fillCounterparty: 'Klant kiezen / contactperson',
    defaultEmailAccount: 'Standaard e-mailaccount',
    fillConterpartiesBegin: 'Het systeem is begonnen met het invullen van klanten (contactpersonen) naar e-mail',
    fillConterpartiesEnd: 'Het systeem is klaar met het invullen van klanten (contactpersonen) voor de e-mail',
  },
  businessProcess: {
    definitionsListTitle: 'Zakelijke processen',
    definitionsFormTitle: 'Processchema bewerken',
    fillExecutorOrExecutorRole: 'Vul de rol van executeur of executeur',
    noExecutor: 'Er is geen enkele aannemer.',
  },
  navigation: {
    newSubsystem: 'Nieuw subsysteem',
    editSubsystem: 'Het subsysteem wijzigen',
    newPartition: 'Nieuw hoofdstuk',
    newRoute: 'Nieuwe weergave',
    editRoute: 'Weergave bewerken',
    getPrezentation: 'Download de presentatie uit het archief',
  },
  ships: {
    imo: 'Internationale zeevaartorganisatie',
  },
  fm: {
    components: {
      fields: {
        input: 'Introductie',
        button: 'Toets',
        textarea: 'Tekstvak',
        number: 'Nummer',
        radio: 'Radio',
        checkbox: 'Checkbox',
        time: 'Tijd',
        date: 'Datuum',
        rate: 'Rate',
        color: 'Kleur',
        select: 'Selecteer',
        switch: 'Schakel',
        slider: 'Schuifregelaar',
        text: 'Tekst',
        blank: 'Custom',
        fileupload: 'Bestand uploaden',
        imgupload: 'Afbeelding uploaden',
        editor: 'Editor',
        cascader: 'Cascader',
        table: 'Ondergeschikte tabel',
        'view-list': 'Lijst',
        pagination: 'Paginering',
        grid: 'Rooster',
        card: 'Kaart',
        tabs: 'Bladwijzer',
        divider: 'Divider',
        toolbar: 'Taakbalk',
        container: 'Container',
        btnGroup: 'Knopgroep',
        collapse: 'Instorten',
        dropdown: 'Drop',
        inputGroup: 'Groep invoeren',
      },
      basic: {
        title: 'Basis onderdeel',
      },
      advance: {
        title: 'Geavanceerd onderdeel',
      },
      layout: {
        title: 'Opstelling',
      },
    },
    description: {
      containerEmpty: 'Je kunt een item vanaf de linkerkant slepen en neerzetten om het toe te voegen aan Onderdelen',
      configEmpty: 'Voeg item toe',
      tableEmpty: 'e kunt een item vanaf de linkerkant slepen en neerzetten om het toe te voegen aan Onderdelen',
      uploadJsonInfo: 'Hieronder staat een JSON-indeling, die je kunt overschrijven met je JSON-code. ',
    },
    message: {
      copySuccess: 'Kopiëren voltooid',
      validError: 'Formulier verificatie mislukt',
      restoreItem: 'Het element zal worden hersteld!',
      deleteItem: 'Het item wordt gemarkeerd voor verwijdering!',
      topicEmpty: 'Het onderwerp mag niet leeg zijn!!!',
      reportOptionGetError: 'Instellingen voor foutmeldingen',
      recipientEmpty: 'Geen ontvanger vermeld!!!',
    },
    actions: {
      import: 'Import JSON',
      clear: 'Maak schoon',
      preview: 'Voorbeeld',
      json: 'Genereer JSON',
      code: 'Genereer code',
      getData: 'Get Data',
      reset: 'Reset',
      copyData: 'Gegevens kopiëren',
      cancel: 'Anulieren',
      confirm: 'Bevestig',
      addOption: 'Optie toevoegen',
      addColumn: 'Kolom toevoegen',
      addItem: 'Artikel toevoegen',
      addTab: 'Veld toevoegen',
      upload: 'Upload',
      add: 'Voeg  toe',
      addCorrespondence: 'Correspondentie toevoegen',
    },
    config: {
      form: {
        title: 'Formulier',
        actions: 'Actie',
        functionNaem: 'Functienaam',
        functionDescription: 'Functiebeschrijving',
        dataSource: 'Gegevensbron',
        labelPosition: {
          title: 'Naam',
          left: 'Links',
          right: 'Rechts',
          top: 'Boven',
        },
        labelWidth: 'Labelbreedte',
        size: 'Maat',
        customClass: 'Custom Class',
        methods: 'Functies',
        toolbarContent: 'Inhoud van de werkbalk',
      },
      widget: {
        tabTitle: 'Kenmerken',
        title: 'Titel',
        subTitle: 'Subtitel',
        model: 'Model',
        name: 'Name',
        width: 'Breedte',
        height: 'Hoogte',
        size: 'Maat',
        labelWidth: 'Labelbreedte',
        custom: 'Custom',
        placeholder: 'Placeholder',
        layout: 'Lay-out',
        block: 'Block',
        inline: 'Inline',
        contentPosition: 'Content Position',
        left: 'Links',
        right: 'Rechts',
        center: 'Midden',
        showInput: 'Display Input Box',
        min: 'Minimum',
        max: 'Maximum',
        step: 'Step',
        multiple: 'Multiple',
        filterable: 'Searchable',
        allowHalf: 'Allow Half',
        showAlpha: 'Support transparency options',
        showLabel: 'Show lable',
        option: 'Option',
        staticData: 'Static Data',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        value: 'Value',
        label: 'Titel',
        childrenOption: 'Sub-Option',
        defaultValue: 'Default Value',
        showType: 'Display type',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        startPlaceholder: 'Placeholder of start time',
        endPlaceholder: 'Placeholder of end time',
        format: 'Format',
        uploadLimit: 'Maximum Upload Count',
        perPage: 'Aantal regels',
        isQiniu: 'Upload with Qiniu Cloud',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        imageAction: 'Picture upload address',
        tip: 'Text Prompt',
        action: 'Upload Address',
        defaultType: 'Data Type',
        string: 'String',
        object: 'Object',
        array: 'Array',
        number: 'Number',
        boolean: 'Boolean',
        search: 'Search',
        tel: 'Tel',
        date: 'Date',
        time: 'Time',
        range: 'Range',
        color: 'Color',
        integer: 'Integer',
        float: 'Float',
        url: 'URL',
        email: 'E-mail',
        password: 'Password',
        hex: 'Hexadecimal',
        noGutter: 'Rasterafstand uitschakelen',
        columnOption: 'Kolom positioneren',
        itemsOption: 'Lijstinstelling',
        cols: 'cols',
        rowCols: 'Kolommen per regel',
        rowColsSm: 'Kolommen per regel (Sm)',
        rowColsMd: 'Kolommen per regel (Md)',
        rowColsLg: 'Kolommen per regel (Lg)',
        rowColsXl: 'Kolommen per regel (Xl)',
        offset: 'offset',
        order: 'order',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl',
        justify: 'Horizontal Arrangement',
        justifyStart: 'Start',
        justifyEnd: 'End',
        justifyCenter: 'Centreer',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        type: 'Type',
        default: 'Default',
        card: 'Tabs',
        borderCard: 'Border-Card',
        tabPosition: 'Tab Position',
        top: 'Top',
        bottom: 'Bottom',
        tabOption: 'Label Configuration',
        tabName: 'Tab Name',
        customClass: 'Domyślne style',
        bodyClass: 't-body style',
        attribute: 'Attribute Action',
        dataBind: 'Data Binding',
        hidden: 'Hidden',
        readonly: 'Read Only',
        disabled: 'Disabled',
        editable: 'Text box is editable',
        clearable: 'Display Clear Button',
        arrowControl: 'Use the arrow for time selection',
        isDelete: 'Deletable',
        isEdit: 'Editable',
        showPassword: 'Display Password',
        validate: 'Validation',
        required: 'Required',
        patternPlaceholder: 'Fill in the regular expressions',
        newOption: 'New Option',
        tab: 'Tab',
        validatorRequired: 'Required',
        validatorType: 'Invaild format',
        validatorPattern: 'Unmatched pattern',
        showWordLimit: 'Show word limit',
        maxlength: 'Max length',
        hover: 'Markeren van geselecteerde',
        striped: 'Gestreept',
        selectable: 'Selecteerbaar',
        selectMode: 'Keuzemodus',
        fields: 'Kolommen',
        sortBy: 'Sorteren per',
        alignSelf: 'Jezelf uitlijnen',
        alignContent: 'Inhoud uitlijnen',
        align: 'Uitlijnen',
        alignH: 'Horizontaal uitlijnen',
        alignV: 'Verticaal uitlijnen',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        alignAround: 'Around',
        alignBetween: 'Between',
        alignStart: 'Start',
        alignCenter: 'Center',
        alignEnd: 'End',
        alignBaseline: 'Baseline',
        alignStretch: 'Stretch',
        tag: 'Tag',
        fluid: 'Vulling',
        keyNav: 'Navigatie',
        variant: 'Variant',
        variants: {
          primary: 'Primary',
          secondary: 'Secondary',
          success: 'Success',
          danger: 'Danger',
          warning: 'Warning',
          info: 'Info',
          light: 'Light',
          dark: 'Dark',
          outlinePrimary: 'Outline primary',
          outlineSecondary: 'Outline secondary',
          outlineSuccess: 'Outline success',
          outlineDanger: 'Outline danger',
          outlineWarning: 'Outline warning',
          outlineInfo: 'Outline info',
          outlineLight: 'Outline light',
          outlineDark: 'Outline dark',
        },
        pill: 'Pill',
        squared: 'Squared',
        vertical: 'Vertical',
        accordion: 'Accordion',
        appear: 'Appear',
        visible: 'Zichtbaar',
        text: 'Text',
        dropleft: 'Drop left',
        dropright: 'Drop right',
        dropup: 'Drop up',
        noCaret: 'No caret',
        noFlip: 'No flip',
        split: 'Split',
      },
    },
    upload: {
      preview: 'preview',
      edit: 'replace',
      delete: 'delete',
    },
  },
}

import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import taskActions from '@/utils/task-actions'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'

import BasicObject from '../../dto/NewTask.json'
const apiUrl = 'tasks'

const initState = {
  listView: {
    list: [],
    folders: {
      list: [],
      expanded: [],
    },
    fields: [],
    total: 0,
    page: 1,
    limit: 20,
    filters: {
      searchStr: '',
      executed: false,
      myTasks: false,
      tasksByExecutorRole: false,
      executor: null,
      period: [null, null],
      customer: null,
      taskCategory: null,
      status: null,
    },
    sort: { sortBy: null, sortDesc: false },
    viewId: null,
    views: [],
    attrs: {},
    settings: {
      title: '',
      viewSettings: null,
      userViewSettings: null,
      objectData: {},
      selectedRows: null,
      selectedFolder: null,
      scrollTop: null,
    },
  },
  objectViews: [],
  parentView: '',
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  ...commonMutations,
  setParentView(state: any, view: any) {
    state.parentView = view
  },
}

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async getOrderTasks(context: any, payload: any) {
    return axios.get(`/tasks`, payload).then((response) => {
      const tasks = response.data

      tasks.forEach((el: any) => {
        el.createdAt = moment(el.createdAt).format('DD.MM.YYYY HH:mm:ss')
        el.date = moment(el.date).format('DD.MM.YYYY HH:mm:ss')
        el.executionPeriod = moment(el.executionPeriod).format('DD.MM.YYYY HH:mm')
      })

      return tasks
    })
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async checkBeforeExecutionTaskCondition({ dispatch, state }: any, payload: any) {
    let executeTask = true
    if (state.openTask.bpDefinition && state.openTask.stage) {
      const bpDefinition = await dispatch(
        'bpDefinitions/findByPk',
        {
          params: {
            id: state.openTask.bpDefinition,
          },
        },
        { root: true }
      )

      if (bpDefinition) {
        const currentStage = bpDefinition.stages.find((stage: any) => stage.id === state.openTask.stage)
        if (currentStage.beforeExecution) {
          const reclamationResponse = await axios.get(`/getreclamation/${state.openTask.ownerId}`, {})
          if (reclamationResponse) {
            const currentReclamationData = reclamationResponse.data.responseData
            const conditionArgs = {
              task: state.openTask,
              reclamation: currentReclamationData,
            }
            executeTask = taskActions.executeConditionCode(currentStage.beforeExecution, conditionArgs)
          }
        }
      }
    }
    return executeTask
  },

  async executeOnStageExecution({ dispatch, state }: any, params: any) {
    const bpDefinitions = await dispatch(
      'bpDefinitions/findByPk',
      {
        params: {
          id: params.bpDefinition,
        },
      },
      { root: true }
    )

    if (bpDefinitions) {
      const currentStage = bpDefinitions.stages.find((stage: any) => stage.id === params.currentStageId)

      const reclamationResponse = await axios.get(`/getreclamation/${params.ownerId}`, {})

      if (currentStage) {
        if (currentStage.onExecution) {
          if (reclamationResponse) {
            const currentReclamationData = reclamationResponse.data.responseData
            const conditionArgs = {
              task: state.openTask,
              reclamation: currentReclamationData,
            }
            taskActions.executeAsyncConditionCode(currentStage.onExecution, conditionArgs)
          }
        }
      }

      if (params.nextStageId) {
        const nextStage = bpDefinitions.stages.find((stage: any) => stage.id === params.nextStageId)
        if (nextStage.beforeExecution) {
          if (reclamationResponse) {
            const currentReclamationData = reclamationResponse.data.responseData
            const nextStageconditionArgs = {
              task: state.openTask,
              reclamation: currentReclamationData,
            }
            await taskActions.executeAsyncConditionCode(nextStage.beforeExecution, nextStageconditionArgs)
          }
        }
      }
    }
  },

  async checkBeforeExecutionTaskCondition2(context: any, payload: any) {
    const currentStage = payload.currentStage
    let executeTask = true
    if (currentStage) {
      const conditionArgs = {}
      executeTask = taskActions.executeConditionCode(currentStage.beforeExecution, conditionArgs)
    }

    return executeTask
  },

  async createTaskBasingOnManualRedirection({ dispatch }: any, params: any) {
    const bpDefinitions = await dispatch(
      'bpDefinitions/findByPk',
      {
        params: {
          id: params.bpDefinition,
        },
      },
      { root: true }
    )

    const executionTerm = new Date()
    executionTerm.setHours(0, 0, 0, 0)
    executionTerm.setDate(executionTerm.getDate() + 1)

    const newTaskParams = _.cloneDeep(BasicObject)
    newTaskParams.executionPeriod = executionTerm
    newTaskParams.ownerType = params.ownerType
    newTaskParams.ownerId = params.ownerId
    newTaskParams.name = params.nextStage
    newTaskParams.lang = params.lang
    newTaskParams.stage = params.nextStageId
    newTaskParams.basicDocumentId = params.ownerId
    newTaskParams.basicDocumentType = params.ownerType
    newTaskParams.customerId = params.customerId
    newTaskParams.bp = bpDefinitions.data
    newTaskParams.bpDefinition = params.bpDefinition
    newTaskParams.bpExemplar = params.bpExemplar
    newTaskParams.bpExemplarId = params.bpExemplar.id
    newTaskParams.executorId = params.nextExecutorId
    newTaskParams.executorRoleId = params.nextExecutorRoleId
    newTaskParams.started = params.started
    if (params.files) {
      newTaskParams.files = params.files
    }

    await axios.post(`/tasks`, newTaskParams)
  },

  async acceptToExecutionTask(context: any, payload: any) {
    return axios
      .put(`/tasks/accept_execution/${payload.id}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async uploadFiles(context: any, params: any) {
    const formData = new FormData()

    params.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: params.taskId }))
    return axios
      .post(`/tasks/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async getFiles({ commit }: any, params: any) {
    return axios
      .post(`/tasks/object_file`, { objectId: params.taskId })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async openFile(context: any, params: any) {
    return axios
      .get(`/tasks/file/${params.id}`, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)

          if (params.open === true && (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png')) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }

          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

export const getters = {
  ...commonGetters,
  listView(state: any) {
    return state.listView
  },
  parentView(state: any) {
    return state.parentView
  },
}

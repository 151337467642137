import moment from 'moment'
import axios from 'axios'

import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'
import _ from 'lodash'

import BasicObject from '@/dto/Interaction.json'
const apiUrl = 'interactions'

const initState = {
  listView: {
    list: [],
    folders: {
      list: [],
      expanded: [],
    },
    fields: [],
    total: 0,
    page: 1,
    limit: 20,
    filters: {
      filter: {
        showClosed: false,
        showNoTasks: false,
      },
      searchStr: '',
      advanced: [],
      quick: [],
    },
    sort: { sortBy: null, sortDesc: false },
    viewId: null,
    views: [],
    attrs: {},
    settings: {
      title: '',
      viewSettings: null,
      userViewSettings: null,
      objectData: {},
      selectedRows: null,
      selectedFolder: null,
    },
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  ...commonMutations,
  updateFile(state: any, payload: any) {
    const viewIndex = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (viewIndex > -1) {
      const existFile = state.objectViews[viewIndex].object.files.find((el: any) => {
        return el.id === payload.fileId
      })
      if (existFile) {
        existFile[payload.property] = payload.value
      }
    }
  },
}

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create({ dispatch, getters }: any, payload: any) {
    return axios
      .post(`/interactions`, payload)
      .then((response) => {
        if (response.status === 200 && payload.objectData.files.length > 0) {
          const formData = new FormData()

          payload.objectData.files.map((file: any) => formData.append('files', file))

          formData.append('data', JSON.stringify({ id: response.data.id }))

          return axios
            .post(`/interactions/file`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              return response
            })
            .catch((error) => {
              console.error(error)
              throw error
            })
        } else {
          return response
        }
      })
      .catch((error) => {
        throw error
      })
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew({ commit, dispatch }: any, payload: any) {
    const newObject = _.cloneDeep(BasicObject)

    newObject.id = payload.viewId

    if (payload.byEmail === true && payload.emailData) {
      if (payload.emailData.title) {
        newObject.reference = `${payload.emailData.title} (${payload.emailData.from})`
        newObject.emailTitle = payload.emailData.title
      }

      newObject.customer = payload.emailData.counterparty
      newObject.customerId = payload.emailData.counterpartyId

      if (newObject.customer && newObject.customer?.managerId) {
        const manager = await dispatch(
          'employees/findByPk',
          {
            noCommit: true,
            params: {
              id: newObject.customer.managerId,
            },
          },
          { root: true }
        )

        if (manager.status === 200) {
          newObject.manager = manager.data
          newObject.managerId = manager.data.id
        }
      }

      if (payload.emailData.filesAtHardDrive === true) {
        newObject.files = []

        let emailFiles = payload.emailData.files

        if (!emailFiles) {
          await dispatch('incomingEmails/getFiles', { params: { emailId: payload.emailData.id } }, { root: true })
            .then(async (response: any) => {
              if (response.status === 200) {
                emailFiles = response.data
              }
            })
            .catch((error: any) => {
              console.error(error)
            })
        }

        if (emailFiles) {
          for (const file of emailFiles) {
            if (file.contentDisposition === 'attachment' || file.contentType === 'application/pdf') {
              await dispatch('incomingEmails/getFile', { id: file.id }, { root: true })
                .then((response: any) => {
                  if (response.status === 200) {
                    const itemFile = new File([response.data], file.filename, { type: file.contentType })
                    newObject.files.push(itemFile)
                  }
                })
                .catch((error: any) => {
                  throw error
                })
            }
          }
        }
      }
    }

    commit('addObjectView', {
      viewId: payload.viewId,
      editMode: 'NEW',
      nevVersion: payload.nevVersion,
      byEmail: payload.byEmail,
      emailData: payload.emailData,
      object: newObject,
    })

    return true
  },

  async updateFile({ commit }: any, payload: any) {
    return axios.put(`/interactions/file/${payload.id}`, payload.updateData).then((response) => {
      return response
    })
  },

  async addComment(context: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append(
      'data',
      JSON.stringify({
        text: payload.text,
        byEmail: payload.byEmail,
        emailTitle: payload.emailTitle,
        emailUid: payload.emailUid,
        emailId: payload.emailId,
        emailAccountId: payload.emailAccountId,
        emailType: payload.emailType,
        parentId: payload.parentId,
      })
    )

    return axios
      .post(`/interactions/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async deleteComment({ commit }: any, payload: any) {
    return axios.delete(`/interactions/comment/${payload.id}`, payload).then((response) => {
      if (response.status === 200) {
        commit('delComment', { viewId: payload.viewId, index: payload.index })
      }
      return response
    })
  },

  async addFiles({ commit }: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: payload.id }))

    return axios
      .post(`/interactions/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async changeHistoryDeletionMark({ commit }: any, payload: any) {
    const path = `/${payload.type === 'event' ? 'events' : 'interactions/comment'}/change_deletion_mark`
    return axios.post(path, { id: payload.value.id }).then((response) => {
      return response
    })
  },

  async getTasks({ commit }: any, payload: any) {
    return axios
      .post(`/interactions/tasks`, { objectId: payload.objectId })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async deleteFile({ commit }: any, params: any) {
    return axios
      .delete(`/interactions/file/${params.id}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async deleteCommentFile({ commit }: any, payload: any) {
    return axios
      .delete(`/interactions/comment/file/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit('delCommentFile', payload)
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async openFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)
          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }
          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async openCommentFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/comment/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)

          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }

          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY HH:mm:ss')
}

export const getters = { ...commonGetters }
